import './ModalSidebar.scss';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { overlayActions } from '../../../store/slices/overlay';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ModalSidebar: FC<{
  children: JSX.Element;
  title: string;
  fullWidth?: boolean;
}> = ({ children, title, fullWidth = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useSelector((state: any) => state.overlay.path);

  useEffect(() => {
    return () => {
      dispatch(overlayActions.close());
    };
  }, [dispatch]);

  const handleClose = () => {
    if (path) {
      navigate(path);
      dispatch(overlayActions.close());
    }
  };

  return (
    <div
      className={`modal-sidebar ${fullWidth ? 'full' : ''}`}
      data-testid={`${fullWidth ? 'full' : ''}`}
    >
      <div className="modal-sidebar-header">
        {t(title)}
        <span
          className="modal-sidebar-header__icon"
          data-testid="close-btn"
          onClick={handleClose}
        >
          <IoMdClose size={20} />
        </span>
      </div>
      {children}
    </div>
  );
};

export default ModalSidebar;
