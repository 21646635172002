import dayjs from 'dayjs';
import { TimeUnitType } from 'interfaces/drug';

export function getFutureDate(offset = 1) {
  const today = dayjs();
  const futureDate = today.add(offset, 'day');
  const formattedDate = futureDate.format('YYYY-MM-DD');
  return formattedDate;
}

export function formatMyDateLong(date: Date) {
  return dayjs(date).format('MMMM D, YYYY h:mm:ss A');
}
export function formatMyDateShort(date: Date) {
  return dayjs(date).format('MMM D, YYYY');
}

export function formatMyDateRemix(date: Date) {
  return dayjs(date).format('MM-DD-YYYY HH:mm:ss');
}

export const formatSecondsToWhateverFormat = (
  seconds: number,
  unit: TimeUnitType,
) => {
  if (unit === 'seconds') {
    return seconds;
  } else if (unit === 'minutes') {
    return dayjs().startOf('day').second(seconds).minute();
  } else if (unit === 'HH:mm') {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return dayjs().hour(hours).minute(minutes).format('HH:mm');
  }
};
