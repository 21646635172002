import {
  PaginationResponse,
  QueryCommonParams,
} from 'interfaces/pagination-response';
import axiosInstance from 'config/axios';
import { Company } from 'interfaces/company';

export type CompanyResponse = PaginationResponse<Company>;

const CompanyService = {
  create: (data: Partial<Company>) => {
    return axiosInstance.post<Company>('/v1/companies', data);
  },

  update: (id: string | number, data: Partial<Company>) => {
    return axiosInstance.patch<Company>(`/v1/companies/${id}`, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<Company>(`/v1/companies/${id}`);
  },

  disable: (id: string | number) => {
    return axiosInstance.post<Company>(`/v1/companies/${id}/disable`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<Company>(`/v1/companies/${id}`);
  },

  get: (queryParams: QueryCommonParams) => {
    return axiosInstance.get<CompanyResponse>('/v1/companies', {
      params: queryParams,
    });
  },
};

export default CompanyService;
