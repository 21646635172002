import { Device } from 'interfaces/deployment';
import axiosInstance from 'config/axios';
import { ColumnFilter, ColumnSort } from '@tanstack/react-table';

export type DeviceResponse = {
  records: Device[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
};

const DevicesService = {
  getAll: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<DeviceResponse>(`/v1/iot/devices`, {
      params: queryParams,
    });
  },
};

export default DevicesService;
