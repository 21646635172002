export const MASTER_LINKS = [
  {
    title: 'facilities.title',
    url: '/facilities',
  },
  {
    title: 'care_areas.title',
    url: '/care-areas',
  },
  {
    title: 'drugs.title',
    url: '/drugs',
  },
  {
    title: 'advisories.title',
    url: '/advisories',
  },
  {
    title: 'therapies.title',
    url: '/therapies',
  },
];
