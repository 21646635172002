import axiosInstance from 'config/axios';

const HelpService = {
  getHelpInfo: () => {
    return axiosInstance.get<{
      'drug-library-be': {
        version: string;
      };
      'drug-library-fe': {
        version: string;
      };
    }>('/app-version');
  },
};

export default HelpService;
