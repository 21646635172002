import { AppDispatch } from 'store';
import { authActions } from 'store/slices/auth';
import { signOut as cognitoSignOut } from 'aws-amplify/auth';
import cookies from 'config/cookie';
import { JWT_ACCESS_TOKEN } from 'constants/jwt-tokens';
import { QueryClient } from '@tanstack/react-query';

// Sign out the user and remove tokens that are stored in cookies
export async function signOutUser(
  dispatch: AppDispatch,
  queryClient: QueryClient,
) {
  await cognitoSignOut();
  dispatch(authActions.reset());
  cookies.remove(JWT_ACCESS_TOKEN);
  queryClient.invalidateQueries();
}
