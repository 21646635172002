import { useEffect, useContext, useCallback } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { History, Blocker, Transition } from 'history';

export function useBlocker(blocker: Blocker, when = true): void {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export const usePrompt = (message: string, when = true) => {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );
  useBlocker(blocker, when);
};
