import {
  DrugUnitsEnum,
  INFUSION_TYPES_VALUES,
  INFUSION_TYPES_VALUES_NO_INTERMITTENT,
} from 'constants/drugs';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { FC, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

type InfusionTypeProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  isConfirmed: boolean;
  watchInfusionType:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  canManage: boolean;
};

const InfusionType: FC<InfusionTypeProps> = ({
  record,
  control,
  isConfirmed,
  watchInfusionType,
  canManage,
}) => {
  const { t } = useTranslation();

  const infusionTypeValues = useMemo(() => {
    if (
      record.drug_unit === DrugUnitsEnum.NANOGRAM ||
      record.drug_unit === DrugUnitsEnum.MILLIUNIT
    ) {
      return { ...INFUSION_TYPES_VALUES_NO_INTERMITTENT };
    }

    return INFUSION_TYPES_VALUES;
  }, [record]);

  return (
    <div className={`row mt-2 position-relative`}>
      {!canManage && (
        <div className="device-concentration--disabled-item"></div>
      )}
      <div className="device-concentration-form__label">
        {t('device.infusion_type')}
      </div>

      {isConfirmed ? (
        <div className="device-concentration-form__column device-concentration-form__concentration--infusion-type">
          {watchInfusionType?.label}
        </div>
      ) : (
        <div className="device-concentration-form__select">
          <Controller
            control={control}
            name="infusion_type"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                id="infusionType"
                aria-label="infusion_type"
                maxMenuHeight={300}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value as any}
                isMulti={false}
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable
                isSearchable
                placeholder="- Select type -"
                options={Object.keys(infusionTypeValues).map((key: string) => ({
                  label:
                    infusionTypeValues[key as keyof typeof infusionTypeValues],
                  value: key,
                }))}
                isDisabled={!canManage}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default InfusionType;
