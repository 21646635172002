import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import { useAppSelector } from 'hooks/useReduxHooks';
import { DeploymentRequirements } from 'interfaces/deployment';
import { userSelector } from 'store/selectors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/UI/Button/Button';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeploymentService from 'services/DeploymentService';
import { DEPLOYMENT_JSON_PREFIX } from 'constants/router';
import CredentialsFields from '../CredentialsFields/CredentialsFields';
import { DEPLOYMENT_FORM_INPUT_WIDTH } from 'constants/deployment';

const DeploymentForm = ({
  libraryHasNotChangedSinceLastDeployment,
}: {
  libraryHasNotChangedSinceLastDeployment: boolean;
}) => {
  const user = useAppSelector(userSelector);

  const { pathname } = useLocation();

  const { t } = useTranslation();

  const deploymentInitialState: DeploymentRequirements = {
    hasDownloaded: false,
    hasReviewed: false,
    deploy_to_all_devices: true,
    devices_selected: [],
    username: user?.email || '',
    password: '',
  };

  const formMethods = useForm<DeploymentRequirements>({
    defaultValues: deploymentInitialState,
  });

  const { handleSubmit, watch, setValue, reset, control } = formMethods;

  const hasDownloaded = watch('hasDownloaded');
  const hasReviewed = watch('hasReviewed');

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    (formData: DeploymentRequirements) => DeploymentService.create(formData),
    {
      onSuccess: () => {
        toast.success(t('deployment.messages.deployment__success'));
        reset();
        // Invalidate the query for the deployment list to refetch the updated data + Partial deployment in case we need to display a different UI
        queryClient.invalidateQueries([ReactQueryKeys.DEPLOYMENTS]);
        queryClient.invalidateQueries([ReactQueryKeys.PARTIAL_DEPLOYMENT]);
        queryClient.invalidateQueries([ReactQueryKeys.CAN_DEPLOY]);
      },
      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
    },
  );

  const handleFormSubmit: SubmitHandler<DeploymentRequirements> = (data) => {
    mutate(data);
  };

  return (
    <>
      <Outlet />
      <h5 className="py-4" data-testid="full-deployments-tab">
        <b>{t('deployment.form.header')}</b>
      </h5>
      <div className="d-flex align-items-start gap-2 pt-3">
        <Link
          to={`${pathname}/${DEPLOYMENT_JSON_PREFIX}`}
          state={pathname}
          className="btn btn-lg rounded btn-primary me-3 text-nowrap"
          //avoid text overflow
          style={{ minWidth: 'auto' }}
          onClick={() => {
            setValue('hasDownloaded', true);
          }}
          data-testid="hasDownloaded"
        >
          {t('deployment.buttons.view_changes')}
        </Link>
        <div>
          <span
            onClick={() => {
              if (!hasDownloaded) {
                toast.error(t('deployment.errors.review__json__changes'));
              }
            }}
            className={hasDownloaded ? '' : 'opacity-50'}
            data-testid="hasReviewedToastyError"
          >
            <Controller
              name="hasReviewed"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, ref } }) => (
                <Checkbox
                  value="hasReviewed"
                  disabled={
                    !hasDownloaded || libraryHasNotChangedSinceLastDeployment
                  }
                  checked={value}
                  id="hasReviewed"
                  data-testid="hasReviewed"
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />

            {t('deployment.form.confirm_reviewing')}
          </span>
          {libraryHasNotChangedSinceLastDeployment && (
            <p
              className="d-flex pt-1"
              data-testid="can_not_deploy_unchanged_library_error"
            >
              {t('deployment.errors.can_not_deploy_unchanged_library')}
            </p>
          )}
        </div>
      </div>

      {hasReviewed && (
        <form
          className="form-fixed-width d-flex flex-column pt-5 gap-2 w-100 ml-auto"
          onSubmit={handleSubmit(handleFormSubmit)}
          aria-label="form"
          autoComplete="off"
          data-testid="deployment_form"
        >
          <>
            <div
              className="pt-2"
              style={{ width: DEPLOYMENT_FORM_INPUT_WIDTH }}
            >
              <CredentialsFields formMethods={formMethods} />
              <div className="d-flex justify-content-center">
                <Button
                  data-testid="submit_deployment_form"
                  loading={isLoading}
                  defaultLabel={t('deployment.buttons.deploy_btn')}
                  type="submit"
                  disabled={isLoading}
                  loadingLabel={t('buttons.saving')}
                />
              </div>
            </div>
          </>
        </form>
      )}
    </>
  );
};

export default DeploymentForm;
