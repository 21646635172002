import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { CareArea } from 'interfaces/care-area';
import { Facility } from 'interfaces/facility';
import FacilityService from 'services/FacilityService';
import { toast } from 'react-hot-toast';
import Spinner from 'components/UI/Spinner/Spinner';
import { Drug } from 'interfaces/drug';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import DrugService from 'services/DrugService';
import { SweetAlertResult } from 'sweetalert2';
import { ScreenEnum } from 'pages/Device/enums';

type CopyDrugCareAreasProps = {
  drug: Drug;
  facility: Facility;
  canManage: boolean;
  setScreen: Function;
};

const CopyDrugCareAreas: FC<CopyDrugCareAreasProps> = ({
  drug,
  facility,
  setScreen,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: careAreas, isLoading: isLoadingCareAreas } = useQuery(
    [
      ReactQueryKeys.FACILITY_CARE_AREAS,
      { facilityId: facility.id, drugId: drug.id },
    ],
    () =>
      FacilityService.getCareAreas(
        String(facility.id),
        String(drug.list_drug_id),
      ),
    {
      keepPreviousData: true,
      // @ts-ignore
      enabled: !!facility && !isNaN(facility.id),
    },
  );

  /**
   * Copy drug to care area
   *
   * @param {CareArea} careArea Care area that the drug will be copied to
   */
  const handleCareAreaClick = async (careArea: CareArea) => {
    firePreConfirmAlert({
      focusCancelBtn: false,
      title: t('device.messages.modal__copy_drug__title'),
      html: t('device.messages.modal__copy_drug__content', {
        drugName: drug.name,
        careAreaName: careArea.name,
      }),
      preConfirm: () => {
        return DrugService.copyDrugToCareArea(
          String(drug.id),
          String(careArea.id),
          String(facility.id),
        )
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.FACILITY_CARE_AREAS]);
        toast.success(t('device.messages.copy_drug__success'));
      }
    });
  };

  const handleCancelCopyDrug = () => {
    setScreen(ScreenEnum.Overview);
  };

  return (
    <>
      {/* <pre>{JSON.stringify(drug)}</pre> */}
      <h6 className="device__care-area--title mt-3 pb-1">
        {t('device.messages.copy_drug_to_care_area', {
          name: facility.name,
        })}
      </h6>

      <div className="row" data-testid="careareas-screen">
        <div className="col-6">
          {isLoadingCareAreas ? <Spinner isAbsolute /> : null}

          <div></div>
          {careAreas?.data?.records && careAreas.data.records.length === 0 && (
            <div className="alert mb-0">{t('messages.no_records')}</div>
          )}

          {careAreas?.data?.records && careAreas.data.records.length > 0 && (
            <div className="master-list device__care-area--full-list">
              {careAreas.data.records.map((careArea: CareArea) => {
                return (
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCareAreaClick(careArea);
                    }}
                    className={`master-list__item device-item ${
                      careArea.hasAssignedDrug ? 'item-disabled' : ''
                    }`}
                    key={careArea.id}
                  >
                    {careArea.name}
                  </a>
                );
              })}
            </div>
          )}
        </div>
        <div className="col-6">
          <div>
            <button
              className="btn btn-lg rounded btn-secondary"
              onClick={() => handleCancelCopyDrug()}
              data-testid="cancel-copy-drug"
            >
              {t('buttons.cancel')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyDrugCareAreas;
