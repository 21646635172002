import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isOpen: boolean;
  path: string | null;
}

const initialState: InitialState = {
  isOpen: false,
  path: null,
};

const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    open: (state, actions) => {
      state.isOpen = true;
      if (actions.payload.path) {
        state.path = actions.payload.path;
      }
    },
    close: (state) => {
      state.isOpen = false;
      state.path = null;
    },
  },
});

export const overlayActions = overlaySlice.actions;

export default overlaySlice.reducer;
