import { Link } from 'react-router-dom';
import './Header.scss';
import logo from '../../../assets/images/logo.svg';

import UserMenu from './UserMenu/UserMenu';
import classNames from 'classnames';
import type { FC } from 'react';

type HeaderProps = {
  showSidebar: boolean;
};

const Header: FC<HeaderProps> = ({ showSidebar }) => {
  const logoWrapperClasses = classNames({
    'top-navbar__logo-wrapper': true,
    'd-flex': true,
    'justify-content-center': true,
    'align-items-center': true,
    'no-sidebar': !showSidebar,
  });
  return (
    <header
      className="top-navbar d-flex justify-content-between"
      data-testid="header"
    >
      <div className={logoWrapperClasses}>
        <Link className="top-navbar__logo-link" to={'/'}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="top-navbar__right d-flex pe-4">
        <UserMenu />
      </div>
    </header>
  );
};

export default Header;
