import {
  Controller,
  Control,
  UseFormGetValues,
  UseFormSetValue,
  FieldErrors,
} from 'react-hook-form';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import Select from 'react-select';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';
import { calculateVariablePrecision, validatePrecision } from 'helpers/drugs';
import { MINUTES, SECONDS } from 'constants/drugs';
import isEqual from 'lodash.isequal';
import {
  getLoadingBolusLimits,
  getLoadingBolusUnits,
  wrongLimits,
} from 'helpers/concentrations';

type BolusLimitsProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  getValues: UseFormGetValues<ConcentrationFormData>;
  setValue: UseFormSetValue<ConcentrationFormData>;
  errors: FieldErrors<ConcentrationFormData>;
  watchWeightBased: boolean;
  watchBolusAmountLHL: string | number | null | undefined;
  watchBolusAmountLSL: string | number | null | undefined;
  watchBolusAmountUSL: string | number | null | undefined;
  watchBolusAmountUHL: string | number | null | undefined;
  watchBolusTimeLHL: string | number | null | undefined;
  watchBolusTimeLSL: string | number | null | undefined;
  watchBolusTimeUSL: string | number | null | undefined;
  watchBolusTimeUHL: string | number | null | undefined;
  watchAllowBolus: boolean;
  watchAllowLoad: boolean;
  watchDoseModeUnit:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchDoseModeTime:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchBolusTimeUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchBolusAmountUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchLoadTimeUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchLoadAmountUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchConcentrationLHL: string | number | null | undefined;
  watchConcentrationUHL: string | number | null | undefined;
  canManage: boolean;
};

const BolusLimits: FC<BolusLimitsProps> = ({
  record,
  control,
  getValues,
  setValue,
  errors,
  watchWeightBased,
  watchAllowBolus,
  watchBolusAmountLHL,
  watchBolusAmountLSL,
  watchBolusAmountUSL,
  watchBolusAmountUHL,
  watchBolusAmountUnits,
  watchBolusTimeLHL,
  watchBolusTimeLSL,
  watchBolusTimeUSL,
  watchBolusTimeUHL,
  watchBolusTimeUnits,
  watchAllowLoad,
  watchLoadAmountUnits,
  watchLoadTimeUnits,
  watchConcentrationLHL,
  watchConcentrationUHL,
  watchDoseModeUnit,
  watchDoseModeTime,
  canManage,
}) => {
  const { t } = useTranslation();

  const precisionBolusAmountLHL = useMemo<any>(
    () => calculateVariablePrecision(watchBolusAmountLHL),
    [watchBolusAmountLHL],
  );
  const precisionBolusAmountLSL = useMemo<any>(
    () => calculateVariablePrecision(watchBolusAmountLSL),
    [watchBolusAmountLSL],
  );
  const precisionBolusAmountUSL = useMemo<any>(
    () => calculateVariablePrecision(watchBolusAmountUSL),
    [watchBolusAmountUSL],
  );
  const precisionBolusAmountUHL = useMemo<any>(
    () => calculateVariablePrecision(watchBolusAmountUHL),
    [watchBolusAmountUHL],
  );

  const timeLimits: { min: number; max: number } = useMemo<any>(() => {
    if (!watchBolusTimeUnits) {
      return {
        min: 0,
        max: 0,
      };
    }

    switch (watchBolusTimeUnits.value) {
      case MINUTES:
        return { min: 1, max: 60 };
      case SECONDS:
        return { min: 30, max: 90 };
    }
    if (watchBolusTimeUnits.value === MINUTES) {
      return;
    }
  }, [watchBolusTimeUnits]);

  // Get loading and bolus units
  const loadingBolusUnits = useMemo(() => {
    const doseModeUnit = record.is_complete
      ? record.dose_mode_unit
      : watchDoseModeUnit?.value;
    const doseModeTime = record.is_complete
      ? record.dose_mode_time
      : watchDoseModeTime?.value;

    return getLoadingBolusUnits(
      record,
      watchWeightBased,
      doseModeUnit,
      doseModeTime,
    );
  }, [watchWeightBased, record, watchDoseModeUnit, watchDoseModeUnit]);

  // get loading and bolus limits
  const loadingBolusLimits = useMemo(() => {
    return getLoadingBolusLimits(
      record,
      watchBolusAmountUnits,
      watchConcentrationLHL,
      watchConcentrationUHL,
    );
  }, [
    watchBolusAmountUnits,
    record,
    watchConcentrationLHL,
    watchConcentrationUHL,
  ]);

  const handleBolusAmountsChange = (
    value:
      | {
          label: string;
          value: string;
        }
      | null
      | undefined,
  ) => {
    if (
      (!!value && !watchBolusAmountUnits) ||
      (!value && !!watchBolusAmountUnits) ||
      !isEqual(value, watchBolusAmountUnits)
    ) {
      setValue('bolus_amount_lower_hard_limit', '');
      setValue('bolus_amount_lower_soft_limit', '');
      setValue('bolus_amount_upper_soft_limit', '');
      setValue('bolus_amount_upper_hard_limit', '');
    }

    if (watchAllowLoad) {
      if (!isEqual(value, watchLoadAmountUnits)) {
        setValue('load_amount_units', value, {
          shouldValidate: true,
        });
        setValue('load_amount_lower_hard_limit', '');
        setValue('load_amount_lower_soft_limit', '');
        setValue('load_amount_upper_soft_limit', '');
        setValue('load_amount_upper_hard_limit', '');
      }
    } else {
      if (
        (!!value && !watchLoadAmountUnits) ||
        (!value && !!watchLoadAmountUnits)
      ) {
        setValue('load_amount_units', value, {
          shouldValidate: true,
        });
        setValue('load_amount_lower_hard_limit', '');
        setValue('load_amount_lower_soft_limit', '');
        setValue('load_amount_upper_soft_limit', '');
        setValue('load_amount_upper_hard_limit', '');
      }
    }
  };

  const handleBolusTimeChange = (
    value:
      | {
          label: string;
          value: string;
        }
      | null
      | undefined,
  ) => {
    if (
      (!!value && !watchBolusTimeUnits) ||
      (!value && !!watchBolusTimeUnits) ||
      !isEqual(value, watchBolusTimeUnits)
    ) {
      setValue('bolus_time_lower_hard_limit', '');
      setValue('bolus_time_lower_soft_limit', '');
      setValue('bolus_time_upper_soft_limit', '');
      setValue('bolus_time_upper_hard_limit', '');
    }

    if (watchAllowLoad) {
      if (!isEqual(value, watchLoadTimeUnits)) {
        setValue('load_time_units', value, {
          shouldValidate: true,
        });
        setValue('load_time_lower_hard_limit', '');
        setValue('load_time_lower_soft_limit', '');
        setValue('load_time_upper_soft_limit', '');
        setValue('load_time_upper_hard_limit', '');
      }
    } else {
      if (
        (!!value && !watchLoadTimeUnits) ||
        (!value && !!watchLoadTimeUnits)
      ) {
        setValue('load_time_units', value, {
          shouldValidate: true,
        });
        setValue('load_time_lower_hard_limit', '');
        setValue('load_time_lower_soft_limit', '');
        setValue('load_time_upper_soft_limit', '');
        setValue('load_time_upper_hard_limit', '');
      }
    }
  };

  if (!Array.isArray(loadingBolusUnits) || !loadingBolusUnits.length) {
    return null;
  }

  return (
    <div className="position-relative row">
      {!canManage ? (
        <div className="device-concentration--disabled-item"></div>
      ) : null}

      <div className="device-concentration-form__allow-load--wrapper">
        <div className="row flex-nowrap">
          <div className="device-concentration-form__label justify-content-between">
            <span>{t('device.allow_bolus')}</span>
            {watchAllowBolus && <span>{t('device.amount')}</span>}
          </div>

          {/* Lower hard limit */}
          <div className="device-concentration__box-input">
            {watchAllowBolus && watchBolusAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_amount_lower_hard_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LSL, USL, UHL] = getValues([
                          'bolus_amount_lower_soft_limit',
                          'bolus_amount_upper_soft_limit',
                          'bolus_amount_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LSL ? Number(LSL) > currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusAmountLSL !== '' &&
                            watchBolusAmountLSL !== null &&
                            !isNaN(watchBolusAmountLSL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionBolusAmountLHL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionBolusAmountLHL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.bolus_amount_lower_hard_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_amount_lower_hard_limit"
                      data-testid="bolus_amount_lower_hard_limit"
                    />
                  )}
                />

                {errors?.bolus_amount_lower_hard_limit && (
                  <ReactTooltip
                    id="bolus_amount_lower_hard_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_amount_lower_hard_limit?.type ===
                        'required' && t('device.errors.hard_limits__required')}
                      {errors?.bolus_amount_lower_hard_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: loadingBolusLimits.min,
                        })}
                      {errors?.bolus_amount_lower_hard_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: loadingBolusLimits.max,
                        })}
                      {errors?.bolus_amount_lower_hard_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_amount_lower_hard_limit?.type ===
                        'precision' &&
                        t('device.errors.precision', {
                          value: precisionBolusAmountLHL,
                        })}
                      {errors?.bolus_amount_lower_hard_limit?.type ===
                        'oneLowerLimitRequired' &&
                        t('device.errors.one_lower_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Lower soft limit */}
          <div className="device-concentration__box-input">
            {!!watchAllowBolus && !!watchBolusAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_amount_lower_soft_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, USL, UHL] = getValues([
                          'bolus_amount_lower_hard_limit',
                          'bolus_amount_upper_soft_limit',
                          'bolus_amount_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusAmountLHL !== '' &&
                            watchBolusAmountLHL !== null &&
                            !isNaN(watchBolusAmountLHL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionBolusAmountLSL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionBolusAmountLSL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.bolus_amount_lower_soft_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_amount_lower_soft_limit"
                      data-testid="bolus_amount_lower_soft_limit"
                    />
                  )}
                />

                {errors?.bolus_amount_lower_soft_limit && (
                  <ReactTooltip
                    id="bolus_amount_lower_soft_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_amount_lower_soft_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: loadingBolusLimits.min,
                        })}
                      {errors?.bolus_amount_lower_soft_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: loadingBolusLimits.max,
                        })}
                      {errors?.bolus_amount_lower_soft_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_amount_lower_soft_limit?.type ===
                        'precision' &&
                        t('device.errors.precision', {
                          value: precisionBolusAmountLSL,
                        })}
                      {errors?.bolus_amount_lower_soft_limit?.type ===
                        'oneLowerLimitRequired' &&
                        t('device.errors.one_lower_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper soft limit */}
          <div className="device-concentration__box-input">
            {!!watchAllowBolus && !!watchBolusAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_amount_upper_soft_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, UHL] = getValues([
                          'bolus_amount_lower_hard_limit',
                          'bolus_amount_lower_soft_limit',
                          'bolus_amount_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusAmountUHL !== '' &&
                            watchBolusAmountUHL !== null &&
                            !isNaN(watchBolusAmountUHL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionBolusAmountUSL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionBolusAmountUSL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.bolus_amount_upper_soft_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_amount_upper_soft_limit"
                      data-testid="bolus_amount_upper_soft_limit"
                    />
                  )}
                />

                {errors?.bolus_amount_upper_soft_limit && (
                  <ReactTooltip
                    id="bolus_amount_upper_soft_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_amount_upper_soft_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: loadingBolusLimits.min,
                        })}
                      {errors?.bolus_amount_upper_soft_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: loadingBolusLimits.max,
                        })}
                      {errors?.bolus_amount_upper_soft_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_amount_upper_soft_limit?.type ===
                        'precision' &&
                        t('device.errors.precision', {
                          value: precisionBolusAmountUSL,
                        })}
                      {errors?.bolus_amount_upper_soft_limit?.type ===
                        'oneUpperLimitRequired' &&
                        t('device.errors.one_upper_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper hard limit */}
          <div className="device-concentration__box-input">
            {!!watchAllowBolus && !!watchBolusAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_amount_upper_hard_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, USL] = getValues([
                          'bolus_amount_lower_hard_limit',
                          'bolus_amount_lower_soft_limit',
                          'bolus_amount_upper_soft_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) < currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusAmountUSL !== '' &&
                            watchBolusAmountUSL !== null &&
                            !isNaN(watchBolusAmountUSL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionBolusAmountUHL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionBolusAmountUHL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.bolus_amount_upper_hard_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_amount_upper_hard_limit"
                      data-testid="bolus_amount_upper_hard_limit"
                    />
                  )}
                />

                {errors?.bolus_amount_upper_hard_limit && (
                  <ReactTooltip
                    id="bolus_amount_upper_hard_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_amount_upper_hard_limit?.type ===
                        'required' && t('device.errors.hard_limits__required')}
                      {errors?.bolus_amount_upper_hard_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: loadingBolusLimits.min,
                        })}
                      {errors?.bolus_amount_upper_hard_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: loadingBolusLimits.max,
                        })}
                      {errors?.bolus_amount_upper_hard_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_amount_upper_hard_limit?.type ===
                        'precision' &&
                        t('device.errors.precision', {
                          value: precisionBolusAmountUHL,
                        })}
                      {errors?.bolus_amount_upper_hard_limit?.type ===
                        'oneUpperLimitRequired' &&
                        t('device.errors.one_upper_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Units */}
          {!!watchAllowBolus && (
            <div className="device-concentration-units">
              {watchAllowBolus ? (
                <div className="device-concentration-form__select">
                  <>
                    <Controller
                      control={control}
                      name="bolus_amount_units"
                      rules={{
                        required: watchAllowBolus,
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          isDisabled={!watchAllowBolus}
                          id="bolus_amount_units"
                          aria-label="bolus_amount_units"
                          maxMenuHeight={300}
                          ref={ref}
                          onChange={(newValue, actionMeta) => {
                            handleBolusAmountsChange(newValue);
                            onChange(newValue, actionMeta);
                          }}
                          onBlur={onBlur}
                          value={value as any}
                          isMulti={false}
                          className={
                            'react-select-container ' +
                            (errors?.bolus_amount_units ? 'has-error' : '')
                          }
                          classNamePrefix="react-select"
                          isClearable
                          isSearchable
                          placeholder="- Select -"
                          options={loadingBolusUnits.map((unit: string) => ({
                            label: unit,
                            value: unit,
                          }))}
                        />
                      )}
                    />
                    {errors?.bolus_amount_units && (
                      <ReactTooltip
                        id="bolus_amount_units"
                        place="bottom"
                        delayHide={300}
                        variant="error"
                      >
                        {errors?.bolus_amount_units?.type === 'required' &&
                          t('errors.field__required')}
                      </ReactTooltip>
                    )}
                  </>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {watchAllowBolus &&
          watchBolusAmountUnits &&
          wrongLimits(loadingBolusLimits) && (
            <div className="row pb-1">
              <div className="device-concentration-form__label"></div>
              <div className="device-concentration-form__column">
                <div className="invalid-feedback">
                  {t('device.errors.loading_dose_limits__out_of_range')}
                </div>
              </div>
            </div>
          )}

        {/* LOAD TIME */}
        <div className="row pb-3 pt-3">
          <div className="device-concentration-form__label justify-content-between">
            <div
              className="ps-4"
              style={{ position: 'relative', top: '-30px', left: '5px' }}
            >
              <Controller
                control={control}
                name="allow_bolus"
                rules={{}}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Checkbox
                    disabled={false}
                    id="allow_bolus"
                    data-testid="allow_bolus"
                    title=""
                    checked={value}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={'allow_bolus'}
                  />
                )}
              />
            </div>
            {watchAllowBolus && <div>{t('device.time')}</div>}
          </div>

          {/* Lower hard limit */}
          <div className="device-concentration__box-input">
            {watchAllowBolus && watchBolusTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_time_lower_hard_limit"
                  rules={{
                    min: timeLimits?.min,
                    max: timeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LSL, USL, UHL] = getValues([
                          'bolus_time_lower_soft_limit',
                          'bolus_time_upper_soft_limit',
                          'bolus_time_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LSL ? Number(LSL) > currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusTimeLSL !== '' &&
                            watchBolusTimeLSL !== null &&
                            !isNaN(watchBolusTimeLSL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.bolus_time_lower_hard_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_time_lower_hard_limit"
                      data-testid="bolus_time_lower_hard_limit"
                    />
                  )}
                />
                {errors?.bolus_time_lower_hard_limit && (
                  <ReactTooltip
                    id="bolus_time_lower_hard_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_time_lower_hard_limit?.type ===
                        'required' && t('device.errors.hard_limits__required')}
                      {errors?.bolus_time_lower_hard_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: timeLimits?.min,
                        })}
                      {errors?.bolus_time_lower_hard_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: timeLimits?.max,
                        })}
                      {errors?.bolus_time_lower_hard_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_time_lower_hard_limit?.type ===
                        'oneLowerLimitRequired' &&
                        t('device.errors.one_lower_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Lower soft limit */}
          <div className="device-concentration__box-input">
            {!!watchAllowBolus && !!watchBolusTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_time_lower_soft_limit"
                  rules={{
                    min: timeLimits?.min,
                    max: timeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, USL, UHL] = getValues([
                          'bolus_time_lower_hard_limit',
                          'bolus_time_upper_soft_limit',
                          'bolus_time_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusTimeLHL !== '' &&
                            watchBolusTimeLHL !== null &&
                            !isNaN(watchBolusTimeLHL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.bolus_time_lower_soft_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_time_lower_soft_limit"
                      data-testid="bolus_time_lower_soft_limit"
                    />
                  )}
                />

                {errors?.bolus_time_lower_soft_limit && (
                  <ReactTooltip
                    id="bolus_time_lower_soft_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_time_lower_soft_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: timeLimits?.min,
                        })}
                      {errors?.bolus_time_lower_soft_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: timeLimits?.max,
                        })}
                      {errors?.bolus_time_lower_soft_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_time_lower_soft_limit?.type ===
                        'oneLowerLimitRequired' &&
                        t('device.errors.one_lower_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper soft limit */}

          <div className="device-concentration__box-input">
            {!!watchAllowBolus && !!watchBolusTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_time_upper_soft_limit"
                  rules={{
                    min: timeLimits?.min,
                    max: timeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, UHL] = getValues([
                          'bolus_time_lower_hard_limit',
                          'bolus_time_lower_soft_limit',
                          'bolus_time_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusTimeUHL !== '' &&
                            watchBolusTimeUHL !== null &&
                            !isNaN(watchBolusTimeUHL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.bolus_time_upper_soft_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_time_upper_soft_limit"
                      data-testid="bolus_time_upper_soft_limit"
                    />
                  )}
                />

                {errors?.bolus_time_upper_soft_limit && (
                  <ReactTooltip
                    id="bolus_time_upper_soft_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_time_upper_soft_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: timeLimits?.min,
                        })}
                      {errors?.bolus_time_upper_soft_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: timeLimits?.max,
                        })}
                      {errors?.bolus_time_upper_soft_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_time_upper_soft_limit?.type ===
                        'oneUpperLimitRequired' &&
                        t('device.errors.one_upper_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper hard limit */}

          <div className="device-concentration__box-input">
            {watchAllowBolus && watchBolusTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="bolus_time_upper_hard_limit"
                  rules={{
                    min: timeLimits?.min,
                    max: timeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, USL] = getValues([
                          'bolus_time_lower_hard_limit',
                          'bolus_time_lower_soft_limit',
                          'bolus_time_upper_soft_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) < currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowBolus) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchBolusTimeUSL !== '' &&
                            watchBolusTimeUSL !== null &&
                            !isNaN(watchBolusTimeUSL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.bolus_time_upper_hard_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tooltip-id="bolus_time_upper_hard_limit"
                      data-testid="bolus_time_upper_hard_limit"
                    />
                  )}
                />

                {errors?.bolus_time_upper_hard_limit && (
                  <ReactTooltip
                    id="bolus_time_upper_hard_limit"
                    place="bottom"
                    delayHide={300}
                    variant="error"
                  >
                    <>
                      {errors?.bolus_time_upper_hard_limit?.type === 'min' &&
                        t('device.errors.not_lower_than', {
                          value: timeLimits?.min,
                        })}
                      {errors?.bolus_time_upper_hard_limit?.type === 'max' &&
                        t('device.errors.not_greater_than', {
                          value: timeLimits?.max,
                        })}
                      {errors?.bolus_time_upper_hard_limit?.type ===
                        'increasingOrder' &&
                        t('device.errors.order_LHL_LSL_USL_UHL')}
                      {errors?.bolus_time_upper_hard_limit?.type ===
                        'oneUpperLimitRequired' &&
                        t('device.errors.one_upper_limit__required')}
                    </>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Units */}
          {!!watchAllowBolus && (
            <div className="device-concentration-units">
              {watchAllowBolus && canManage ? (
                <div className="device-concentration-form__select">
                  <Controller
                    control={control}
                    name="bolus_time_units"
                    rules={{
                      required: watchAllowBolus,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        id="bolus_time_units"
                        aria-label="bolus_time_units"
                        maxMenuHeight={300}
                        ref={ref}
                        onChange={(newValue, actionMeta) => {
                          handleBolusTimeChange(newValue);
                          onChange(newValue, actionMeta);
                        }}
                        onBlur={onBlur}
                        value={value as any}
                        isMulti={false}
                        className={
                          'react-select-container ' +
                          (errors?.bolus_time_units ? 'has-error' : '')
                        }
                        classNamePrefix="react-select"
                        isClearable
                        isSearchable
                        placeholder="- Select -"
                        options={[
                          {
                            label: MINUTES,
                            value: MINUTES,
                          },
                          {
                            label: SECONDS,
                            value: SECONDS,
                          },
                        ]}
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BolusLimits;
