import { useAppDispatch, useAppSelector } from './useReduxHooks';
import {
  deviceActions,
  selectDrugUnsavedChangesMessage,
  selectIsEditingStill,
} from 'store/slices/device';
import { useTranslation } from 'react-i18next';
import { fireAlert } from 'components/UI/SwalAlert/SwalAlert';
/**

   *
   * A hook that will prompt the user to confirm a certain action when there are unsaved changes. This hook is useful in cases where the usePrompt hook cannot be triggered:  a. links with onClick logic (no direct navigation), or b. buttons/divs with onClick events that destroy the component where we want to prevent unsaved changes
   *
   * @returns handleBlock fn
   *
   * @example
   *
   * const handleBlock =useBlockerExceptionalCases()
  
   *

   */
const useBlockerExceptionalCases = () => {
  const { t } = useTranslation();
  const isEditingStill = useAppSelector(selectIsEditingStill);
  const drugUnsavedChangesMessage = useAppSelector(
    selectDrugUnsavedChangesMessage,
  );
  const dispatch = useAppDispatch();

  /**

   *
   * if form isDirty, user will be prompted to confirm action. Whether the form is dirty is determined by the redux Slice
   *
   * @params a function
   * 
   * @returns undefined if user cancels, or the return value of the callback function the user passed. It can be used in two different ways, as displayed below
   *
   * @example
   * <button onClick={async () => await handleBlock(fnIWantToExecuteIfUserConfirms)}/>
   *
   * @example 
   * <button onClick={async () => {
   *         if (!(await handleBlock(() => true))) return;
   *  restOfMyLogic()};} 
   * />
   */

  const handleBlock = <T extends () => any>(
    cb: T,
  ): Promise<ReturnType<T> | undefined> => {
    if (isEditingStill) {
      //TODO: check what do we do with that blockDoubleModal and add that info here
      //when handleBlock is called within a fn that alters the url, the original useBlocker hook will be triggered and modal will appear twice. This action will prevent that
      dispatch(deviceActions.updateBlockDoubleModal(true));

      return new Promise((resolve) => {
        fireAlert({
          title: t('messages.unsaved_changes_title'),
          html: t(drugUnsavedChangesMessage || 'messages.unsaved_changes'),
        }).then((result) => {
          if (result.isDismissed || result.isDenied) {
            resolve(undefined);
          } else {
            resolve(cb());
          }

          setTimeout(() => {
            dispatch(deviceActions.updateBlockDoubleModal(false));
          }, 100);
        });
      });
    } else {
      return cb();
    }
  };

  return handleBlock;
};

export default useBlockerExceptionalCases;
