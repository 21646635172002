import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from '../../../constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from '../../../store/slices/overlay';
import { useTranslation } from 'react-i18next';
import BasicModeAdvisoryReasonService from 'services/BasicModeAdvisoryReasonService';
import { BasicModeAdvisoryReason } from 'interfaces/basic-mode-advisory-reason';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { IoIosCreate, IoMdTrash } from 'react-icons/io';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import { SweetAlertResult } from 'sweetalert2';

type FormData = {
  reason: string;
};

const initialFormData: FormData = {
  reason: '',
};

const ManageBasicModeAdvisoryModalForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'all',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [isFormShown, setIsFormShown] = useState(false);
  const [selectedReason, setSelectedReason] =
    useState<null | BasicModeAdvisoryReason>(null);

  const { data: reasonsData, isLoading: isReasonsLoading } = useQuery(
    [ReactQueryKeys.BASIC_MODE_ADVISORY_REASONS],
    () =>
      BasicModeAdvisoryReasonService.get({
        pageIndex: 0,
        pageSize: 20,
        sorting: [
          {
            id: 'reason',
            desc: false,
          },
        ],
      }),
    { keepPreviousData: true },
  );

  /**
   * Submit form
   *
   * @param formData Reason data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      reason: formData.reason.trim(),
    };
    setFormSubmitting(true);
    if (!!selectedReason && !!selectedReason.id) {
      try {
        await BasicModeAdvisoryReasonService.update(selectedReason.id, data);
        toast.success(
          t('advisories.messages.basic_mode_advisory_reason__update__success'),
        );
        queryClient.invalidateQueries([
          ReactQueryKeys.BASIC_MODE_ADVISORY_REASONS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.ADVISORY_BASIC_MODE_REASONS,
        ]);
        setSelectedReason(null);
        setIsFormShown(false);
      } catch (e: any) {
        let message = t(
          'advisories.messages.basic_mode_advisory_reason__update__error',
        );
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    } else {
      try {
        await BasicModeAdvisoryReasonService.create(data);
        toast.success(
          t('advisories.messages.basic_mode_advisory_reason__create__success'),
        );
        queryClient.invalidateQueries([
          ReactQueryKeys.BASIC_MODE_ADVISORY_REASONS,
        ]);
        setSelectedReason(null);
        setIsFormShown(false);
      } catch (e: any) {
        let message = t(
          'advisories.messages.basic_mode_advisory_reason__create__error',
        );
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }
    setFormSubmitting(false);
  };

  useEffect(() => {
    dispatch(overlayActions.open({ path: `/advisories/${params.id}` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(`/advisories/${params.id}`);
    }
  };

  const handleReasonSelect = (reason: BasicModeAdvisoryReason) => {
    setSelectedReason(reason);
  };

  // Show alert for confirming of deletion a record
  const handleDeleteShow = (reason: BasicModeAdvisoryReason) => {
    firePreConfirmAlert({
      title: t('advisories.messages.modal_basic_mode_advisory__delete__title'),
      html: t(
        'advisories.messages.modal_basic_mode_advisory__delete__content',
        {
          name: reason.reason,
        },
      ),
      preConfirm: () => {
        return BasicModeAdvisoryReasonService.delete(String(reason.id))
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([
          ReactQueryKeys.BASIC_MODE_ADVISORY_REASONS,
        ]);
        toast.success(
          t('advisories.messages.basic_mode_advisory_reason__delete__success'),
        );
      }
    });
  };

  const handleEditReason = (reason: BasicModeAdvisoryReason) => {
    setSelectedReason(reason);
    setIsFormShown(true);
    reset(Object.assign({ ...initialFormData }, { reason: reason.reason }));
  };

  const handleAddReason = () => {
    setSelectedReason(null);
    setIsFormShown(true);
    reset({ ...initialFormData });
  };

  return (
    <form
      id="formid"
      autoComplete="off"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div
        className={`modal-sidebar-body pb-4 ${
          isReasonsLoading || formSubmitting ? 'opacity-50' : ''
        } ${!isFormShown ? 'modal-sidebar-body--full' : ''}`}
      >
        {isReasonsLoading && <Spinner isAbsolute />}

        {/* Hide form if reason needs to be selected */}
        {isFormShown ? (
          <div className="row">
            {/* Reason */}
            <div className="col-12">
              <label htmlFor="reason" className="form-label">
                {t('advisories.basic_mode_advisory_reason')} *
              </label>
              <input
                type="text"
                autoFocus
                className="form-control form-control-lg rounded"
                id="reason"
                data-testid="reason"
                {...register('reason', {
                  required: true,
                  maxLength: 25,
                })}
              />
              {errors?.reason?.type === 'required' && (
                <div className="invalid-feedback pt-1">
                  {t('advisories.errors.basic_mode_advisory_reason__required')}
                </div>
              )}
              {errors?.reason?.type === 'maxLength' && (
                <div className="invalid-feedback pt-1">
                  {t(
                    'advisories.errors.basic_mode_advisory_reason__max_characters',
                  )}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {/* List of reasons */}
        {!isFormShown ? (
          <>
            {reasonsData?.data?.records &&
              reasonsData?.data?.records.length === 0 && (
                <div className="alert mb-0">{t('messages.no_records')}</div>
              )}

            {!!reasonsData?.data?.records &&
            reasonsData?.data?.records.length > 0 ? (
              <div className="master-list advisory-reasons-master-list">
                {reasonsData?.data?.records.map(
                  (reason: BasicModeAdvisoryReason) => {
                    return (
                      <div
                        key={reason.id}
                        className={`master-list__item ${
                          selectedReason?.id === reason.id ? 'active' : ''
                        }`}
                        onClick={() => {
                          handleReasonSelect(reason);
                        }}
                      >
                        {reason.reason}

                        <button
                          className="master-list__item-edit"
                          onClick={(e) => {
                            e.preventDefault();
                            handleEditReason(reason);
                          }}
                          data-testid={`edit_btn_${reason.id}`}
                        >
                          <IoIosCreate size={26} />
                        </button>

                        <button
                          className="master-list__item-delete"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteShow(reason);
                          }}
                          data-testid={`delete_btn_${reason.id}`}
                        >
                          <IoMdTrash size={26} />
                        </button>
                      </div>
                    );
                  },
                )}
              </div>
            ) : null}

            {reasonsData?.data?.records &&
              reasonsData?.data?.records.length < 10 && (
                <div className="text-center">
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddReason();
                    }}
                    data-testid="add_reason_btn"
                    className="btn btn-lg rounded btn-primary mt-3"
                  >
                    {t('advisories.add_reason')}
                  </a>
                </div>
              )}
          </>
        ) : null}
      </div>

      {isFormShown ? (
        <div className="modal-sidebar-footer">
          <Button
            loading={formSubmitting}
            defaultLabel={t('buttons.save')}
            loadingLabel={
              params.id ? t('buttons.updating') : t('buttons.saving')
            }
            type="submit"
            disabled={formSubmitting || isReasonsLoading}
            data-testid="submit_btn"
          ></Button>

          <Link
            to={`/advisories/${params.id}`}
            className={`btn btn-lg rounded btn-secondary ${
              formSubmitting || isReasonsLoading ? 'disabled' : ''
            }`}
          >
            {t('buttons.cancel')}
          </Link>
        </div>
      ) : null}
    </form>
  );
};

export default ManageBasicModeAdvisoryModalForm;
