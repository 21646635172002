import axiosInstance from 'config/axios';
import {
  Deployment,
  DeploymentJsonFile,
  DeploymentJsonFileOrNull,
  DeploymentRequirements,
  UpdateTypeLiterals,
  UserCredentials,
} from 'interfaces/deployment';
import { RecordTypeUnionValues } from 'interfaces/facility';
import {
  PaginationResponse,
  QueryCommonParams,
} from 'interfaces/pagination-response';

export type CanDeployApiResponse = {
  libraryHasNotChangedSinceLastDeployment: boolean;
  status: boolean;
  reasons: {
    panelLockPasscode: boolean;
    advancedSettingsPasscode: boolean;
    incompleteDrugs: boolean;
    incompleteCareArea: boolean;
  };
};

export type ChangeReportFilters = {
  recordType: RecordTypeUnionValues[];
};

const DeploymentService = {
  downloadChangeReport: (queryParams: {
    deploymentTab: string | undefined;
  }) => {
    return axiosInstance.post<any>(
      '/v1/deployments/change-report/pdf',
      undefined,
      {
        headers: {
          Accept: '*/*',
        },
        responseType: 'blob',
        params: queryParams,
      },
    );
  },

  create: (data: DeploymentRequirements) => {
    return axiosInstance.post<Deployment>('/v1/deployments', data);
  },

  updateToFullDeployment: (
    deployment_id: string,
    credentials: UserCredentials,
  ) => {
    return axiosInstance.put<Deployment>(
      `/v1/deployments/${deployment_id}`,
      credentials,
    );
  },

  updatePartialDeployment: (
    credentials: UserCredentials,
    update_type: UpdateTypeLiterals,
  ) => {
    return axiosInstance.put<Deployment>(`/v1/deployments/partial`, {
      ...credentials,
      update_type,
    });
  },

  /**
   *  If user is in partial deployment tab, and there is an active partial deployment, we compare the library against that old PARTIAL deployment. Else, we compare to the latest FULL deployment
       
   * @param deploymentTabSelected 

   */
  getChangeReport: (queryParams: {
    filters: ChangeReportFilters;
    deploymentTab: string | undefined;
  }) => {
    return axiosInstance.get<{
      oldDeployment: DeploymentJsonFileOrNull;
      newDeployment: DeploymentJsonFile;
    }>(`/v1/deployments/change-report`, {
      params: queryParams,
    });
  },

  get: (queryParams: QueryCommonParams) => {
    return axiosInstance.get<PaginationResponse<Deployment>>(
      `/v1/deployments`,
      {
        params: queryParams,
      },
    );
  },

  getAllActiveDeployments: () => {
    return axiosInstance.get<Deployment[]>(`/v1/deployments/active`);
  },
  getLatestActiveDeployment: () => {
    return axiosInstance.get<Deployment | null>(
      `/v1/deployments/latest-active`,
    );
  },

  getSingleDeployment: (deployment_id: Deployment['id']) => {
    return axiosInstance.get<Deployment>(`/v1/deployments/${deployment_id}`);
  },

  checkPartialDeployment: () => {
    return axiosInstance.get<Deployment | null>(`/v1/deployments/partial`);
  },

  canDeploy: (deploymentTabSelected: string | undefined) => {
    return axiosInstance.get<CanDeployApiResponse>(
      `/v1/deployments/can-deploy`,

      {
        params: { deploymentTab: deploymentTabSelected },
      },
    );
  },
};

export default DeploymentService;
