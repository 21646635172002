import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { Therapy } from 'interfaces/therapy';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

type TherapySelectorProps = {
  record: Concentration | null;
  control: Control<ConcentrationFormData, any>;
  isConfirmed: boolean;
  watchTherapy:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  therapies: Therapy[];
  canManage: boolean;
};

const TherapySelector: FC<TherapySelectorProps> = ({
  record,
  control,
  isConfirmed,
  watchTherapy,
  therapies,
  canManage,
}) => {
  const { t } = useTranslation();

  return record?.therapy_required ? (
    <div className="customRow">
      <div className="device-concentration-form__label">
        {t('device.therapy')}
      </div>

      {isConfirmed && watchTherapy?.label ? (
        <div className="device-concentration-form__column device-concentration-form__concentration--therapy">
          {watchTherapy?.label}
        </div>
      ) : (
        <div>
          <div className="device-concentration-form__select">
            <Controller
              control={control}
              name="therapy"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  id="therapy"
                  maxMenuHeight={300}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value as any}
                  isMulti={false}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  aria-label="therapy"
                  isClearable
                  isSearchable
                  placeholder="- Select therapy -"
                  options={therapies.map((therapy: Therapy) => ({
                    label: therapy.name,
                    value: therapy.id,
                  }))}
                  isDisabled={!canManage}
                />
              )}
            />
          </div>
          {record.is_complete && !record.is_complete_therapy ? (
            <div className="invalid-feedback pt-1">
              {t('device.errors.therapy__required')}
            </div>
          ) : null}
        </div>
      )}
    </div>
  ) : null;
};

export default TherapySelector;
