import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'interfaces/user';
import { RootState } from 'store';

export interface AuthInitialState {
  user: User | null;
}

const initialState: AuthInitialState = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<AuthInitialState>) => {
      state.user = action.payload.user;
    },
    reset: () => ({ ...initialState }),
  },
});

// Selectors
export const selectUser = (state: RootState) => state.auth.user;

export const authActions = authSlice.actions;

export default authSlice.reducer;
