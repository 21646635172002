export const ReactQueryKeys = {
  ADVISORIES: 'advisories',
  CARE_AREAS: 'care_areas',
  COMPANIES: 'companies',
  DEPLOYMENTS: 'deployments',
  CAN_DEPLOY: 'can_deploy',
  DEVICES: 'devices',
  DRUGS: 'drugs',
  DRUG_CARE_AREAS: 'drug_care_areas',
  FACILITIES: 'facilities',
  FACILITY_CARE_AREAS: 'facility_care_areas',
  LIST_DRUGS: 'list_drugs',
  LIST_CONCENTRATIONS: 'list_concentrations',
  PARTIAL_DEPLOYMENT: 'partial_deployment',
  SINGLE_DEPLOYMENT: 'single_deployment',
  TEMPORARY_JSON_FILE: 'temporary_json_file',
  THERAPIES: 'therapies',
  BASIC_MODE_ADVISORY_REASONS: 'basic_mode_advisory_reasons',
  ADVISORY_BASIC_MODE_REASONS: 'advisory_basic_mode_reasons',
  USERS: 'users',
  REPORTS_DRUG_REPORT: 'reports_drug_report',
  HELP: 'help',
};
