import ContentHeader from '../../components/UI/ContentHeader/ContentHeader';
import { MASTER_LINKS } from '../../constants/master-list-tabs';
import TherapyList from './TherapyList/TherapyList';
import TherapyService from '../../services/TherapyService';
import { ReactQueryKeys } from '../../constants/react-query-keys';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ColumnFiltersState, PaginationState } from '@tanstack/react-table';
import { SweetAlertResult } from 'sweetalert2';
import SwalAlert, {
  firePreConfirmAlert,
} from '../../components/UI/SwalAlert/SwalAlert';
import { toast } from 'react-hot-toast';
import { ADMINISTRATION, AUTHOR } from '../../constants/roles';
import { useSelector } from 'react-redux';
import intersection from 'lodash.intersection';
import { useTranslation } from 'react-i18next';
import { Therapy } from '../../interfaces/therapy';
import Spinner from 'components/UI/Spinner/Spinner';
import HospitalTotalLabels from 'components/UI/HospitalTotalLabels/HospitalTotalLabels';
import { selectUser } from 'store/slices/auth';

const Therapies = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const user = useSelector(selectUser);
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;

  const {
    data: therapies,
    isLoading: isLoadingTherapies,
    isFetching,
  } = useQuery(
    [
      ReactQueryKeys.THERAPIES,
      {
        columnFilters,
        pagination,
      },
    ],
    () =>
      TherapyService.get({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
        columnFilters: columnFilters,
      }),
    { keepPreviousData: true },
  );

  /**
   * Update search filters for the api call
   *
   * @param value The search value
   */
  const handleUpdateFilter = (value: string) => {
    setColumnFilters([
      {
        id: 'name',
        value,
      },
    ]);
  };

  // Show alert for confirming of deletion a record
  const handleDeleteShow = (therapy: Therapy) => {
    firePreConfirmAlert({
      title: t('therapies.messages.modal__delete__title'),
      html: t('therapies.messages.modal__delete__content', {
        name: therapy.name,
      }),
      preConfirm: () => {
        return TherapyService.delete(String(therapy.id))
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.THERAPIES]);
        navigate('/therapies');
        toast.success(t('therapies.messages.delete__success'));
      }
    });
  };

  return (
    <>
      <ContentHeader links={MASTER_LINKS} />
      <div className="main-container with-tabs">
        <div className="row">
          <div className="col-3">
            {isLoadingTherapies ? (
              <Spinner isAbsolute />
            ) : (
              <>
                <HospitalTotalLabels
                  total={therapies?.data.total}
                  translationKey="therapies"
                />
                <TherapyList
                  refetch={isLoadingTherapies || isFetching}
                  canManage={canManage}
                  onDelete={handleDeleteShow}
                  updateFilter={handleUpdateFilter}
                  therapyResponse={therapies?.data}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </>
            )}
          </div>
          <div className="col-9 ps-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Therapies;
