import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'config/axios';
import { PaginationResponse } from 'interfaces/pagination-response';
import { Therapy } from 'interfaces/therapy';

export type TherapyResponse = PaginationResponse<Therapy>;

const TherapyService = {
  create: (data: Partial<Therapy>) => {
    return axiosInstance.post<Therapy>('/v1/therapies', data);
  },

  update: (id: string | number, data: Partial<Therapy>) => {
    return axiosInstance.patch<Therapy>(`/v1/therapies/${id}`, data);
  },

  find: (id: string | number, config?: AxiosRequestConfig) => {
    return axiosInstance.get<Therapy>(`/v1/therapies/${id}`, config);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<Therapy>(`/v1/therapies/${id}`);
  },

  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<TherapyResponse>('/v1/therapies', {
      params: queryParams,
    });
  },
};

export default TherapyService;
