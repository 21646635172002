import { forwardRef, Ref, useEffect, useState } from 'react';

const toFixedWithNoRounding = (n: number, fixed: number): number =>
  +(`${n}`.match(new RegExp(`^-?\\d+(?:.\\d{0,${fixed}})?`)) as string[])[0];
const toFixedWhenThereIsRounding = (n: number, fixed: number) => {
  return Number(Math.round(+(n + 'e' + fixed)) + 'e' + -fixed).toFixed(fixed);
};

/** toNearestWholeNumber: if user inputs decimals onBlur, we round to whole number */
type RoundingType =
  | 'none'
  | 'toNearestWholeNumber'
  | 'toAllowedNumberOfDecimals';

type DecimalInputProps = {
  disabled?: boolean;
  onBlur?: Function;
  precision?: number;
  alignCenter?: boolean;
  value?: string | undefined;
  onChange: Function;
  className?: string;
  placeholder?: string;
  rounding?: RoundingType;
};

const allowedKeys = [
  'Backspace',
  'Tab',
  'Escape',
  'ArrowLeft',
  'ArrowUp',
  'ArrowRight',
  'ArrowDown',
  'Delete',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
];

const countDecimals = (value: string | number) => {
  if (value === '') return 0;
  return String(value).split('.').length > 1
    ? String(value).split('.')[1].length
    : 0;
};

function hasOnlyZeroDecimals(value: string) {
  return /^\d+(\.0+)?$/.test(value);
}

/** Check if the number contains only 0's on the decimal part and returns the whole number i
 *
 * @param number
 * @returns
 */
function ifNumberHasOnlyZeroDecimalsReturnWholePart(number: string) {
  //if value contains only 0's on the decimal part, we just leave the whole number
  if (hasOnlyZeroDecimals(number)) {
    const wholeNumber = number.split('.')[0];
    return wholeNumber;
  }
  return number;
}

// eslint-disable-next-line react/display-name
const DecimalInput = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      disabled = false,
      precision = 0,
      className = '',
      placeholder = '',
      rounding = 'none',
      alignCenter = true,
      ...rest
    }: DecimalInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [stateValue, setStateValue] = useState<string | undefined>('');
    const [numberPrecision, setNumberPrecision] = useState<number>(precision);

    useEffect(() => {
      setNumberPrecision(precision);
    }, [precision]);

    useEffect(() => {
      if (!isNaN(value as any)) {
        setStateValue(value);
      } else if (value === '.') {
        setStateValue('.');
      } else {
        setStateValue('');
      }
    }, [value]);

    /**
     * Used when there is rounding
     * and you can enter more decimals than precision
     *
     * @param value
     * @param hasDecimal
     * @returns
     */
    const getRoundedFormattedValue = (value: string, hasDecimal: boolean) => {
      // turn .9 into 0.9
      if (/^\.\d$/.test(value)) {
        return `0${value}`;
      }

      if (value === '') return '';

      return hasDecimal ? value : Number(value);
    };

    /**
     * Used when there is no rounding
     * and you can not enter more decimals than precision
     *
     * @param value
     * @param numOfDec
     * @param precision
     * @param hasDecimal
     * @returns
     */
    const getFormattedValue = (
      value: string,
      numOfDec: number,
      precision: number,
      hasDecimal: boolean,
    ) => {
      if (numOfDec > precision) {
        return toFixedWithNoRounding(Number(value), precision);
      }

      if (/^\.\d$/.test(value)) {
        return `0${value}`;
      }

      if (value === '') return '';

      return hasDecimal ? value : Number(value);
    };

    /**
     * Handle change of input
     *
     * @param event Keybord Event
     * @returns
     */
    const handleChange = (event: any) => {
      const value = event.target.value;

      if (String(value).split('.').length > 2) return;

      const hasDecimal = String(value).includes('.');
      const numOfDec = hasDecimal ? countDecimals(value) : 0;

      let floating;

      if (rounding !== 'none') {
        floating = getRoundedFormattedValue(value, hasDecimal);
      } else {
        floating = getFormattedValue(
          value,
          numOfDec,
          numberPrecision,
          hasDecimal,
        );
      }

      setStateValue(String(floating));
      onChange(String(floating));
    };

    /**
     * Prevent not allowed keys
     *
     * @param event Keyboard Event
     * @returns void
     */
    const handleKeyDown = (event: any) => {
      // Allow select all
      if (
        (event.metaKey && event.key.toLowerCase() === 'a') ||
        (event.ctrlKey && event.key.toLowerCase() === 'a')
      ) {
        return true;
      }

      if (rounding === 'none') {
        // For 0 decimals don't allow dot
        if (numberPrecision === 0 && event.key === '.') {
          event.preventDefault();
        }
      }

      if (!allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    };

    /**
     * Check if the number has . at the end
     *
     * @param event Blur event
     */
    const handleBlur = (event: any) => {
      let value = String(event.target.value);

      if (value === '.') {
        setStateValue('');
        onChange('');
        //if value contains a decimal point but no decimals, we set it back to a whole number
      } else if (/^\d*\.$/.test(value)) {
        value = value.replace(/\.$/, '');
        const newValue = value === '' ? '' : value;
        setStateValue(newValue);
        onChange(newValue);
      } else if (hasOnlyZeroDecimals(value)) {
        const wholeNumber = ifNumberHasOnlyZeroDecimalsReturnWholePart(value);
        setStateValue(wholeNumber);
        onChange(wholeNumber);
      }
      if (rounding === 'toAllowedNumberOfDecimals') {
        //if number of decimals is more than allowed we round the number
        const hasDecimal = String(value).includes('.');
        const numOfDec = hasDecimal ? countDecimals(value) : 0;

        if (numOfDec > precision) {
          let newValue = toFixedWhenThereIsRounding(Number(value), precision);

          newValue = ifNumberHasOnlyZeroDecimalsReturnWholePart(newValue);

          setStateValue(newValue + '');
          onChange(newValue);
        }
      }

      if (rounding === 'toNearestWholeNumber') {
        const newValue = Math.round(+value);
        setStateValue(newValue + '');
        onChange(newValue);
      }

      if (onBlur) {
        onBlur(event);
      }
    };

    return (
      <>
        <input
          ref={ref}
          onChange={handleChange}
          onBlur={handleBlur}
          value={stateValue}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={
            `decimal-input form-control form-control-lg rounded-1 ${alignCenter ? 'text-center' : ''} ` +
            className
          }
          {...rest}
        />
      </>
    );
  },
);

export default DecimalInput;
