export enum AudioLevelAlarmEnum {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const AUDIO_LEVEL_ALARM_VALUES = {
  LOW: 'LOW',
  MEDIUM: 'MED',
  HIGH: 'HIGH',
};

export const AUDIO_LEVEL_ALARM_IDS = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
};

export const AUDIO_LEVEL_ALARM_VALUES_NUMBERS = {
  LOW: 1,
  MED: 2,
  HIGH: 3,
} as const;
