import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  clearSearch: boolean;
  pageIndex: number;
}

const initialState: InitialState = {
  clearSearch: false,
  pageIndex: 0,
};

const drugSlice = createSlice({
  name: 'drug',
  initialState,
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    clearSearch: (state) => {
      state.clearSearch = true;
    },
    resetClearSearch: (state) => {
      state.clearSearch = false;
    },
  },
});

export const drugActions = drugSlice.actions;

export default drugSlice.reducer;
