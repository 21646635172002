import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { IoIosClose } from 'react-icons/io';

const ToasterNotifications = () => {
  return (
    <Toaster
      position="bottom-left"
      toastOptions={{
        style: {
          border: '1px solid #292E38',
          padding: '16px',
          color: '#292E38',
          borderRadius: '3px',
        },
        duration: 4000,
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button
                  data-testid="toast_close_btn"
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                  onClick={() => toast.remove(t.id)}
                >
                  <IoIosClose size={24} />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToasterNotifications;
