import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import CompanyService from '../../../services/CompanyService';
import { Company } from '../../../interfaces/company';
import { toast } from 'react-hot-toast';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from '../../../constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from '../../../store/slices/overlay';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';

type FormData = {
  name: string;
  create_library: boolean;
  number_care_areas: number;
  number_drugs: number;
  number_advisories: number;
  number_therapies: number;
};

const CompaniesModalForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      create_library: false,
      number_care_areas: undefined,
      number_drugs: undefined,
      number_advisories: undefined,
      number_therapies: undefined,
    },
  });

  const watchCreateLibrary = watch('create_library');

  /**
   * Submit form and create company
   *
   * @param data Company data
   */
  const handleSubmitData = async (data: any) => {
    setFormSubmitting(true);

    if (params.id) {
      try {
        await CompanyService.update(params.id, data);
        queryClient.invalidateQueries([ReactQueryKeys.COMPANIES]);
        toast.success(t('companies.messages.update__success'));
        navigate('/companies');
      } catch (e: any) {
        let message = t('companies.messages.update__error');
        if (e.response.data?.statusCode === 400) {
          message = e.response.data?.message;
        }
        toast.error(message);
      }
    } else {
      try {
        await CompanyService.create({
          ...data,
          create_library: !!data.create_library,
          number_care_areas:
            !!data.create_library && !isNaN(data.number_care_areas)
              ? Number(data.number_care_areas)
              : undefined,
          number_drugs:
            !!data.create_library && !isNaN(data.number_drugs)
              ? Number(data.number_drugs)
              : undefined,
          number_advisories:
            !!data.create_library && !isNaN(data.number_advisories)
              ? Number(data.number_advisories)
              : undefined,
          number_therapies:
            !!data.create_library && !isNaN(data.number_therapies)
              ? Number(data.number_therapies)
              : undefined,
        });
        queryClient.invalidateQueries([ReactQueryKeys.COMPANIES]);
        toast.success(t('companies.messages.create__success'));
        navigate('/companies');
      } catch (e: any) {
        let message = t('companies.messages.create__error');
        if (e.response.data?.statusCode === 400) {
          message = e.response.data?.message;
        }
        toast.error(message);
      }
    }

    setFormSubmitting(false);
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate('/companies');
    }
  };

  useEffect(() => {
    dispatch(overlayActions.open({ path: '/companies' }));

    if (params.id) {
      setFormLoading(true);
      CompanyService.find(params.id)
        .then(({ data }: { data: Company }) => {
          setValue('name', data.name);
          setFormLoading(false);
        })
        .catch((_) => {
          toast.error(t('companies.messages.loading__error'));
          navigate('/companies');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <form
      id="formid"
      autoComplete="off"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div
        className={
          'modal-sidebar-body ' +
          (formLoading || formSubmitting ? 'opacity-50' : '')
        }
      >
        {formLoading && <Spinner isAbsolute />}

        <div className="row">
          <div className="col-12">
            <label
              htmlFor="companyName"
              className="form-label"
              data-testid="companyName"
            >
              {t('companies.name')} *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="companyName"
              data-testid="companyInput"
              {...register('name', {
                required: true,
              })}
            />
            {errors?.name?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('companies.errors.name__required')}
              </div>
            )}
          </div>
        </div>

        {!params.id && (
          <div className="row pt-4">
            <div className="col-12">
              <Controller
                control={control}
                name="create_library"
                rules={{}}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Checkbox
                    disabled={false}
                    title={t('companies.create_library')}
                    id="create_library"
                    data-testid="create_library"
                    checked={value}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={'create_library'}
                  />
                )}
              />
            </div>
          </div>
        )}

        {!params.id && watchCreateLibrary && (
          <>
            <div className="row pt-3">
              {/* Care Areas */}
              <div className="col-6">
                <label
                  htmlFor="numberCareAreas"
                  className="form-label"
                  data-testid="numberCareAreas"
                >
                  {t('companies.number_care_areas')} *
                </label>

                <>
                  <Controller
                    control={control}
                    name="number_care_areas"
                    rules={{
                      min: 1,
                      max: 32,
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DecimalInput
                        value={String(value)}
                        onChange={onChange}
                        onBlur={onBlur}
                        precision={0}
                        ref={ref}
                      />
                    )}
                  />

                  {errors?.number_care_areas?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_care_areas__required')}
                    </div>
                  )}

                  {errors?.number_care_areas?.type === 'min' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_care_areas__min')}
                    </div>
                  )}

                  {errors?.number_care_areas?.type === 'max' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_care_areas__max')}
                    </div>
                  )}
                </>
              </div>
              {/* Drugs */}
              <div className="col-6">
                <label
                  htmlFor="numberDrugs"
                  className="form-label"
                  data-testid="numberDrugs"
                >
                  {t('companies.number_drugs')} *
                </label>

                <>
                  <Controller
                    control={control}
                    name="number_drugs"
                    rules={{
                      min: 1,
                      max: 10000,
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DecimalInput
                        value={String(value)}
                        onChange={onChange}
                        onBlur={onBlur}
                        precision={0}
                        ref={ref}
                      />
                    )}
                  />

                  {errors?.number_drugs?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_drugs__required')}
                    </div>
                  )}

                  {errors?.number_drugs?.type === 'min' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_drugs__min')}
                    </div>
                  )}

                  {errors?.number_drugs?.type === 'max' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_drugs__max')}
                    </div>
                  )}
                </>
              </div>
            </div>

            <div className="row pt-3">
              {/* Advisories */}
              <div className="col-6">
                <label
                  htmlFor="numberAdvisories"
                  className="form-label"
                  data-testid="numberAdvisories"
                >
                  {t('companies.number_advisories')} *
                </label>

                <>
                  <Controller
                    control={control}
                    name="number_advisories"
                    rules={{
                      min: 1,
                      max: 800,
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DecimalInput
                        value={String(value)}
                        onChange={onChange}
                        onBlur={onBlur}
                        precision={0}
                        ref={ref}
                      />
                    )}
                  />

                  {errors?.number_advisories?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_advisories__required')}
                    </div>
                  )}

                  {errors?.number_advisories?.type === 'min' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_advisories__min')}
                    </div>
                  )}

                  {errors?.number_advisories?.type === 'max' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_advisories__max')}
                    </div>
                  )}
                </>
              </div>

              {/* Therapies */}
              <div className="col-6">
                <label
                  htmlFor="numberTherapies"
                  className="form-label"
                  data-testid="numberTherapies"
                >
                  {t('companies.number_therapies')} *
                </label>

                <>
                  <Controller
                    control={control}
                    name="number_therapies"
                    rules={{
                      min: 1,
                      max: 500,
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DecimalInput
                        value={String(value)}
                        onChange={onChange}
                        onBlur={onBlur}
                        precision={0}
                        ref={ref}
                      />
                    )}
                  />

                  {errors?.number_therapies?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_therapies__required')}
                    </div>
                  )}

                  {errors?.number_therapies?.type === 'min' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_therapies__min')}
                    </div>
                  )}

                  {errors?.number_therapies?.type === 'max' && (
                    <div className="invalid-feedback pt-1">
                      {t('companies.errors.number_therapies__max')}
                    </div>
                  )}
                </>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={formSubmitting}
          defaultLabel={t('buttons.save')}
          loadingLabel={params.id ? t('buttons.updating') : t('buttons.saving')}
          type="submit"
          disabled={formSubmitting || formLoading}
        ></Button>

        <Link
          to="/companies"
          className={`btn btn-lg rounded btn-secondary ${
            formSubmitting || formLoading ? 'disabled' : ''
          }`}
        >
          {t('buttons.cancel')}
        </Link>
      </div>
    </form>
  );
};

export default CompaniesModalForm;
