import ContentHeader from '../../components/UI/ContentHeader/ContentHeader';
import { MASTER_LINKS } from '../../constants/master-list-tabs';
import FacilityList from './FacilityList/FacilityList';
import FacilityService from '../../services/FacilityService';
import { ReactQueryKeys } from '../../constants/react-query-keys';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { ColumnFiltersState, PaginationState } from '@tanstack/react-table';
// import { SweetAlertResult } from 'sweetalert2';
// import { Facility } from '../../interfaces/facility';
// import SwalAlert, {
//   firePreConfirmAlert,
// } from '../../components/UI/SwalAlert/SwalAlert';
// import { toast } from 'react-hot-toast';
// import { ADMINISTRATION, AUTHOR } from '../../constants/roles';
// import { CognitoUser } from '../../interfaces/user';
// import { useSelector } from 'react-redux';
// import intersection from 'lodash.intersection';
// import { useTranslation } from 'react-i18next';
import Spinner from 'components/UI/Spinner/Spinner';
// import { selectUser } from 'store/slices/auth';
// import HospitalTotalLabels from 'components/UI/HospitalTotalLabels/HospitalTotalLabels';

const Facilities = () => {
  // const { t } = useTranslation();
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  // const user: CognitoUser = useSelector((state: any) => state.auth.user);
  // const canManage =
  //   !!intersection([user?.roles], [ADMINISTRATION, AUTHOR]).length;

  const {
    data: facilities,
    isLoading: isLoadingFacilities,
    isFetching,
  } = useQuery(
    [
      ReactQueryKeys.FACILITIES,
      {
        columnFilters,
        pagination,
      },
    ],
    () =>
      FacilityService.get({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
        columnFilters: columnFilters,
      }),
    { keepPreviousData: true },
  );

  /**
   * Update search filters for the api call
   *
   * @param value The search value
   */
  const handleUpdateFilter = (value: string) => {
    setColumnFilters([
      {
        id: 'name',
        value,
      },
    ]);
  };

  // Show alert for confirming of deletion a record
  // const handleDeleteShow = (facility: Facility) => {
  //   firePreConfirmAlert({
  //     title: t('facilities.messages.modal__delete__title'),
  //     html: t('facilities.messages.modal__delete__content', {
  //       name: facility.name,
  //     }),
  //     preConfirm: () => {
  //       return FacilityService.delete(String(facility.id))
  //         .then(({ data }) => {
  //           return data;
  //         })
  //         .catch((error) => {
  //           SwalAlert.showValidationMessage(error?.response?.data?.message);
  //         });
  //     },
  //   }).then((data: SweetAlertResult) => {
  //     if (data.isConfirmed) {
  //       queryClient.invalidateQueries([ReactQueryKeys.FACILITIES]);
  //       navigate('/facilities');
  //       toast.success(t('facilities.messages.delete__success'));
  //     }
  //   });
  // };

  return (
    <>
      <ContentHeader links={MASTER_LINKS} />
      <div className="main-container with-tabs">
        <div className="row">
          <div className="col-3">
            {isLoadingFacilities ? (
              <Spinner isAbsolute />
            ) : (
              <>
                {/* <HospitalTotalLabels
                  translationKey="facilities"
                  total={facilities?.data?.total}
                /> */}

                <FacilityList
                  // onDelete={handleDeleteShow}
                  updateFilter={handleUpdateFilter}
                  facilityResponse={facilities?.data}
                  refetch={isLoadingFacilities || isFetching}

                  // pagination={pagination}
                  // setPagination={setPagination}
                />
              </>
            )}
          </div>
          <div className="col-9 ps-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
