import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import {
  DrugConcentration,
  GetDrugsWithConcentrationsRecord,
} from 'interfaces/drug-concentration';
import { ListConcentration } from 'interfaces/list-concentration';
import { ListDrug } from 'interfaces/list-drug';
import { Concentration } from 'interfaces/concentration';
import {
  PaginationResponse,
  PaginationResponseWithoutTotal,
  QueryCommonParams,
} from 'interfaces/pagination-response';
import { Drug } from 'interfaces/drug';
import { AxiosRequestConfig } from 'axios';

export type ListDrugResponse = PaginationResponse<ListDrug>;

export type DrugConcentrationResponse = {
  records: GetDrugsWithConcentrationsRecord[];
};

const DrugService = {
  create: (data: Partial<ListDrug>) => {
    return axiosInstance.post<{ record: ListDrug; totalRecords: ListDrug[] }>(
      '/v1/list-drugs',
      data,
    );
  },

  update: (id: string | number, data: Partial<ListDrug>) => {
    return axiosInstance.patch<ListDrug>(`/v1/list-drugs/${id}`, data);
  },

  find: (id: string | number, config?: AxiosRequestConfig) => {
    return axiosInstance.get<ListDrug>(`/v1/list-drugs/${id}`, config);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<ListDrug>(`/v1/list-drugs/${id}`);
  },

  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
    includeBaseDrug?: boolean;
  }) => {
    return axiosInstance.get<
      ListDrugResponse & { totalConcentrations: number }
    >('/v1/list-drugs', {
      params: queryParams,
    });
  },

  getListDrugsWithListConcentrations: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<ListDrugResponse>(
      '/v1/list-drugs/concentrations',
      {
        params: queryParams,
      },
    );
  },

  getListConcentrations: (
    drugId: number | string,
    queryParams: QueryCommonParams,
  ) => {
    return axiosInstance.get<PaginationResponseWithoutTotal<ListConcentration>>(
      `/v1/list-drugs/${drugId}/list-concentrations`,
      {
        params: queryParams,
      },
    );
  },

  deleteListConcentration: (drugId: string | number, id: string | number) => {
    return axiosInstance.delete<ListConcentration>(
      `/v1/list-drugs/${drugId}/list-concentrations/${id}`,
    );
  },

  createConcentration: (
    drugId: string | number,
    data: Partial<ListConcentration>,
  ) => {
    return axiosInstance.post<ListConcentration>(
      `/v1/list-drugs/${drugId}/list-concentrations`,
      data,
    );
  },

  assignConcentrationToCareArea(data: {
    careAreaId: number;
    facilityId: number;
    listDrugId: number;
    listConcentrationId: number;
  }) {
    return axiosInstance.post<DrugConcentration>(
      `/v1/drugs/concentrations`,
      data,
    );
  },

  getDrugsWithConcentrations: (
    queryParams: QueryCommonParams & {
      careAreaId: number;
      facilityId: number;
    },
  ) => {
    return axiosInstance.get<
      PaginationResponseWithoutTotal<GetDrugsWithConcentrationsRecord>
    >('/v1/drugs/concentrations', {
      params: queryParams,
    });
  },

  deleteConcentration: (
    id: string | number,
    queryParams: {
      careAreaId: number;
      facilityId: number;
    },
  ) => {
    return axiosInstance.delete(`/v1/drugs/concentrations/${id}`, {
      params: queryParams,
    });
  },

  deleteDrug: (
    id: string | number,
    queryParams: {
      careAreaId: number;
      facilityId: number;
    },
  ) => {
    return axiosInstance.delete(`/v1/drugs/${id}`, {
      params: queryParams,
    });
  },

  getDrug: (id: string | number) => {
    return axiosInstance.get<Drug>(`/v1/drugs/${id}`);
  },

  getConcentration: (id: string | number) => {
    return axiosInstance.get(`/v1/drugs/concentrations/${id}`);
  },

  updateConcentration: (id: string | number, data: Partial<Concentration>) => {
    return axiosInstance.patch(`/v1/drugs/concentrations/${id}`, data);
  },

  deleteAdvisory: (id: string | number) => {
    return axiosInstance.delete(`/v1/drugs/concentrations/${id}/advisories`);
  },

  assignAdvisory: (
    id: string | number,
    data: {
      advisory_id: string | number;
    },
  ) => {
    return axiosInstance.patch(
      `/v1/drugs/concentrations/${id}/advisories`,
      data,
    );
  },

  copyDrugToCareArea: (
    drugId: string | number,
    careAreaId: string | number,
    facilityId: string | number,
  ) => {
    return axiosInstance.post(`/v1/drugs/${drugId}/copy`, {
      care_area_id: +careAreaId,
      facility_id: +facilityId,
    });
  },
};

export default DrugService;
