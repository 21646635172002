import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';
I18n.putVocabularies(translations);

export const i18next_KEY = 'i18nextLng';
export const FALLBACK_LNG = 'en';

export const LANGUAGES = {
  en: 'EN',
  de: 'DE',
};

i18next.use(initReactI18next).use(LanguageDetector).use(Backend).init({
  fallbackLng: FALLBACK_LNG,
  debug: false,
});

I18n.setLanguage(localStorage.getItem(i18next_KEY) ?? FALLBACK_LNG);
