import { CareAreaChangesReport } from 'interfaces/care-area';
import { DrugChangesReportType } from 'interfaces/drug';
import {
  FacilityChangesReport,
  RecordTypeChangesReport,
} from 'interfaces/facility';

export const getViewChangesCellClass = (
  row: RecordTypeChangesReport,
  {
    limit,
    key,
    bolusAndLoad,
  }: {
    key?:
      | keyof FacilityChangesReport
      | keyof CareAreaChangesReport
      | keyof DrugChangesReportType
      //add infuseAs object keys
      | keyof DrugChangesReportType['infuseAs'];
    limit?:
      | 'lowerHardLimit'
      | 'lowerSoftLimit'
      | 'upperSoftLimit'
      | 'upperHardLimit';
    bolusAndLoad?: keyof Pick<
      DrugChangesReportType['loadingDose'],
      'amount' | 'time'
    >;
  } = {},
) => {
  switch (row.recordType) {
    case 'ADDED':
      return 'added';
    case 'DELETED':
      return 'deleted';
    case 'UPDATED': {
      //check if column key is present in oldValues
      const updatedClass = 'updated';
      if (row.oldValues && key && key in row.oldValues) {
        //CareAreaChanges limit keys
        if (key === 'patientWeight' || key === 'bodySurfaceArea') {
          if (
            limit &&
            limit in (row.oldValues[key] as CareAreaChangesReport[typeof key])
          ) {
            return updatedClass;
          }
          return '';
        }
        //DrugChanges limit keys
        if (key === 'loadingDose' || key === 'bolus') {
          if (
            limit &&
            bolusAndLoad &&
            //@ts-ignore
            row.oldValues?.[key]?.[bolusAndLoad] &&
            limit in
              (row.oldValues[key] as DrugChangesReportType[typeof key])[
                bolusAndLoad
              ]
          ) {
            return updatedClass;
          }
          return '';
        }
        if (
          key === 'concentrationLimits' ||
          key === 'doseRateLimits' ||
          key === 'time' ||
          key === 'doseLimits' ||
          key === 'drugAmountLimits'
        ) {
          if (
            limit &&
            limit in (row.oldValues[key] as DrugChangesReportType[typeof key])
          ) {
            return updatedClass;
          }
          return '';
        }
        return updatedClass;
      }
      return '';
    }
  }
};
