import Select from 'react-select';
import { Control, Controller } from 'react-hook-form';
import { DeploymentRequirements, DeviceOption } from 'interfaces/deployment';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { debounce } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { toast } from 'react-hot-toast';
import { PaginationState, ColumnFiltersState } from '@tanstack/react-table';
import DevicesService from 'services/DevicesService';

const DeviceSelector = ({
  control,
  requiredError,
  libraryHasNotChangedSinceLastDeployment,
}: {
  control: Control<DeploymentRequirements, any>;
  requiredError: boolean;
  libraryHasNotChangedSinceLastDeployment: boolean;
}) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState<DeviceOption[]>([]);

  const handleInputChange = debounce((inputValue: string) => {
    setColumnFilters([{ id: 'name', value: inputValue }]);
  }, 500);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const { data, isLoading, isFetching } = useQuery(
    [
      ReactQueryKeys.DEVICES,
      {
        pageIndex,
        pageSize,
        columnFilters,
      },
    ],
    () =>
      DevicesService.getAll({
        pageIndex,
        pageSize,
        columnFilters,
      }),
    {
      onSuccess: (data) => {
        setDevices((prevDevices) => {
          const newDevices = data.data.records.map((dev) => ({
            ...dev,
            value: dev.name,
            label: dev.name,
          }));
          // Filter out any devices that already exist in the previous data
          return [...prevDevices, ...newDevices].filter(
            (device, index, self) =>
              index === self.findIndex((d) => d.id === device.id),
          );
        });
      },
      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
      keepPreviousData: true,
    },
  );

  const handlePageChange = (pageUpdated: number) => {
    if (
      typeof data?.data.totalPages === 'undefined' ||
      pageUpdated >= data?.data.totalPages
    ) {
      return;
    }

    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: pageUpdated,
    }));
  };

  return (
    <div className="pb-3">
      <label htmlFor="devices_selected" className="form-label">
        Select Device
      </label>
      <Controller
        control={control}
        name="devices_selected"
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value, ref } }) => (
          <div
            style={{
              cursor: libraryHasNotChangedSinceLastDeployment
                ? 'not-allowed'
                : 'auto',
            }}
          >
            <Select
              id="devices"
              maxMenuHeight={300}
              ref={ref}
              onChange={(data) => {
                return onChange(data.slice(-1) ?? []);
              }}
              onInputChange={handleInputChange}
              onMenuScrollToBottom={() => handlePageChange(pageIndex + 1)}
              isLoading={isLoading || isFetching}
              /*    closeMenuOnSelect={false} */
              value={value as any}
              isMulti
              className={`react-select-container-multiselector react-select-container`}
              classNamePrefix="react-select"
              isClearable
              isSearchable
              aria-label="devices"
              hideSelectedOptions={false}
              placeholder={t('deployment.form.device_selector_placeholder')}
              options={devices}
              isDisabled={libraryHasNotChangedSinceLastDeployment}
            />
          </div>
        )}
      />
      {requiredError && (
        <div className="invalid-feedback">
          {t('deployment.errors.select__devices')}
        </div>
      )}
    </div>
  );
};

export default DeviceSelector;
