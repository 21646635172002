import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from '../config/axios';
import { Facility } from '../interfaces/facility';
import { BasicModeAdvisoryReason } from 'interfaces/basic-mode-advisory-reason';

export type BasicModeAdvisoryReasonResponse = {
  records: BasicModeAdvisoryReason[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
};

const BasicModeAdvisoryReasonService = {
  create: (data: Partial<BasicModeAdvisoryReason>) => {
    return axiosInstance.post<BasicModeAdvisoryReason>(
      '/v1/basic-mode-advisory-reasons',
      data,
    );
  },

  update: (id: string | number, data: Partial<BasicModeAdvisoryReason>) => {
    return axiosInstance.patch<Facility>(
      `/v1/basic-mode-advisory-reasons/${id}`,
      data,
    );
  },

  find: (id: string | number) => {
    return axiosInstance.get<BasicModeAdvisoryReason>(
      `/v1/basic-mode-advisory-reasons/${id}`,
    );
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<BasicModeAdvisoryReason>(
      `/v1/basic-mode-advisory-reasons/${id}`,
    );
  },

  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<BasicModeAdvisoryReasonResponse>(
      '/v1/basic-mode-advisory-reasons',
      {
        params: queryParams,
      },
    );
  },
};

export default BasicModeAdvisoryReasonService;
