import { Outlet } from 'react-router-dom';
import './Reports.scss';

const Reports = () => {
  return (
    <div className="d-flex">
      <div className="reports__content">
        <Outlet />
      </div>
    </div>
  );
};

export default Reports;
