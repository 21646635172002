import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './AdminLayout.scss';
import Header from 'components/UI/Header/Header';
import Sidebar from 'components/UI/Sidebar/Sidebar';
import Overlay from 'components/UI/Overlay/Overlay';
import intersection from 'lodash.intersection';
import { ADMINISTRATION, AUTHOR, SUPER_ADMIN } from 'constants/roles'; //REVIEWER
import classnames from 'classnames';
import { selectUser } from 'store/slices/auth';

const AdminLayout = () => {
  const isOverlayOpen: boolean = useSelector(
    (state: any) => state.overlay.isOpen,
  );
  const user = useSelector(selectUser);
  const showSidebar = !!intersection(
    [user?.roles],
    [SUPER_ADMIN, ADMINISTRATION, AUTHOR],
  ).length; //REVIEWER

  const mainContainerClasses = classnames({
    'page-container': true,
    'no-sidebar': !showSidebar,
  });

  return (
    <>
      <Header showSidebar={showSidebar} />
      {showSidebar && <Sidebar />}
      <main className={mainContainerClasses}>
        {isOverlayOpen && <Overlay />}
        <Outlet />
      </main>
    </>
  );
};

export default AdminLayout;
