import type { FC } from 'react';
import {
  IoMdBusiness,
  IoMdPeople,
  IoMdPhonePortrait,
  IoIosStats,
  IoMdCloudDownload,
} from 'react-icons/io';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

type SidebarLinkProps = {
  title: string;
  link: string;
  icon: keyof typeof Icons;
  activeLink?: boolean;
  testid?: string;
};

const Icons = {
  building: <IoMdBusiness size={20} className="nav-sidebar__link-icon" />,
  users: <IoMdPeople size={20} className="nav-sidebar__link-icon" />,
  device: <IoMdPhonePortrait size={20} className="nav-sidebar__link-icon" />,
  reports: <IoIosStats size={20} className="nav-sidebar__link-icon" />,
  deployment: (
    <IoMdCloudDownload size={20} className="nav-sidebar__link-icon" />
  ),
  help: <AiFillQuestionCircle size={20} className="nav-sidebar__link-icon" />,
};

const SidebarLink: FC<SidebarLinkProps> = ({
  title,
  link,
  icon,
  activeLink,
  testid,
}) => {
  return (
    <NavLink
      style={({ isActive }) => ({
        borderLeft:
          isActive || activeLink
            ? '4px solid #0895DF'
            : '4px solid transparent',
        background: isActive || activeLink ? '#3A4250' : 'transparent',
      })}
      className="nav-sidebar__link"
      to={link}
      data-testid={testid}
    >
      {Icons[icon]}
      {title}
    </NavLink>
  );
};

export default SidebarLink;
