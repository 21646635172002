import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  clearSearch: boolean;
}

const initialState: InitialState = {
  clearSearch: false,
};

const facilitySlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.clearSearch = true;
    },
    resetClearSearch: (state) => {
      state.clearSearch = false;
    },
  },
});

export const facilityActions = facilitySlice.actions;

export default facilitySlice.reducer;
