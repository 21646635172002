import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { ConcentrationFormData } from 'interfaces/concentration';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RadioInput from 'components/UI/RadioInput/RadioInput';

type InfusioncompleteProps = {
  register: UseFormRegister<ConcentrationFormData>;
  errors: FieldErrors<ConcentrationFormData>;
  canManage: boolean;
};

const InfusionComplete: FC<InfusioncompleteProps> = ({
  register,
  errors,
  canManage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="customRow position-relative pt-2">
      {!canManage && (
        <div className="device-concentration--disabled-item"></div>
      )}

      <div className="device-concentration-primary-secondary2--width">
        {t('device.infusion_complete')}
      </div>
      <div>
        <div className="customRow ps-0 align-basic-radios">
          <RadioInput
            {...register('infusion_complete', {
              required: true,
            })}
            data-testid="infusion_complete"
            value={'run_kvo_rate'}
            id="run-kvo-rate"
            title={t('device.run_kvo_rate')}
          />
          <RadioInput
            {...register('infusion_complete', {
              required: true,
            })}
            data-testid="infusion_complete"
            value={'maintain_infusion_rate'}
            id="maintain-infusion-rate"
            title={t('device.maintain_infusion_rate')}
          />
        </div>

        {errors?.infusion_complete?.type === 'required' && (
          <div
            className="invalid-feedback"
            data-testid="infusion_complete__required"
          >
            {t('device.errors.one_option__required')}
          </div>
        )}
      </div>
    </div>
  );
};

export default InfusionComplete;
