import './Device.scss';
import Facilities from './Facilities/Facilities';
import FacilityCareAreas from './FacilityCareAreas/FacilityCareAreas';
import { ADMINISTRATION, AUTHOR } from 'constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import intersection from 'lodash.intersection';
import { Facility } from 'interfaces/facility';
import { useEffect, useMemo } from 'react';
import { CareArea } from 'interfaces/care-area';
import CareAreas from './CareAreas/CareAreas';
import ListDrugs from './ListDrugs/ListDrugs';
import Drugs from './Drugs/Drugs';
import { DrugConcentration } from 'interfaces/drug-concentration';
import { Drug } from 'interfaces/drug';
import { selectUser } from 'store/slices/auth';
import {
  deviceActions,
  selectSelectedFacility,
  selectSelectedCareArea,
  selectSelectedDrugConcentration,
  selectSelectedDrug,
  selectIsAddingCareAreas,
  selectIsAddingConcentration,
} from 'store/slices/device';
import ConcentrationForm from './ConcentrationForm/ConcentrationForm';
import DrugForm from './DrugForm/DrugForm';

const Device = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;
  const facility: Facility = useSelector(selectSelectedFacility);
  const careArea: CareArea = useSelector(selectSelectedCareArea);
  const drug: Drug = useSelector(selectSelectedDrug);
  const drugConcentration: DrugConcentration = useSelector(
    selectSelectedDrugConcentration,
  );
  const isAddingCareAreas: boolean = useSelector(selectIsAddingCareAreas);
  const isAddingConcentration: boolean = useSelector(
    selectIsAddingConcentration,
  );

  useEffect(() => {
    return () => {
      dispatch(deviceActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Construct title for the content
   */
  const contentTitle = useMemo(() => {
    let title = '';
    if (facility) {
      title = facility.name;
    }

    if (facility && careArea) {
      title += ' > ' + careArea.name;
    }

    return title;
  }, [facility, careArea]);

  return (
    <div className="d-flex device">
      <div className="device__sidebar">
        <Facilities canManage={canManage} />
        {facility ? (
          <FacilityCareAreas
            facility={facility}
            canManage={canManage}
            disabledAdding={isAddingCareAreas}
          />
        ) : null}
      </div>
      <div className="device__drugs">
        <Drugs
          drug={drug}
          drugConcentration={drugConcentration}
          careArea={careArea}
          facility={facility}
          disabledAdding={!facility || !careArea || isAddingConcentration}
        />
      </div>
      <div className="device__content">
        <h4>{contentTitle}</h4>
        {isAddingCareAreas && facility ? (
          <CareAreas canManage={canManage} facility={facility} />
        ) : null}
        {isAddingConcentration && facility && careArea ? (
          <ListDrugs
            canManage={canManage}
            careArea={careArea}
            facility={facility}
          />
        ) : null}
        {drugConcentration ? (
          <ConcentrationForm concentration={drugConcentration} />
        ) : null}
        {!!drug && !!careArea && !!facility ? (
          <DrugForm drug={drug} facility={facility} />
        ) : null}
      </div>
    </div>
  );
};

export default Device;
