import { InputHTMLAttributes, Ref, forwardRef } from 'react';

interface RadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  classname?: string;
}

// eslint-disable-next-line react/display-name
const RadioInput = forwardRef(
  (
    {
      id,
      title,
      value,
      onChange,
      disabled,
      onBlur,
      classname,
      name,
      ...rest
    }: RadioInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <label
        htmlFor={id}
        className={'radio-button ' + (classname ? classname : '')}
      >
        <input
          id={id}
          type="radio"
          className="form-check-input"
          value={value}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          ref={ref}
          name={name}
          {...rest}
        />
        {title && <span>{title}</span>}
      </label>
    );
  },
);

export default RadioInput;
