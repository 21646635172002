import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/UI/Table/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getViewChangesCellClass } from 'helpers/deployment';
import { AUDIO_LEVEL_ALARM_VALUES_NUMBERS } from 'constants/care-areas';
import { CareAreaChangesReport } from 'interfaces/care-area';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';

type CareAreasChangesProps = {
  careAreas: any[];
};

function setTooltipForUpdatedValues(
  key: keyof CareAreaChangesReport,
  row: CareAreaChangesReport,
  {
    limit,
    replaceValueTooltipText,
  }: {
    limit?:
      | 'lowerHardLimit'
      | 'lowerSoftLimit'
      | 'upperSoftLimit'
      | 'upperHardLimit';
    replaceValueTooltipText?: string;
  } = {},
) {
  const noTooltip = {};
  if (row.oldValues && key in row.oldValues) {
    if (!limit) {
      return {
        'data-tooltip-id': `${row.id}-${key}`,
        'data-tooltip-content': replaceValueTooltipText ?? row.oldValues[key],
      };
    }

    if (
      limit in (row.oldValues[key] as CareAreaChangesReport['patientWeight'])
    ) {
      return {
        'data-tooltip-id': `${row.id}-${key}-${limit}`,
        'data-tooltip-content':
          replaceValueTooltipText ??
          (row.oldValues[key] as CareAreaChangesReport['patientWeight'])[limit],
      };
    }
  }
  return noTooltip;
}

export const getPatientWeightValue = (value: null | number) => {
  const result = value === null || value === 0 ? '' : Number(value);

  return result ? `${result} kg` : '';
};

export const getBodySurfaceAreaValue = (value: null | number) => {
  const result = value === null || value === 0 ? '' : Number(value);

  return result ? `${result} m²` : '';
};

const CareAreasChanges: FC<CareAreasChangesProps> = ({ careAreas }) => {
  const { t } = useTranslation();

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<CareAreaChangesReport>[]>(
    () => [
      {
        id: 'name',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<CareAreaChangesReport, 'name'> = 'name';

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(row.original.oldValues &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key]}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t('reports.dl_changes_report.care_areas.name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'patient_weight',
        header: t('reports.dl_changes_report.care_areas.patient_weight'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'patient_weight_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'patientWeight'> =
                'patientWeight';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'lowerHardLimit'
              > = 'lowerHardLimit';
              const OLD_VALUES = row.original.oldValues;

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getPatientWeightValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getPatientWeightValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'patient_weight_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'patientWeight'> =
                'patientWeight';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getPatientWeightValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getPatientWeightValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'patient_weight_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'patientWeight'> =
                'patientWeight';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'upperSoftLimit'
              > = 'upperSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getPatientWeightValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getPatientWeightValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'patient_weight_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'patientWeight'> =
                'patientWeight';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'upperHardLimit'
              > = 'upperHardLimit';

              const OLD_VALUES = row.original.oldValues;

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getPatientWeightValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getPatientWeightValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'body_surface_area',
        header: t('reports.dl_changes_report.care_areas.body_surface_area'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'body_surface_area_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'bodySurfaceArea'> =
                'bodySurfaceArea';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'lowerHardLimit'
              > = 'lowerHardLimit';

              const OLD_VALUES = row.original.oldValues;
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getBodySurfaceAreaValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getBodySurfaceAreaValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'body_surface_area_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'bodySurfaceArea'> =
                'bodySurfaceArea';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getBodySurfaceAreaValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getBodySurfaceAreaValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'body_surface_area_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'bodySurfaceArea'> =
                'bodySurfaceArea';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'upperSoftLimit'
              > = 'upperSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getBodySurfaceAreaValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getBodySurfaceAreaValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'body_surface_area_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<CareAreaChangesReport, 'bodySurfaceArea'> =
                'bodySurfaceArea';
              const limit: keyof Pick<
                CareAreaChangesReport[typeof key],
                'upperHardLimit'
              > = 'upperHardLimit';

              const OLD_VALUES = row.original.oldValues;

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    {...(OLD_VALUES &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText: (() => {
                          const isValueEmpty = getBodySurfaceAreaValue(
                            //@ts-ignore
                            OLD_VALUES?.[key]?.[limit] ?? null,
                          );
                          return isValueEmpty === ''
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : isValueEmpty;
                        })(),
                      }))}
                  >
                    {getBodySurfaceAreaValue(
                      row.original?.[key]?.[limit] ?? null,
                    )}
                  </div>
                  <ReactTooltip
                    place="bottom"
                    className="toolTip"
                    id={`${row.original.id}-${key}-${limit}`}
                  />
                </>
              );
            },
            header: t('reports.dl_changes_report.care_areas.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'kvo_rate',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<CareAreaChangesReport, 'kvoRate'> = 'kvoRate';
          const OLD_VALUES = row.original.oldValues;
          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(OLD_VALUES &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText: OLD_VALUES[key] + ' mL / hr',
                  }))}
              >
                {row.original.kvoRate} mL / hr
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t('reports.dl_changes_report.care_areas.kvo_rate'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'value_reentry',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<
            CareAreaChangesReport,
            'weightBsaConfirmation'
          > = 'weightBsaConfirmation';
          const OLD_VALUES = row.original.oldValues;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                data-testid={`${key}-${row.original.id}`}
                {...(OLD_VALUES &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText: OLD_VALUES[key] ? 'On' : 'Off',
                  }))}
              >
                {row.original[key] ? 'On' : 'Off'}
              </div>{' '}
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t('reports.dl_changes_report.care_areas.value_reentry'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'audio_level_alarm',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<CareAreaChangesReport, 'audioLevelAlarm'> =
            'audioLevelAlarm';
          const OLD_VALUES = row.original.oldValues;
          return (
            <>
              <div
                data-testid={`${key}-${row.original.id}`}
                className={getViewChangesCellClass(row.original, { key })}
                {...(OLD_VALUES &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText: OLD_VALUES[key]
                      ? AUDIO_LEVEL_ALARM_VALUES_NUMBERS[
                          (OLD_VALUES[key] as any)
                            .value as keyof typeof AUDIO_LEVEL_ALARM_VALUES_NUMBERS
                        ] + ''
                      : '---',
                  }))}
              >
                {row.original[key]
                  ? AUDIO_LEVEL_ALARM_VALUES_NUMBERS[
                      row.original[key]
                        .value as keyof typeof AUDIO_LEVEL_ALARM_VALUES_NUMBERS
                    ]
                  : ''}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t('reports.dl_changes_report.care_areas.audio_level_alarm'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Define react table
   */
  const table: ReactTable<any> = useReactTable({
    data: careAreas,
    columns,
    // pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      //   pagination,
      //   sorting,
      //   columnFilters,
    },
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // manualPagination: true,
    // manualSorting: true,
    // manualFiltering: true,
    enableMultiSort: false,
  });

  return <Table classes="dl-changes-table" table={table} isFetching={false} />;
};

export default CareAreasChanges;
