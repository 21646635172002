export const DEPLOYMENT_ID_PARAMS_KEY = 'deployment_id';
export const DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY = 'update_type';
export const DEPLOYMENT_TAB_PARAMS_KEY = 'tab';

export const DEPLOYMENT_BASE_URL = '/deployment';

export const DEPLOYMENT_ALL_PREFIX = 'all';
export const DEPLOYMENT_PARTIAL_PREFIX = 'partial';
export const DEPLOYMENT_JSON_PREFIX = 'json';
export const DEPLOYMENT_UPDATE_PARTIAL_PREFIX = 'update-partial';
export const DEPLOYMENT_UPDATE_PREFIX = 'update';

export const FRONT_ROUTER = {
  /* ----------------------DEPLOYMENT---------------------------- */
  DEPLOYMENT_TABS: `${DEPLOYMENT_BASE_URL}/:${DEPLOYMENT_TAB_PARAMS_KEY}`,
  DEPLOYMENT_FULL_DEPLOYMENT_TAB:
    `${DEPLOYMENT_BASE_URL}/${DEPLOYMENT_ALL_PREFIX}` as const,

  DEPLOYMENT_PARTIAL_DEPLOYMENT_TAB:
    `${DEPLOYMENT_BASE_URL}/${DEPLOYMENT_PARTIAL_PREFIX}` as const,
  DEPLOYMENT_UPDATE_PARTIAL:
    `${DEPLOYMENT_BASE_URL}/${DEPLOYMENT_UPDATE_PARTIAL_PREFIX}/:${DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY}` as const,

  /* ---------------------ERROR-------------------------------------- */
  ERROR: '/error',
  /* ---------------------HELP--------------------------------------  */
  HELP: '/help',
} as const;
