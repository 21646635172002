import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'config/axios';
import { Advisory } from 'interfaces/advisory';
import { AdvisoryBasicModeReason } from 'interfaces/advisory-basic-mode-reason';
import { BasicModeAdvisoryReason } from 'interfaces/basic-mode-advisory-reason';
import { PaginationResponse } from 'interfaces/pagination-response';

export type AdvisoryResponse = PaginationResponse<Advisory>;

const AdvisoryService = {
  create: (data: Partial<Advisory>) => {
    return axiosInstance.post<Advisory>('/v1/advisories', data);
  },

  update: (id: string | number, data: Partial<Advisory>) => {
    return axiosInstance.patch<Advisory>(`/v1/advisories/${id}`, data);
  },

  find: (id: string | number, config?: AxiosRequestConfig) => {
    return axiosInstance.get<Advisory>(`/v1/advisories/${id}`, config);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<Advisory>(`/v1/advisories/${id}`);
  },

  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<AdvisoryResponse>('/v1/advisories', {
      params: queryParams,
    });
  },

  removeReason: (advisoryId: string | number, reasonId: string | number) => {
    return axiosInstance.delete<AdvisoryBasicModeReason>(
      `/v1/advisories/${advisoryId}/basic-mode-advisory-reasons/${reasonId}`,
    );
  },

  assignReason: (advisoryId: string | number, reasonId: string | number) => {
    return axiosInstance.post<AdvisoryBasicModeReason>(
      `/v1/advisories/${advisoryId}/basic-mode-advisory-reasons/${reasonId}`,
    );
  },

  getReasons: (advisoryId: string | number) => {
    return axiosInstance.get<BasicModeAdvisoryReason[]>(
      `/v1/advisories/${advisoryId}/basic-mode-advisory-reasons`,
    );
  },
};

export default AdvisoryService;
