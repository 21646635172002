import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/UI/Table/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getViewChangesCellClass } from 'helpers/deployment';
import { cloneDeep } from 'lodash';
import { FacilityChangesReport } from 'interfaces/facility';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';

type FacilitiesChangesProps = {
  facilities: any[];
};

function setTooltipForUpdatedValues(
  key: keyof FacilityChangesReport,
  row: FacilityChangesReport,
  replaceValueTooltipText?: string,
) {
  return row.oldValues && key in row.oldValues
    ? {
        'data-tooltip-id': `${row.id}-${key}`,
        'data-tooltip-content':
          replaceValueTooltipText ?? (row.oldValues[key] as string),
      }
    : {};
}

// Add proprties
const FacilitiesChanges: FC<FacilitiesChangesProps> = ({ facilities }) => {
  const { t } = useTranslation();

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<FacilityChangesReport>[]>(
    () => [
      {
        id: 'name',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<FacilityChangesReport, 'name'> = 'name';
          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(row.original.oldValues &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key]}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t('reports.dl_changes_report.facilities.name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'panel_lock_passcode',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<FacilityChangesReport, 'panelLockPasscode'> =
            'panelLockPasscode';
          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(row.original.oldValues &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key]}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t('reports.dl_changes_report.facilities.panel_lock_passcode'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'advanced_passcode',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<FacilityChangesReport, 'advancedPasscode'> =
            'advancedPasscode';
          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(row.original.oldValues &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key]}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t('reports.dl_changes_report.facilities.advanced_passcode'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },

      {
        id: 'play_voice_alarm',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<FacilityChangesReport, 'playAuditoryIcon'> =
            'playAuditoryIcon';
          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                data-testid={`${key}-${row.original.id}`}
                {...(row.original.oldValues &&
                  setTooltipForUpdatedValues(
                    key,
                    row.original,
                    row.original?.oldValues[key] ? 'On' : 'Off',
                  ))}
              >
                {row.original[key] ? 'On' : 'Off'}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: () =>
          t('reports.dl_changes_report.facilities.play_voice_alarm'),
        // <WrapTableHeader
        //   header={t('reports.dl_changes_report.facilities.play_voice_alarm')}
        //   centered={true}
        // />
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'basic_mode_advisory_message',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<FacilityChangesReport, 'basicModeMessage'> =
            'basicModeMessage';
          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(row.original.oldValues &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key]}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        header: t(
          'reports.dl_changes_report.facilities.basic_mode_advisory_message',
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'basic_mode_reasons',
        cell: ({ row }) => {
          type BasicModeReasons = {
            id: number;
            reason: string;
          };
          const basicReasons = cloneDeep(
            (row.original.basicModeReasons as BasicModeReasons[]) ?? [],
          );

          const key: keyof Pick<FacilityChangesReport, 'basicModeReasons'> =
            'basicModeReasons';

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(row.original.oldValues &&
                  setTooltipForUpdatedValues(
                    key,
                    row.original,
                    row.original.oldValues[key]
                      ? (row.original.oldValues[key] as BasicModeReasons[])
                          .map((reason: BasicModeReasons) => reason.reason)
                          .sort((a, b) => a.localeCompare(b))
                          .join(', ')
                      : undefined,
                  ))}
              >
                {basicReasons
                  .sort((a: BasicModeReasons, b: BasicModeReasons) =>
                    a.reason.localeCompare(b.reason),
                  )
                  .map((reason: BasicModeReasons) => (
                    <div key={reason.id}>{reason.reason}</div>
                  ))}
              </div>
              <ReactTooltip
                place="bottom"
                className="toolTip"
                id={`${row.original.id}-${key}`}
              />
            </>
          );
        },
        accessorFn: () => {},
        header: t('reports.dl_changes_report.facilities.basic_mode_reasons'),
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Define react table
   */
  const table: ReactTable<any> = useReactTable({
    data: facilities,
    columns,
    // pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      //   pagination,
      //   sorting,
      //   columnFilters,
    },
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // manualPagination: true,
    // manualSorting: true,
    // manualFiltering: true,
    enableMultiSort: false,
  });

  return <Table classes="dl-changes-table" table={table} isFetching={false} />;
};

export default FacilitiesChanges;
