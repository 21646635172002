import type { FC } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { CanDeployApiResponse } from 'services/DeploymentService';

export type CanNotDeployProps = Pick<CanDeployApiResponse, 'reasons'>;

const CanNotDeploy: FC<CanNotDeployProps> = ({ reasons }) => {
  return (
    <div className="p-4" data-testid="can-not-deploy-tab">
      <h4 className="pb-2">
        Drug Library cannot be deployed until all required fields are completed.
      </h4>
      <ul>
        {reasons.panelLockPasscode || reasons.advancedSettingsPasscode ? (
          <li data-testid="panel_lock_code">
            Panel Lock and Advanced Settings Passcodes are not set. Facility
            information must be completed before a drug library can be deployed.
          </li>
        ) : null}

        {reasons.incompleteCareArea ? (
          <li data-testid="care_area">
            Drug Library cannot be deployed when a Care Area has no drugs
            assigned.
          </li>
        ) : null}

        {reasons.incompleteDrugs && (
          <li data-testid="incomplete_drugs">
            <p>
              There is at least one Drug assigned to a Care Area where the set
              up is not complete. These incomplete Drugs are identified by the
              following symbol
              <IoIosWarning
                className="device-list-drug--concentration-warning"
                size={22}
              />
            </p>
            <p>
              All incomplete drugs must be resolved before a drug library can be
              deployed.
            </p>
          </li>
        )}
      </ul>
    </div>
  );
};

export default CanNotDeploy;
