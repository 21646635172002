import { Tooltip } from 'react-tooltip';

const ReactTooltip: React.FC<React.ComponentProps<typeof Tooltip>> = ({
  children,
  ...props
}) => {
  return (
    <Tooltip {...props} style={{ zIndex: 1001, fontSize: '13px' }}>
      {children}
    </Tooltip>
  );
};

export default ReactTooltip;
