import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { PaginationResponse } from 'interfaces/pagination-response';

export type DrugReportResponse = PaginationResponse<any>;

const ReportService = {
  getDrugReport: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<DrugReportResponse>('/v1/reports/drug-report', {
      params: queryParams,
    });
  },

  downloadDrugReport: (data: {
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
    columnVisibility: { [key: string]: boolean };
  }) => {
    return axiosInstance.post<any>('/v1/reports/drug-report/pdf', data, {
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },
};

export default ReportService;
