import {
  Controller,
  Control,
  UseFormGetValues,
  FieldErrors,
} from 'react-hook-form';
import {
  Concentration,
  ConcentrationFormData,
} from '../../../../interfaces/concentration';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DecimalInput from '../../../../components/UI/DecimalInput/DecimalInput';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';
import {
  calculateDoseRateHardLimits,
  wrongLimits,
} from '../../../../helpers/concentrations';
import {
  calculateVariablePrecision,
  validatePrecision,
} from '../../../../helpers/drugs';

type DoseRateFieldsProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  getValues: UseFormGetValues<ConcentrationFormData>;
  errors: FieldErrors<ConcentrationFormData>;
  watchDoseModeUnit:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchDoseModeTime:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchWeightBased: boolean;
  watchConcentrationLHL: string | number | null | undefined;
  watchConcentrationUHL: string | number | null | undefined;
  watchDoseRateLHL: string | number | null | undefined;
  watchDoseRateLSL: string | number | null | undefined;
  watchDoseRateUSL: string | number | null | undefined;
  watchDoseRateUHL: string | number | null | undefined;
  canManage: boolean;
};

const DoseRateFields: FC<DoseRateFieldsProps> = ({
  record,
  control,
  getValues,
  errors,
  watchDoseModeUnit,
  watchDoseModeTime,
  watchWeightBased,
  watchConcentrationLHL,
  watchConcentrationUHL,
  watchDoseRateLHL,
  watchDoseRateLSL,
  watchDoseRateUSL,
  watchDoseRateUHL,
  canManage,
}) => {
  const { t } = useTranslation();

  const precisionDoseRateLHL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseRateLHL),
    [watchDoseRateLHL],
  );
  const precisionDoseRateLSL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseRateLSL),
    [watchDoseRateLSL],
  );
  const precisionDoseRateUSL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseRateUSL),
    [watchDoseRateUSL],
  );
  const precisionDoseRateUHL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseRateUHL),
    [watchDoseRateUHL],
  );

  const doseRateLimits: { min: number; max: number } | null | undefined =
    calculateDoseRateHardLimits(
      record,
      watchWeightBased,
      record.is_complete ? record.dose_mode_unit : watchDoseModeUnit?.value,
      record.is_complete ? record.dose_mode_time : watchDoseModeTime?.value,
      watchConcentrationLHL,
      watchConcentrationUHL,
    );

  return (
    <div className="position-relative row">
      {!canManage && (
        <div className="device-concentration--disabled-item"></div>
      )}
      <div>
        <div
          className={`row ${
            !wrongLimits(doseRateLimits) ? 'pb-3' : ''
          } pt-1 flex-nowrap`}
        >
          <div className="device-concentration-form__label">
            {t('device.dose_rate')}
          </div>

          {/* Lower hard limit */}
          <div className="device-concentration__box-input">
            <Controller
              control={control}
              name="dose_rate_lower_hard_limit"
              rules={{
                required: true,
                min: doseRateLimits?.min,
                max: doseRateLimits?.max,
                validate: {
                  increasingOrder: (value) => {
                    if (!value || isNaN(Number(value))) return true;

                    const currentValue = Number(value);
                    let isValid = true;

                    const [LSL, USL, UHL] = getValues([
                      'dose_rate_lower_soft_limit',
                      'dose_rate_upper_soft_limit',
                      'dose_rate_upper_hard_limit',
                    ]);

                    isValid =
                      isValid && (LSL ? Number(LSL) > currentValue : true);
                    isValid =
                      isValid && (USL ? Number(USL) > currentValue : true);
                    isValid =
                      isValid && (UHL ? Number(UHL) > currentValue : true);

                    return isValid;
                  },
                  precision: (value) =>
                    validatePrecision(value, precisionDoseRateLHL),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={precisionDoseRateLHL}
                  rounding={'toAllowedNumberOfDecimals'}
                  className={
                    'device-concentration__input--hard-limit ' +
                    (errors?.dose_rate_lower_hard_limit ? 'has-error' : '')
                  }
                  ref={ref}
                  data-tooltip-id="dose_rate_lower_hard_limit"
                  data-testid="dose_rate_lower_hard_limit"
                />
              )}
            />

            {errors?.dose_rate_lower_hard_limit && (
              <ReactTooltip
                id="dose_rate_lower_hard_limit"
                place="bottom"
                delayHide={300}
                variant="error"
              >
                <>
                  {errors?.dose_rate_lower_hard_limit?.type === 'required' &&
                    t('errors.field__required')}
                  {errors?.dose_rate_lower_hard_limit?.type === 'min' &&
                    t('device.errors.not_lower_than', {
                      value: doseRateLimits?.min,
                    })}
                  {errors?.dose_rate_lower_hard_limit?.type === 'max' &&
                    t('device.errors.not_greater_than', {
                      value: doseRateLimits?.max,
                    })}
                  {errors?.dose_rate_lower_hard_limit?.type ===
                    'increasingOrder' &&
                    t('device.errors.order_LHL_LSL_USL_UHL')}
                  {errors?.dose_rate_lower_hard_limit?.type === 'precision' &&
                    t('device.errors.precision', {
                      value: precisionDoseRateLHL,
                    })}
                </>
              </ReactTooltip>
            )}
          </div>

          {/* Lower soft limit */}
          <div className="device-concentration__box-input">
            <Controller
              control={control}
              name="dose_rate_lower_soft_limit"
              rules={{
                min: doseRateLimits?.min,
                max: doseRateLimits?.max,
                validate: {
                  increasingOrder: (value) => {
                    if (!value || isNaN(Number(value))) return true;

                    const currentValue = Number(value);
                    let isValid = true;

                    const [LHL, USL, UHL] = getValues([
                      'dose_rate_lower_hard_limit',
                      'dose_rate_upper_soft_limit',
                      'dose_rate_upper_hard_limit',
                    ]);

                    isValid =
                      isValid && (LHL ? Number(LHL) < currentValue : true);
                    isValid =
                      isValid && (USL ? Number(USL) > currentValue : true);
                    isValid =
                      isValid && (UHL ? Number(UHL) > currentValue : true);

                    return isValid;
                  },
                  precision: (value) =>
                    validatePrecision(value, precisionDoseRateLSL),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={precisionDoseRateLSL}
                  rounding={'toAllowedNumberOfDecimals'}
                  className={
                    'device-concentration__input--soft-limit ' +
                    (errors?.dose_rate_lower_soft_limit ? 'has-error' : '')
                  }
                  ref={ref}
                  data-tooltip-id="dose_rate_lower_soft_limit"
                  data-testid="dose_rate_lower_soft_limit"
                  placeholder={''}
                />
              )}
            />

            {errors?.dose_rate_lower_soft_limit && (
              <ReactTooltip
                id="dose_rate_lower_soft_limit"
                place="bottom"
                delayHide={300}
                variant="error"
              >
                <>
                  {errors?.dose_rate_lower_soft_limit?.type === 'min' &&
                    t('device.errors.not_lower_than', {
                      value: doseRateLimits?.min,
                    })}
                  {errors?.dose_rate_lower_soft_limit?.type === 'max' &&
                    t('device.errors.not_greater_than', {
                      value: doseRateLimits?.max,
                    })}
                  {errors?.dose_rate_lower_soft_limit?.type ===
                    'increasingOrder' &&
                    t('device.errors.order_LHL_LSL_USL_UHL')}
                  {errors?.dose_rate_lower_soft_limit?.type === 'precision' &&
                    t('device.errors.precision', {
                      value: precisionDoseRateLSL,
                    })}
                </>
              </ReactTooltip>
            )}
          </div>

          {/* Upper soft limit */}
          <div className="device-concentration__box-input">
            <Controller
              control={control}
              name="dose_rate_upper_soft_limit"
              rules={{
                min: doseRateLimits?.min,
                max: doseRateLimits?.max,
                validate: {
                  increasingOrder: (value) => {
                    if (!value || isNaN(Number(value))) return true;

                    const currentValue = Number(value);
                    let isValid = true;

                    const [LHL, LSL, UHL] = getValues([
                      'dose_rate_lower_hard_limit',
                      'dose_rate_lower_soft_limit',
                      'dose_rate_upper_hard_limit',
                    ]);

                    isValid =
                      isValid && (LHL ? Number(LHL) < currentValue : true);
                    isValid =
                      isValid && (LSL ? Number(LSL) < currentValue : true);
                    isValid =
                      isValid && (UHL ? Number(UHL) > currentValue : true);

                    return isValid;
                  },
                  precision: (value) =>
                    validatePrecision(value, precisionDoseRateUSL),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={precisionDoseRateUSL}
                  rounding={'toAllowedNumberOfDecimals'}
                  className={
                    'device-concentration__input--soft-limit ' +
                    (errors?.dose_rate_upper_soft_limit ? 'has-error' : '')
                  }
                  ref={ref}
                  data-tooltip-id="dose_rate_upper_soft_limit"
                  data-testid="dose_rate_upper_soft_limit"
                  placeholder={''}
                />
              )}
            />

            {errors?.dose_rate_upper_soft_limit && (
              <ReactTooltip
                id="dose_rate_upper_soft_limit"
                place="bottom"
                delayHide={300}
                variant="error"
              >
                <>
                  {errors?.dose_rate_upper_soft_limit?.type === 'min' &&
                    t('device.errors.not_lower_than', {
                      value: doseRateLimits?.min,
                    })}
                  {errors?.dose_rate_upper_soft_limit?.type === 'max' &&
                    t('device.errors.not_greater_than', {
                      value: doseRateLimits?.max,
                    })}
                  {errors?.dose_rate_upper_soft_limit?.type ===
                    'increasingOrder' &&
                    t('device.errors.order_LHL_LSL_USL_UHL')}
                  {errors?.dose_rate_upper_soft_limit?.type === 'precision' &&
                    t('device.errors.precision', {
                      value: precisionDoseRateUSL,
                    })}
                </>
              </ReactTooltip>
            )}
          </div>

          {/* Upper hard limit */}
          <div className="device-concentration__box-input">
            <Controller
              control={control}
              name="dose_rate_upper_hard_limit"
              rules={{
                required: true,
                min: doseRateLimits?.min,
                max: doseRateLimits?.max,
                validate: {
                  increasingOrder: (value) => {
                    if (!value || isNaN(Number(value))) return true;

                    const currentValue = Number(value);
                    let isValid = true;

                    const [LHL, LSL, USL] = getValues([
                      'dose_rate_lower_hard_limit',
                      'dose_rate_lower_soft_limit',
                      'dose_rate_upper_soft_limit',
                    ]);

                    isValid =
                      isValid && (LHL ? Number(LHL) < currentValue : true);
                    isValid =
                      isValid && (LSL ? Number(LSL) < currentValue : true);
                    isValid =
                      isValid && (USL ? Number(USL) < currentValue : true);

                    return isValid;
                  },
                  precision: (value) =>
                    validatePrecision(value, precisionDoseRateUHL),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={precisionDoseRateUHL}
                  rounding={'toAllowedNumberOfDecimals'}
                  className={
                    'device-concentration__input--hard-limit ' +
                    (errors?.dose_rate_upper_hard_limit ? 'has-error' : '')
                  }
                  ref={ref}
                  data-tooltip-id="dose_rate_upper_hard_limit"
                  data-testid="dose_rate_upper_hard_limit"
                />
              )}
            />

            {errors?.dose_rate_upper_hard_limit && (
              <ReactTooltip
                id="dose_rate_upper_hard_limit"
                place="bottom"
                delayHide={300}
                variant="error"
              >
                <>
                  {errors?.dose_rate_upper_hard_limit?.type === 'required' &&
                    t('errors.field__required')}
                  {errors?.dose_rate_upper_hard_limit?.type === 'min' &&
                    t('device.errors.not_lower_than', {
                      value: doseRateLimits?.min,
                    })}
                  {errors?.dose_rate_upper_hard_limit?.type === 'max' &&
                    t('device.errors.not_greater_than', {
                      value: doseRateLimits?.max,
                    })}
                  {errors?.dose_rate_upper_hard_limit?.type ===
                    'increasingOrder' &&
                    t('device.errors.order_LHL_LSL_USL_UHL')}
                  {errors?.dose_rate_upper_hard_limit?.type === 'precision' &&
                    t('device.errors.precision', {
                      value: precisionDoseRateUHL,
                    })}
                </>
              </ReactTooltip>
            )}
          </div>

          {/* Units */}
          <div className="device-concentration-units">
            {record?.is_complete
              ? record.dose_mode_unit
              : watchDoseModeUnit?.label}{' '}
            {watchWeightBased ? '/ kg' : ''}{' '}
            {record?.is_complete
              ? '/ ' + record.dose_mode_time
              : '/ ' + watchDoseModeTime?.label}
          </div>
        </div>
        {!!wrongLimits(doseRateLimits) && (
          <div className="row pb-3">
            <div className="device-concentration-form__label"></div>
            <div className="device-concentration-form__column">
              <div className="invalid-feedback">
                {t('device.errors.dose_rate_limits__out_of_range')}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DoseRateFields;
