import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { AmplifyAuthenticatorEnum } from 'enums';
import '@aws-amplify/ui-react/styles.css';
import logo from 'assets/images/logo.svg';
import './Login.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import cookies from 'config/cookie';
import { JWT_ACCESS_TOKEN } from 'constants/jwt-tokens';
import UserService from 'services/UserService';
import { authActions } from 'store/slices/auth';
import { toast } from 'react-hot-toast';
import { translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';
import { useQueryClient } from '@tanstack/react-query';
import { signOutUser } from 'helpers/signOut';
I18n.putVocabularies(translations);

const passwordCannotBeEmpty = 'Password cannot be empty';
const usernameCannotBeEmpty = 'Username cannot be empty';

I18n.putVocabulariesForLanguage('en', {
  'username is required to signIn': usernameCannotBeEmpty,
  'password is required to signIn': passwordCannotBeEmpty,
  'username is required to resetPassword': usernameCannotBeEmpty,
});

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // @ts-ignore
  const from = location?.state?.from?.pathname || '/';

  useEffect(() => {
    const updateUser = async () => {
      // Get current authenticated Cognito user
      const authSession = await fetchAuthSession();

      if (authSession.tokens) {
        cookies.set(JWT_ACCESS_TOKEN, authSession.tokens?.idToken?.toString(), {
          path: '/',
        });

        // Get the user and update the store
        const user = await UserService.getMe();
        dispatch(
          authActions.updateUser({
            user: user.data,
          }),
        );
      }
    };

    if (route === AmplifyAuthenticatorEnum.AUTHENTICATED) {
      updateUser()
        .then(() => {
          navigate(from, { replace: false });
        })
        .catch((e) => {
          toast.error(e.response?.data?.message || e.message);
          signOutUser(dispatch, queryClient);
        });
    }
  }, [route, navigate, from]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="login-page d-flex flex-column justify-content-center align-items-center">
      <div className="login-page__logo-wrapper">
        <img src={logo} alt="Logo" />
      </div>

      <Authenticator
        hideSignUp
        formFields={{
          signIn: {
            password: {
              labelHidden: true,
              autocomplete: 'new-password',
              //change default placeholder
              placeholder: 'Password',
            },
            username: {
              labelHidden: true,
              //change default placeholder
              placeholder: 'Username (email)',
            },
          },

          forgotPassword: {
            username: {
              labelHidden: true,
              //change default placeholder
              placeholder: 'Enter your username (email)',
            },
          },

          setupTotp: {
            QR: {
              totpIssuer:
                process.env.REACT_APP_ENV !== 'production'
                  ? `Drug Library (${process.env.REACT_APP_ENV})`
                  : '',
            },
          },
        }}
      ></Authenticator>
    </div>
  );
};

export default Login;
