import React, { FC, useEffect, useState } from 'react';
// import { DevTool } from '@hookform/devtools';
import './ConcentrationForm.scss';
import { DrugConcentration } from 'interfaces/drug-concentration';
import { useForm } from 'react-hook-form';
import { usePrompt } from 'hooks/useBlocker';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import {
  InfusionTypesEnum,
  DrugUnitsEnum,
  ConcentrationTypesEnum,
  DoseModeTypeEnum,
} from 'constants/drugs';
import {
  formatConcentrationPayload,
  formatConcentrationResult,
} from 'helpers/drugs';
import DrugService from 'services/DrugService';
import { ReactQueryKeys } from 'constants/react-query-keys';
import TherapyService from 'services/TherapyService';
import { useQueryClient } from '@tanstack/react-query';
import { Therapy } from 'interfaces/therapy';
import Button from 'components/UI/Button/Button';
import ConcentrationFields from './ConcentrationFormComponents/ConcentrationFields';
import DoseRateFields from './ConcentrationFormComponents/DoseRateFields';
import ConfirmationButton from './ConcentrationFormComponents/ConfirmationButton';
import UnitsHeader from './ConcentrationFormComponents/UnitsHeader';
import InfuseAs from './ConcentrationFormComponents/InfuseAs';
import SwalAlert from 'components/UI/SwalAlert/SwalAlert';
import LoadingDoseLimits from './ConcentrationFormComponents/LoadingDoseLimits';
import BolusLimits from './ConcentrationFormComponents/BolusLimits';
import AllowRapidComplete from './ConcentrationFormComponents/AllowRapidComplete';
import DrugAmountLimits from './ConcentrationFormComponents/DrugAmountLimits';
import DoseAmountLimits from './ConcentrationFormComponents/DoseAmountLimits';
import DurationLimits from './ConcentrationFormComponents/DurationLimits';
import {
  calculateDoseRateHardLimits,
  wrongLimits,
} from 'helpers/concentrations';
import DoseMode from './ConcentrationFormComponents/DoseMode';
import TherapySelector from './ConcentrationFormComponents/TherapySelector';
import InfusionType from './ConcentrationFormComponents/InfusionType';
import { useAppDispatch } from 'hooks/useReduxHooks';
import { deviceActions } from 'store/slices/device';
import { formatMyDateRemix } from 'helpers/date-helpers';
import DeliveryPressure from './ConcentrationFormComponents/DeliveryPressure';

type ConcentrationFormProps = {
  concentration: DrugConcentration;
};

const initialFormData: ConcentrationFormData = {
  therapy: null,
  infusion_type: null,
  dose_mode_unit: null,
  dose_mode_type: null,
  dose_mode_time: null,
  weight_based: false,
  infuse_primary: false,
  infuse_secondary: false,
  allow_secondary: null,
  infusion_complete: null,
  concentration_lower_hard_limit: '',
  concentration_lower_soft_limit: '',
  concentration_upper_soft_limit: '',
  concentration_upper_hard_limit: '',
  dose_rate_lower_hard_limit: '',
  dose_rate_lower_soft_limit: '',
  dose_rate_upper_soft_limit: '',
  dose_rate_upper_hard_limit: '',
  allow_load: false,
  load_amount_lower_hard_limit: '',
  load_amount_lower_soft_limit: '',
  load_amount_upper_soft_limit: '',
  load_amount_upper_hard_limit: '',
  load_amount_units: null,
  load_time_lower_hard_limit: '',
  load_time_lower_soft_limit: '',
  load_time_upper_soft_limit: '',
  load_time_upper_hard_limit: '',
  load_time_units: null,
  allow_bolus: false,
  bolus_amount_lower_hard_limit: '',
  bolus_amount_lower_soft_limit: '',
  bolus_amount_upper_hard_limit: '',
  bolus_amount_upper_soft_limit: '',
  bolus_time_lower_hard_limit: '',
  bolus_time_lower_soft_limit: '',
  bolus_time_upper_hard_limit: '',
  bolus_time_upper_soft_limit: '',
  bolus_amount_units: null,
  bolus_time_units: null,
  allow_rapid_complete: 'OFF',
  infuse_until_empty: null,
  delivery_pressure: 'ON',
  duration_lower_hard_limit: '',
  duration_lower_soft_limit: '',
  duration_upper_soft_limit: '',
  duration_upper_hard_limit: '',
  drug_amount_lower_hard_limit: '',
  drug_amount_lower_soft_limit: '',
  drug_amount_upper_soft_limit: '',
  drug_amount_upper_hard_limit: '',
  dose_lower_hard_limit: '',
  dose_lower_soft_limit: '',
  dose_upper_soft_limit: '',
  dose_upper_hard_limit: '',
  allow_concentration_limits: false,
  max_rate_increase: '',
} as const;

const ConcentrationForm: FC<ConcentrationFormProps> = ({ concentration }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isConfirmed, setConfirmed] = useState(false);
  const [prevInfusionType, setPrevInfusionType] = useState<any>(null);
  const [record, setRecord] = useState<Concentration | null>(null);
  const [formLoading, setFormLoading] = useState(false);
  const [therapies, setTherapies] = useState<Therapy[]>([]);
  const [initialData, setInitialData] = useState({});
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    register,
    getValues,
    getFieldState,
    formState: { isValid, isDirty, errors },
    trigger,
  } = useForm<ConcentrationFormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });

  const IS_EDITING_STILL = isDirty && !formSubmitting;
  usePrompt(t('messages.unsaved_changes'), IS_EDITING_STILL);

  useEffect(() => {
    dispatch(
      deviceActions.updateIsEditingStill({
        isEditingStill: IS_EDITING_STILL,
        drugUnsavedChangesMessage: IS_EDITING_STILL
          ? t('drugs.messages.modal__unsaved__changes', {
              name: concentration.drug_name,
            })
          : '',
      }),
    );

    return () => {
      dispatch(
        deviceActions.updateIsEditingStill({
          isEditingStill: false,
          drugUnsavedChangesMessage: '',
        }),
      );
    };
  }, [IS_EDITING_STILL, dispatch, concentration.drug_name, t]);

  const watchTherapy = watch('therapy');
  const watchInfusionType = watch('infusion_type');
  const watchDoseModeUnit = watch('dose_mode_unit');
  const watchDoseModeType = watch('dose_mode_type');
  const watchDoseModeTime = watch('dose_mode_time');
  const watchWeightBased = watch('weight_based');
  const watchInfusePrimary = watch('infuse_primary');
  const watchInfuseSecondary = watch('infuse_secondary');
  const watchAllowSecondary = watch('allow_secondary');
  const watchInfuseUntilEmpty = watch('infuse_until_empty');

  const watchAllowConcentrationLimits = watch('allow_concentration_limits');
  const watchConcentrationLHL = watch('concentration_lower_hard_limit');
  const watchConcentrationLSL = watch('concentration_lower_soft_limit');
  const watchConcentrationUSL = watch('concentration_upper_soft_limit');
  const watchConcentrationUHL = watch('concentration_upper_hard_limit');

  const watchDoseRateLHL = watch('dose_rate_lower_hard_limit');
  const watchDoseRateLSL = watch('dose_rate_lower_soft_limit');
  const watchDoseRateUSL = watch('dose_rate_upper_soft_limit');
  const watchDoseRateUHL = watch('dose_rate_upper_hard_limit');

  const watchLoadAmountLHL = watch('load_amount_lower_hard_limit');
  const watchLoadAmountLSL = watch('load_amount_lower_soft_limit');
  const watchLoadAmountUSL = watch('load_amount_upper_soft_limit');
  const watchLoadAmountUHL = watch('load_amount_upper_hard_limit');
  const watchLoadTimeLHL = watch('load_time_lower_hard_limit');
  const watchLoadTimeLSL = watch('load_time_lower_soft_limit');
  const watchLoadTimeUSL = watch('load_time_upper_soft_limit');
  const watchLoadTimeUHL = watch('load_time_upper_hard_limit');
  const watchAllowLoad = watch('allow_load');
  const watchLoadTimeUnits = watch('load_time_units');
  const watchLoadAmountUnits = watch('load_amount_units');

  const watchBolusAmountLHL = watch('bolus_amount_lower_hard_limit');
  const watchBolusAmountLSL = watch('bolus_amount_lower_soft_limit');
  const watchBolusAmountUSL = watch('bolus_amount_upper_soft_limit');
  const watchBolusAmountUHL = watch('bolus_amount_upper_hard_limit');
  const watchBolusTimeLHL = watch('bolus_time_lower_hard_limit');
  const watchBolusTimeLSL = watch('bolus_time_lower_soft_limit');
  const watchBolusTimeUSL = watch('bolus_time_upper_soft_limit');
  const watchBolusTimeUHL = watch('bolus_time_upper_hard_limit');
  const watchAllowBolus = watch('allow_bolus');
  const watchBolusTimeUnits = watch('bolus_time_units');
  const watchBolusAmountUnits = watch('bolus_amount_units');

  const watchDurationLHL = watch('duration_lower_hard_limit');
  const watchDurationLSL = watch('duration_lower_soft_limit');
  const watchDurationUSL = watch('duration_upper_soft_limit');
  const watchDurationUHL = watch('duration_upper_hard_limit');

  const watchDrugAmountLHL = watch('drug_amount_lower_hard_limit');
  const watchDrugAmountLSL = watch('drug_amount_lower_soft_limit');
  const watchDrugAmountUSL = watch('drug_amount_upper_soft_limit');
  const watchDrugAmountUHL = watch('drug_amount_upper_hard_limit');

  const watchDoseLHL = watch('dose_lower_hard_limit');
  const watchDoseLSL = watch('dose_lower_soft_limit');
  const watchDoseUSL = watch('dose_upper_soft_limit');
  const watchDoseUHL = watch('dose_upper_hard_limit');

  const updateFormData = (
    concentration: Concentration,
    therapies: Therapy[],
  ) => {
    setRecord(concentration);

    const formattedInitialData = formatConcentrationResult(
      initialFormData,
      concentration,
      therapies,
    );

    reset(formattedInitialData);
    setInitialData(formattedInitialData);

    if (!concentration.is_complete) {
      setValue('infusion_type', null);
      setValue('dose_mode_type', null);
      setValue('dose_mode_unit', null);
      setValue('dose_mode_type', null);
      // setValue('delivery_pressure', 'ON');
      // setValue('allow_rapid_complete', 'OFF');
    }
    setConfirmed(concentration.is_complete);
  };

  useEffect(() => {
    setFormLoading(true);
    setConfirmed(false);
    setRecord(null);
    TherapyService.get({
      pageIndex: 0,
      pageSize: 1000,
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    }).then(({ data }) => {
      const therapies = data.records;
      setTherapies(therapies);

      DrugService.getConcentration(concentration.id)
        .then(({ data: concentration }) => {
          updateFormData(concentration, therapies);
          setFormLoading(false);
        })
        .catch((_) => {
          setFormLoading(false);
          toast.error(t('device.messages.concentration_can_not_be_loaded'));
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concentration]);

  useEffect(() => {
    if (prevInfusionType !== watchInfusionType?.value && !!record) {
      // INFUSION_TYPES_VALUES
      if (watchInfusionType?.value === InfusionTypesEnum.INTERMITTENT) {
        if (record?.type === ConcentrationTypesEnum.MILLILITER_BASED) {
          setValue('dose_mode_unit', {
            label: DrugUnitsEnum.MILLILITER,
            value: DrugUnitsEnum.MILLILITER,
          });
        } else {
          setValue('dose_mode_unit', {
            label: String(record?.drug_unit),
            value: String(record?.drug_unit),
          });
        }
      } else {
        setValue('dose_mode_unit', null);
        setValue('dose_mode_type', null);
      }
      setValue('dose_mode_time', null);

      setPrevInfusionType(watchInfusionType?.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchInfusionType, record]);

  // Reset Dose Mode Time when Dose Mode Unit is changed
  useEffect(() => {
    setValue('dose_mode_time', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDoseModeUnit]);

  // Reset Dose Mode Unit and Time when weight-based is changed
  useEffect(() => {
    setValue('dose_mode_unit', null);
    setValue('dose_mode_time', null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchWeightBased]);

  // Validate Amount Lower Limits
  useEffect(() => {
    trigger('load_amount_lower_hard_limit');
    trigger('load_amount_lower_soft_limit');
  }, [watchLoadAmountLSL, watchLoadAmountLHL]);

  // Validate Amount Upper Limits
  useEffect(() => {
    trigger('load_amount_upper_hard_limit');
    trigger('load_amount_upper_soft_limit');
  }, [watchLoadAmountUHL, watchLoadAmountUSL]);

  // Validate Drug Amount Lower Limits
  useEffect(() => {
    trigger('drug_amount_lower_soft_limit');
    trigger('drug_amount_lower_hard_limit');
  }, [watchDrugAmountLSL, watchDrugAmountLHL]);

  // Validate Drug Amount Upper Limits
  useEffect(() => {
    trigger('drug_amount_upper_hard_limit');
    trigger('drug_amount_upper_soft_limit');
  }, [watchDrugAmountUHL, watchDrugAmountUSL]);

  // Validate Dose Amount Lower Limits
  useEffect(() => {
    trigger('dose_lower_hard_limit');
    trigger('dose_lower_soft_limit');
  }, [watchDoseLSL, watchDoseLHL]);

  // Validate Dose Amount Upper Limits
  useEffect(() => {
    trigger('dose_upper_hard_limit');
    trigger('dose_upper_soft_limit');
  }, [watchDoseUHL, watchDoseUSL]);

  // Validate Time Lower Limits
  useEffect(() => {
    trigger('load_time_lower_hard_limit');
    trigger('load_time_lower_soft_limit');
  }, [watchLoadTimeLHL, watchLoadTimeLSL]);

  // Validate Time Upper Limits
  useEffect(() => {
    trigger('load_time_upper_hard_limit');
    trigger('load_time_upper_soft_limit');
  }, [watchLoadTimeUHL, watchLoadTimeUSL]);

  // Validate Bolus Lower Limits
  useEffect(() => {
    trigger('bolus_amount_lower_hard_limit');
    trigger('bolus_amount_lower_soft_limit');
  }, [watchBolusAmountLHL, watchBolusAmountLSL]);

  // Validate Bolus Upper Limits
  useEffect(() => {
    trigger('bolus_amount_upper_hard_limit');
    trigger('bolus_amount_upper_soft_limit');
  }, [watchBolusAmountUHL, watchBolusAmountUSL]);

  // Validate Bolus Time Lower Limits
  useEffect(() => {
    trigger('bolus_time_lower_hard_limit');
    trigger('bolus_time_lower_soft_limit');
  }, [watchBolusTimeLHL, watchBolusTimeLSL]);

  // Validate Bolus Time Upper Limits
  useEffect(() => {
    trigger('bolus_time_upper_hard_limit');
    trigger('bolus_time_upper_soft_limit');
  }, [watchBolusTimeUHL, watchBolusTimeUSL]);

  // Validate Duration Lower Limits
  useEffect(() => {
    trigger('duration_lower_hard_limit');
    trigger('duration_lower_soft_limit');
  }, [watchDurationLHL, watchDurationLSL]);

  // Validate Duration Upper Limits
  useEffect(() => {
    trigger('duration_upper_soft_limit');
    trigger('duration_upper_hard_limit');
  }, [watchDurationUSL, watchDurationUHL]);

  // Reset load valuds if allow load checkbox is unchecked
  useEffect(() => {
    if (!watchAllowLoad) {
      if (!formLoading) {
        setValue('load_amount_lower_hard_limit', '');
        setValue('load_amount_lower_soft_limit', '');
        setValue('load_amount_upper_soft_limit', '');
        setValue('load_amount_upper_hard_limit', '');
        setValue('load_time_lower_hard_limit', '');
        setValue('load_time_lower_soft_limit', '');
        setValue('load_time_upper_soft_limit', '');
        setValue('load_time_upper_hard_limit', '');
        setValue('load_amount_units', '' as any);
        setValue('load_time_units', '' as any);
      }
    } else {
      if (watchBolusAmountUnits) {
        setValue('load_amount_units', watchBolusAmountUnits);
      }

      if (watchBolusTimeUnits) {
        setValue('load_time_units', watchBolusTimeUnits);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllowLoad, formLoading]);

  // Reset bolus valuds if allow load checkbox is unchecked
  useEffect(() => {
    if (!watchAllowBolus) {
      if (!formLoading) {
        setValue('bolus_amount_lower_hard_limit', '');
        setValue('bolus_amount_lower_soft_limit', '');
        setValue('bolus_amount_upper_soft_limit', '');
        setValue('bolus_amount_upper_hard_limit', '');
        setValue('bolus_time_lower_hard_limit', '');
        setValue('bolus_time_lower_soft_limit', '');
        setValue('bolus_time_upper_soft_limit', '');
        setValue('bolus_time_upper_hard_limit', '');
        setValue('bolus_time_units', '' as any);
        setValue('bolus_amount_units', '' as any);
      }
    } else {
      if (watchLoadAmountUnits) {
        setValue('bolus_amount_units', watchLoadAmountUnits);
      }

      if (watchLoadTimeUnits) {
        setValue('bolus_time_units', watchLoadTimeUnits);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllowBolus, formLoading]);

  //reset WhenInfusionCompletes back to null when InfuseUntilEmpty changes value
  useEffect(() => {
    //if record is basic, we do nothing
    if (record?.is_basic) return;

    if (watchInfuseUntilEmpty === 'ON') {
      setValue('infusion_complete', null, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
  }, [watchInfuseUntilEmpty, setValue]);

  /**
   * Set form confirmed
   *
   * @param e Event
   */
  const handleConfirmClick = (e: React.MouseEvent) => {
    e.preventDefault();

    // For these concentration types we don't have Concentration limits on concentration details level
    // We show the error immediatelly for Dose Rate Limits
    // For Infusion Type = Continuous
    if (
      (record?.type === ConcentrationTypesEnum.FULL ||
        record?.type === ConcentrationTypesEnum.MILLILITER_BASED) &&
      (watchInfusionType?.value === InfusionTypesEnum.CONTINUOUS ||
        watchInfusionType?.value === InfusionTypesEnum.MULTI_STEP)
    ) {
      const doseRateLimits = calculateDoseRateHardLimits(
        record,
        watchWeightBased,
        record.is_complete ? record.dose_mode_unit : watchDoseModeUnit?.value,
        record.is_complete ? record.dose_mode_time : watchDoseModeTime?.value,
        watchConcentrationLHL,
        watchConcentrationUHL,
      );

      if (wrongLimits(doseRateLimits)) {
        SwalAlert.fire({
          icon: 'error',
          title: 'Wrong dose mode',
          confirmButtonColor: '#0895DF',
          text: t('device.errors.dose_rate_limits__out_of_range'),
        });
        return;
      }
    }

    setConfirmed(true);
    setValue('infuse_primary', false, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue('infuse_secondary', false, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  /**
   * Submit form and create a concetration
   *
   * @param formData Concentration data
   */
  const handleSubmitData = async (formData: ConcentrationFormData) => {
    setFormSubmitting(true);

    const payload = formatConcentrationPayload(record!, formData);

    try {
      const { data: concentration } = await DrugService.updateConcentration(
        Number(record?.id),
        payload,
      );
      updateFormData(concentration, therapies);
      queryClient.invalidateQueries([ReactQueryKeys.DRUGS]);
      toast.success(t('concentrations.messages.update__success'));
    } catch (e: any) {
      let message = t('concentrations.messages.update__error');
      if (
        e.response.data?.statusCode === 400 ||
        e.response.data?.statusCode === 502
      ) {
        message = parseErrorMessage(e.response.data);

        if (e.response.data?.error === 'DUPLICATE_THERAPY') {
          setValue('therapy', null);
        }
      }
      toast.error(message);
    }

    setFormSubmitting(false);
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    if (record?.is_complete) {
      reset({ ...initialData });
    } else {
      setConfirmed(false);
      reset({ ...initialFormData });
    }
  };

  return (
    <div
      className="pt-3 device-concentration-form"
      data-testid="concentration-form-component"
    >
      <div className="d-flex pb-3 align-items-center">
        <div className="device-concentration-form__drug--name flex-fill">
          {concentration.drug_name}
        </div>
        {!!record && !concentration.is_basic && (
          <div className="device-concentration-form__drug--last-modified">
            {t('device.concentration_last_modified')}:{' '}
            {formatMyDateRemix(record?.updated_at)}
          </div>
        )}
      </div>
      <div className="d-flex pb-3">
        <div className="device-concentration-form__concentration--name">
          {concentration.name}
        </div>
      </div>
      <div className="customRow">
        <div className="col-12">
          <form
            style={{ position: 'relative' }}
            autoComplete="off"
            onSubmit={handleSubmit((data) => handleSubmitData(data))}
            className={formSubmitting ? 'opacity-50' : ''}
            data-testid="form"
          >
            {!formLoading ? (
              <div>
                {/* THERAPY */}
                <TherapySelector
                  control={control}
                  isConfirmed={isConfirmed}
                  record={record}
                  therapies={therapies}
                  watchTherapy={watchTherapy}
                  canManage={!concentration.is_basic}
                />

                {/* INFUSION TYPE */}
                {record ? (
                  <InfusionType
                    record={record}
                    control={control}
                    isConfirmed={isConfirmed}
                    watchInfusionType={watchInfusionType}
                    canManage={!concentration.is_basic}
                  />
                ) : null}

                {/* DOSE MODE */}
                {watchInfusionType ? (
                  <DoseMode
                    record={record}
                    control={control}
                    isConfirmed={isConfirmed}
                    watchDoseModeTime={watchDoseModeTime}
                    watchDoseModeType={watchDoseModeType}
                    watchDoseModeUnit={watchDoseModeUnit}
                    watchInfusionType={watchInfusionType}
                    watchWeightBased={watchWeightBased}
                    canManage={!concentration.is_basic}
                  />
                ) : null}

                {/* INFUSE AS */}
                {!!isConfirmed && (
                  <InfuseAs
                    control={control}
                    register={register}
                    watchAllowSecondary={watchAllowSecondary}
                    watchInfusePrimary={watchInfusePrimary}
                    watchInfuseSecondary={watchInfuseSecondary}
                    watchInfusionType={watchInfusionType?.value}
                    watchInfuseUntilEmpty={watchInfuseUntilEmpty}
                    record={record}
                    canManage={!concentration.is_basic}
                    errors={errors}
                    setValue={setValue}
                  />
                )}

                {/* DELIVERY PRESSURE */}
                {!!isConfirmed && (
                  <DeliveryPressure
                    canManage={!concentration.is_basic}
                    register={register}
                  />
                )}

                {/* RAPID COMPLETE */}
                {!!record &&
                !!isConfirmed &&
                (watchInfusionType?.value === InfusionTypesEnum.INTERMITTENT ||
                  concentration.is_basic) ? (
                  <AllowRapidComplete register={register} canManage={true} />
                ) : null}

                {/* UNITS TITLES */}
                {!!isConfirmed && (
                  <UnitsHeader canManage={!concentration.is_basic} />
                )}

                {/* CONCENTRATION - Continuous infusion type - Units only !NOT INTERMITTENT*/}
                {!!record &&
                  !!isConfirmed &&
                  !!watchInfusionType &&
                  watchInfusionType?.value !== InfusionTypesEnum.INTERMITTENT &&
                  record?.type === ConcentrationTypesEnum.UNITS_ONLY && (
                    <ConcentrationFields
                      record={record}
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                      watchConcentrationLHL={watchConcentrationLHL}
                      watchConcentrationLSL={watchConcentrationLSL}
                      watchConcentrationUHL={watchConcentrationUHL}
                      watchConcentrationUSL={watchConcentrationUSL}
                      watchAllowConcentrationLimits={
                        watchAllowConcentrationLimits
                      }
                      infusionType={watchInfusionType?.value}
                      canManage={!concentration.is_basic}
                    />
                  )}

                {/* DURATION LIMITS */}
                {!!record &&
                !!isConfirmed &&
                watchInfusionType?.value === InfusionTypesEnum.INTERMITTENT ? (
                  <DurationLimits
                    record={record}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    getFieldState={getFieldState}
                    watchDurationLHL={watchDurationLHL}
                    watchDurationLSL={watchDurationLSL}
                    watchDurationUSL={watchDurationUSL}
                    watchDurationUHL={watchDurationUHL}
                    watchDoseModeType={watchDoseModeType}
                    canManage={!concentration.is_basic}
                  />
                ) : null}

                {/* DOSE LIMITS */}
                {!!record &&
                !!isConfirmed &&
                record?.type === ConcentrationTypesEnum.UNITS_ONLY &&
                (watchDoseModeType?.value === DoseModeTypeEnum.WEIGHT_BASED ||
                  watchDoseModeType?.value === DoseModeTypeEnum.BSA_BASED) ? (
                  <DoseAmountLimits
                    record={record}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    watchDoseLHL={watchDoseLHL}
                    watchDoseLSL={watchDoseLSL}
                    watchDoseUSL={watchDoseUSL}
                    watchDoseUHL={watchDoseUHL}
                    watchDoseModeType={watchDoseModeType}
                    canManage={!concentration.is_basic}
                  />
                ) : null}

                {/* DOSE RATE */}
                {!!record &&
                  !!isConfirmed &&
                  (watchInfusionType?.value === InfusionTypesEnum.CONTINUOUS ||
                    watchInfusionType?.value ===
                      InfusionTypesEnum.MULTI_STEP) && (
                    <DoseRateFields
                      record={record}
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      watchDoseModeUnit={watchDoseModeUnit}
                      watchDoseModeTime={watchDoseModeTime}
                      watchWeightBased={watchWeightBased}
                      watchConcentrationLHL={watchConcentrationLHL}
                      watchConcentrationUHL={watchConcentrationUHL}
                      watchDoseRateLHL={watchDoseRateLHL}
                      watchDoseRateLSL={watchDoseRateLSL}
                      watchDoseRateUSL={watchDoseRateUSL}
                      watchDoseRateUHL={watchDoseRateUHL}
                      canManage={!concentration.is_basic}
                    />
                  )}

                {/* ALLOW LOAD */}
                {!!record &&
                !!isConfirmed &&
                watchInfusionType?.value === InfusionTypesEnum.CONTINUOUS ? (
                  <LoadingDoseLimits
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    record={record}
                    setValue={setValue}
                    watchDoseModeUnit={watchDoseModeUnit}
                    watchDoseModeTime={watchDoseModeTime}
                    watchAllowLoad={watchAllowLoad}
                    watchLoadAmountLHL={watchLoadAmountLHL}
                    watchLoadAmountLSL={watchLoadAmountLSL}
                    watchLoadAmountUHL={watchLoadAmountUHL}
                    watchLoadAmountUSL={watchLoadAmountUSL}
                    watchLoadAmountUnits={watchLoadAmountUnits}
                    watchLoadTimeLHL={watchLoadTimeLHL}
                    watchLoadTimeLSL={watchLoadTimeLSL}
                    watchLoadTimeUHL={watchLoadTimeUHL}
                    watchLoadTimeUSL={watchLoadTimeUSL}
                    watchLoadTimeUnits={watchLoadTimeUnits}
                    watchWeightBased={watchWeightBased}
                    watchAllowBolus={watchAllowBolus}
                    watchBolusAmountUnits={watchBolusAmountUnits}
                    watchBolusTimeUnits={watchBolusTimeUnits}
                    watchConcentrationLHL={watchConcentrationLHL}
                    watchConcentrationUHL={watchConcentrationUHL}
                    canManage={!concentration.is_basic}
                  />
                ) : null}

                {/* ALLOW BOLUS */}
                {!!record &&
                !!isConfirmed &&
                watchInfusionType?.value === InfusionTypesEnum.CONTINUOUS ? (
                  <BolusLimits
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    record={record}
                    setValue={setValue}
                    watchDoseModeUnit={watchDoseModeUnit}
                    watchDoseModeTime={watchDoseModeTime}
                    watchAllowBolus={watchAllowBolus}
                    watchBolusAmountLHL={watchBolusAmountLHL}
                    watchBolusAmountLSL={watchBolusAmountLSL}
                    watchBolusAmountUHL={watchBolusAmountUHL}
                    watchBolusAmountUSL={watchBolusAmountUSL}
                    watchBolusAmountUnits={watchBolusAmountUnits}
                    watchBolusTimeLHL={watchBolusTimeLHL}
                    watchBolusTimeLSL={watchBolusTimeLSL}
                    watchBolusTimeUHL={watchBolusTimeUHL}
                    watchBolusTimeUSL={watchBolusTimeUSL}
                    watchBolusTimeUnits={watchBolusTimeUnits}
                    watchWeightBased={watchWeightBased}
                    watchAllowLoad={watchAllowLoad}
                    watchLoadAmountUnits={watchLoadAmountUnits}
                    watchLoadTimeUnits={watchLoadTimeUnits}
                    watchConcentrationLHL={watchConcentrationLHL}
                    watchConcentrationUHL={watchConcentrationUHL}
                    canManage={!concentration.is_basic}
                  />
                ) : null}

                {/* DRUG AMOUNT LIMITS */}
                {!!record &&
                !!isConfirmed &&
                record?.type === ConcentrationTypesEnum.UNITS_ONLY &&
                watchDoseModeType?.value ===
                  DoseModeTypeEnum.NON_WEIGHT_BASED ? (
                  <DrugAmountLimits
                    record={record}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    watchDrugAmountLHL={watchDrugAmountLHL}
                    watchDrugAmountLSL={watchDrugAmountLSL}
                    watchDrugAmountUSL={watchDrugAmountUSL}
                    watchDrugAmountUHL={watchDrugAmountUHL}
                    watchDoseModeUnit={watchDoseModeUnit}
                    canManage={!concentration.is_basic}
                  />
                ) : null}

                {/* CONCENTRATION - Continuous infusion type - Units only*/}
                {!!record &&
                  !!isConfirmed &&
                  !!watchInfusionType &&
                  watchInfusionType.value === InfusionTypesEnum.INTERMITTENT &&
                  record?.type === ConcentrationTypesEnum.UNITS_ONLY && (
                    <ConcentrationFields
                      record={record}
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                      watchConcentrationLHL={watchConcentrationLHL}
                      watchConcentrationLSL={watchConcentrationLSL}
                      watchConcentrationUHL={watchConcentrationUHL}
                      watchConcentrationUSL={watchConcentrationUSL}
                      watchAllowConcentrationLimits={
                        watchAllowConcentrationLimits
                      }
                      infusionType={watchInfusionType?.value}
                      canManage={!concentration.is_basic}
                    />
                  )}

                {/* Confirm initial properties */}
                {!isConfirmed && !concentration.is_basic && (
                  <ConfirmationButton
                    isValid={isValid}
                    onClick={handleConfirmClick}
                  />
                )}

                {/* SUBMIT & CANCEL */}
                {!!isConfirmed && (
                  <div className="pt-5 pb-3">
                    <Button
                      loading={formSubmitting}
                      defaultLabel={t('buttons.save')}
                      loadingLabel={t('buttons.saving')}
                      type="submit"
                      disabled={formSubmitting || formLoading || !isDirty}
                      data-testid="submit-button"
                    ></Button>

                    <a
                      href="/#"
                      className={`btn btn-lg rounded-1 btn-secondary ${
                        formSubmitting || formLoading || !isDirty
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={handleCancel}
                      data-testid="cancel-button"
                    >
                      {t('buttons.cancel')}
                    </a>
                  </div>
                )}
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConcentrationForm;
