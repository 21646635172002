import {
  ConcentrationTypesEnum,
  DrugUnitsEnum,
  InfusionTypesEnum,
} from 'constants/drugs';
import {
  getDoseModeTimeValues,
  getDoseModeTypeValues,
  getDoseModeValueMetric,
  getDrugUnitValues,
} from 'helpers/drugs';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { FC, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Switch from 'react-switch';

type DoseModeProps = {
  record: Concentration | null;
  control: Control<ConcentrationFormData, any>;
  isConfirmed: boolean;
  watchWeightBased: boolean;
  watchDoseModeUnit:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchInfusionType:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchDoseModeTime:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchDoseModeType:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  canManage: boolean;
};

const DoseMode: FC<DoseModeProps> = ({
  record,
  control,
  isConfirmed,
  watchInfusionType,
  watchDoseModeUnit,
  watchWeightBased,
  watchDoseModeTime,
  watchDoseModeType,
  canManage,
}) => {
  const { t } = useTranslation();

  // Get dose mode values based on Infusion Type
  const doseModeTypeValues = useMemo(() => {
    return getDoseModeTypeValues(watchInfusionType, record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchInfusionType, record]);

  const drugUnit = useMemo(() => {
    if (!record) return '';

    return record?.type === ConcentrationTypesEnum.MILLILITER_BASED
      ? DrugUnitsEnum.MILLILITER
      : record?.drug_unit;
  }, [record]);

  const equalDrugModeUnit = useMemo(() => {
    if (watchDoseModeUnit) {
      return drugUnit === watchDoseModeUnit.value;
    }

    if (record?.is_complete) {
      return drugUnit === record.dose_mode_unit;
    }
  }, [record, watchDoseModeUnit, drugUnit]);

  // Get dose mode values based on Infusion Type
  const drugUnitValues = useMemo(() => {
    return getDrugUnitValues(watchWeightBased, record, watchInfusionType);
  }, [watchInfusionType, watchWeightBased, record]);

  // Get Dose Mode Time values
  const doseModeTimeValues = useMemo(() => {
    return getDoseModeTimeValues(
      watchWeightBased,
      record,
      watchInfusionType,
      watchDoseModeUnit,
    );
  }, [watchInfusionType, watchDoseModeUnit, watchWeightBased, record]);

  return (
    <div className={`pt-2 position-relative`}>
      {!canManage && (
        <div className="device-concentration--disabled-item"></div>
      )}
      {!isConfirmed &&
      (watchInfusionType?.value === InfusionTypesEnum.CONTINUOUS ||
        watchInfusionType?.value === InfusionTypesEnum.MULTI_STEP) ? (
        <div className="row">
          <div className="device-concentration-form__label">
            {t('device.weight_based')}
          </div>
          <div className="device-concentration-form__column">
            <Controller
              control={control}
              name="weight_based"
              render={({ field: { onChange, value, ref } }) => (
                <Switch
                  data-testid="weight_based"
                  ref={ref}
                  onChange={onChange}
                  checked={value}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  height={24}
                  width={50}
                  disabled={!canManage}
                />
              )}
            />
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="device-concentration-form__label">
          {t('device.dose_mode')}
        </div>

        {/* DOSE MODE UNIT */}
        {watchInfusionType?.value === InfusionTypesEnum.CONTINUOUS ||
        watchInfusionType?.value === InfusionTypesEnum.MULTI_STEP ? (
          <>
            {!isConfirmed ? (
              <div className="device-concentration-form__select">
                <Controller
                  control={control}
                  name="dose_mode_unit"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      aria-label="dose_mode_unit"
                      placeholder="- Select unit -"
                      maxMenuHeight={300}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as any}
                      isMulti={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      isSearchable
                      options={drugUnitValues.map((drugUnit: string) => ({
                        label: drugUnit,
                        value: drugUnit,
                      }))}
                      isDisabled={!canManage}
                    />
                  )}
                />
              </div>
            ) : (
              <>
                <div
                  className={`device-concentration-form__column device-concentration-form__concentration--dose-mode ${
                    isConfirmed ? 'pe-0' : ''
                  } ${
                    isConfirmed &&
                    (!equalDrugModeUnit
                      ? 'device-concentration--dose-highlight'
                      : '')
                  }`}
                  data-testid="dose_mode_unit__complete"
                >
                  {record?.is_complete
                    ? record.dose_mode_unit
                    : watchDoseModeUnit?.label}
                </div>
              </>
            )}
            {watchWeightBased ? (
              <div
                className={`device-concentration-form__column device-concentration-form__concentration--dose-mode ${
                  isConfirmed ? 'ps-0 pe-0' : ''
                } ${
                  isConfirmed &&
                  (!equalDrugModeUnit
                    ? 'device-concentration--dose-highlight'
                    : '')
                }`}
                data-testid="weight_based__text"
              >
                <span className={`${isConfirmed ? 'ps-1 pe-1' : ''}`}>/</span>
                <span className={`${isConfirmed ? '' : 'ps-4'}`}>kg</span>
              </div>
            ) : null}
            <div
              className={`device-concentration-form__column device-concentration-form__concentration--dose-mode ${
                isConfirmed ? 'ps-1 pe-1' : ''
              } ${
                isConfirmed &&
                (!equalDrugModeUnit
                  ? 'device-concentration--dose-highlight'
                  : '')
              }`}
            >
              /
            </div>

            {!isConfirmed ? (
              <div className="device-concentration-form__select">
                <Controller
                  control={control}
                  name="dose_mode_time"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      aria-label="dose_mode_time"
                      placeholder="- Select time -"
                      maxMenuHeight={300}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as any}
                      isMulti={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      isSearchable
                      options={
                        doseModeTimeValues
                          ? doseModeTimeValues.map((timeUnit: string) => ({
                              label: timeUnit,
                              value: timeUnit,
                            }))
                          : []
                      }
                      isDisabled={!canManage}
                    />
                  )}
                />
              </div>
            ) : (
              <div
                className={`device-concentration-form__column device-concentration-form__concentration--dose-mode ${
                  isConfirmed ? 'ps-0 pe-0' : ''
                } ${
                  isConfirmed &&
                  (!equalDrugModeUnit
                    ? 'device-concentration--dose-highlight'
                    : '')
                }`}
                data-testid="dose_mode_time__complete"
              >
                {record?.is_complete
                  ? record.dose_mode_time
                  : watchDoseModeTime?.label}
              </div>
            )}

            {(isConfirmed || record?.is_complete) && !equalDrugModeUnit ? (
              <span
                className={`device-concentration-form__column ps-5 device-concentration--dose-highlight`}
              >
                {t('device.messages.highlight_dose_mode')}
              </span>
            ) : null}
          </>
        ) : null}

        {watchInfusionType?.value === InfusionTypesEnum.INTERMITTENT ? (
          <>
            {/* DOSE MODE TYPE - only for Intermittent */}
            {!isConfirmed ? (
              <div className="device-concentration-form__select">
                <Controller
                  control={control}
                  name="dose_mode_type"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      aria-label="dose_mode_type"
                      placeholder="- Select type -"
                      maxMenuHeight={300}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value as any}
                      isMulti={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      isSearchable
                      isDisabled={!canManage}
                      options={Object.keys(doseModeTypeValues).map(
                        (key: string) => ({
                          label:
                            drugUnit +
                            ' ' +
                            doseModeTypeValues[
                              key as keyof typeof doseModeTypeValues
                            ],
                          value: key,
                        }),
                      )}
                    />
                  )}
                />
              </div>
            ) : (
              <div
                data-testid="dose_mode_type__complete"
                className={`device-concentration-form__column device-concentration-form__concentration--dose-mode`}
              >
                {`${drugUnit} ${
                  getDoseModeValueMetric(watchDoseModeType?.value) ? '/ ' : ''
                }${getDoseModeValueMetric(watchDoseModeType?.value)}`}

                {drugUnit !== watchDoseModeUnit?.value ? (
                  <span className="ps-5 device-concentration--dose-highlight">
                    {t('device.messages.highlight_dose_mode')}
                  </span>
                ) : null}
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DoseMode;
