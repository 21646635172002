import Button from 'components/UI/Button/Button';
import { DeploymentRequirements, UserCredentials } from 'interfaces/deployment';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import DeploymentService from 'services/DeploymentService';
import { toast } from 'react-hot-toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import DeviceSelector from '../DeploymentForm/DeviceSelector';
import { useAppSelector } from 'hooks/useReduxHooks';
import { userSelector } from 'store/selectors';
import { DEPLOYMENT_JSON_PREFIX } from 'constants/router';
import CredentialsFields from '../CredentialsFields/CredentialsFields';
import { DEPLOYMENT_FORM_INPUT_WIDTH } from 'constants/deployment';

const PartialDeploymentTab = ({
  libraryHasNotChangedSinceLastDeployment,
}: {
  libraryHasNotChangedSinceLastDeployment: boolean;
}) => {
  const user = useAppSelector(userSelector);

  const { pathname } = useLocation();

  const {
    data: isThereAnActivePartialDep,
    isLoading,
    isFetching,
  } = useQuery(
    [ReactQueryKeys.PARTIAL_DEPLOYMENT],
    () => DeploymentService.checkPartialDeployment(),
    {
      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
      keepPreviousData: true,
    },
  );

  const { t } = useTranslation();

  /* ----------------------------------------- */
  const deploymentInitialState: DeploymentRequirements = {
    hasDownloaded: true,
    hasReviewed: true,
    deploy_to_all_devices: false,
    devices_selected: [],
    username: user?.email || '',
    password: '',
  };

  const formMethods = useForm<DeploymentRequirements>({
    defaultValues: deploymentInitialState,
  });

  const {
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const editAllDevices = watch('deploy_to_all_devices');
  const selectedDevices = watch('devices_selected');

  /* ------------------------------------------------------------------- */
  const queryClient = useQueryClient();

  const { isLoading: isLoadingBis, mutate } = useMutation(
    (formData: DeploymentRequirements) => DeploymentService.create(formData),
    {
      onSuccess: () => {
        toast.success(t('deployment.messages.deployment__success'));
        reset();
        // Invalidate the query for the deployment list to refetch the updated data + Partial deployment in case we need to display a different UI
        queryClient.invalidateQueries([ReactQueryKeys.DEPLOYMENTS]);
        queryClient.invalidateQueries([ReactQueryKeys.PARTIAL_DEPLOYMENT]);
        queryClient.invalidateQueries([ReactQueryKeys.CAN_DEPLOY]);
      },
      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
    },
  );

  const {
    isLoading: isLoadingUpdate,
    mutate: mutateAnActivePartialDeployment,
  } = useMutation(
    (data: UserCredentials) =>
      DeploymentService.updatePartialDeployment(data, 'update-to-full-library'),
    {
      onSuccess: () => {
        toast.success(t('deployment.messages.deployment__success'));
        reset();
        queryClient.invalidateQueries([ReactQueryKeys.DEPLOYMENTS]);
        queryClient.invalidateQueries([ReactQueryKeys.PARTIAL_DEPLOYMENT]);
        queryClient.invalidateQueries([ReactQueryKeys.CAN_DEPLOY]);
      },
      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
    },
  );

  const handleFormSubmit: SubmitHandler<DeploymentRequirements> = (
    dataToCreatePartialDeployment,
  ) => {
    if (isThereAnActivePartialDep?.data) {
      mutateAnActivePartialDeployment({
        password: dataToCreatePartialDeployment.password,
        username: dataToCreatePartialDeployment.username,
      });
    } else {
      mutate(dataToCreatePartialDeployment);
    }
  };

  return (
    <>
      <Outlet />
      <h5 className="py-4" data-testid="partial-deployments-tab">
        <b> {t('deployment.partial_deployment.header')}</b>
      </h5>

      {!!isThereAnActivePartialDep?.data && (
        <div className="d-flex flex-column flex-fill">
          <h6 className="d-flex fw-bold">
            {(() => {
              const allPartialDevices =
                isThereAnActivePartialDep.data?.deploymentDevices ?? [];
              const deviceNames = allPartialDevices
                .map((dev) => dev.device_number)
                .join(', ');

              return (
                <div className="flex-fill">
                  <div className="py-2">
                    {t(
                      `deployment.partial_deployment.active_${
                        allPartialDevices.length > 1 ? 'multiple' : 'single'
                      }`,
                    )}
                    {deviceNames}
                  </div>
                </div>
              );
            })()}

            <span className="pe-5">
              {isLoading ||
                (isFetching && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ))}
            </span>
          </h6>
        </div>
      )}
      <div className="d-flex align-items-start mt-3">
        <Link
          to={`${pathname}/${DEPLOYMENT_JSON_PREFIX}`}
          state={pathname}
          className="btn btn-lg rounded btn-primary me-3 text-nowrap"
          //avoid text overflow
          style={{ minWidth: 'auto' }}
          data-testid="view_changes_json_comparison_btn"
        >
          {t('deployment.buttons.view_changes')}
        </Link>
        {libraryHasNotChangedSinceLastDeployment && (
          <p
            className="d-flex pt-1"
            data-testid="can_not_deploy_unchanged_library_error"
          >
            {t('deployment.errors.can_not_deploy_unchanged_library')}
          </p>
        )}
      </div>

      <form
        className="form-fixed-width d-flex flex-column pt-5 w-100 ml-auto"
        onSubmit={handleSubmit(handleFormSubmit)}
        autoComplete="off"
        data-testid="partial_deployment_form"
      >
        <>
          <div className="pt-2">
            {!isThereAnActivePartialDep?.data && !editAllDevices && (
              <div style={{ width: DEPLOYMENT_FORM_INPUT_WIDTH }}>
                <DeviceSelector
                  control={control}
                  requiredError={errors.devices_selected?.type === 'required'}
                  libraryHasNotChangedSinceLastDeployment={
                    libraryHasNotChangedSinceLastDeployment
                  }
                />
              </div>
            )}
            {(!!selectedDevices.length ||
              !!isThereAnActivePartialDep?.data) && (
              <div
                style={{
                  width: DEPLOYMENT_FORM_INPUT_WIDTH,
                }}
              >
                <CredentialsFields
                  formMethods={formMethods}
                  disabled={libraryHasNotChangedSinceLastDeployment}
                />
                <div className="d-flex justify-content-center">
                  <Button
                    loading={isLoadingBis || isLoadingUpdate}
                    defaultLabel={t('deployment.buttons.deploy_btn')}
                    type="submit"
                    disabled={
                      isLoadingBis ||
                      libraryHasNotChangedSinceLastDeployment ||
                      isLoadingUpdate
                    }
                    loadingLabel={t('buttons.saving')}
                    data-testid="submit_deployment_form"
                  />
                </div>
              </div>
            )}
          </div>
        </>
      </form>
    </>
  );
};

export default PartialDeploymentTab;
