import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import overlayReducer from './slices/overlay';
import facilityReducer from './slices/facility';
import advisoryReducer from './slices/advisory';
import careAreaReducer from './slices/careArea';
import therapyReducer from './slices/therapy';
import drugReducer from './slices/drug';
import deviceReducer from './slices/device';
import deploymentReducer from './slices/deployment';

const store = configureStore({
  reducer: {
    auth: authReducer,
    overlay: overlayReducer,
    facility: facilityReducer,
    careArea: careAreaReducer,
    therapy: therapyReducer,
    drug: drugReducer,
    advisory: advisoryReducer,
    device: deviceReducer,
    deployment: deploymentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
