import {
  Controller,
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import { InfusionTypesEnum } from 'constants/drugs';
import { IoMdHelpCircle } from 'react-icons/io';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';
import InfuseUntilEmpty from './InfuseUntilEmpty';
import InfusionComplete from './InfusionComplete';
import RadioInput from 'components/UI/RadioInput/RadioInput';

type InfuseAsProps = {
  control: Control<ConcentrationFormData, any>;
  watchInfusePrimary: boolean;
  watchInfuseSecondary: boolean;
  watchAllowSecondary: string | number | null;
  watchInfusionType: string | undefined;
  watchInfuseUntilEmpty: ConcentrationFormData['infuse_until_empty'];
  errors: FieldErrors<ConcentrationFormData>;
  register: UseFormRegister<ConcentrationFormData>;
  canManage: boolean;
  record: Concentration | null;
  setValue: UseFormSetValue<ConcentrationFormData>;
};

const InfuseAs: FC<InfuseAsProps> = ({
  control,
  register,
  watchInfusePrimary,
  watchAllowSecondary,
  watchInfuseSecondary,
  watchInfusionType,
  watchInfuseUntilEmpty,
  errors,
  canManage,
  record,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <div className="customRow pt-2">
      <div
        className={`device-concentration-${
          !canManage ? 'disabled' : 'form__label'
        }`}
      >
        {t('device.infuse_as')}
      </div>
      <div className="customRow flex-column">
        <div className="customRow pt-3 position-relative">
          {!canManage && (
            <div className="device-concentration--disabled-item"></div>
          )}

          <div>
            <div className="device-concentration-primary-secondary--width">
              <Controller
                control={control}
                name="infuse_primary"
                rules={{
                  validate: {
                    primaryOrSecondarySelected: (value) => {
                      return value || watchInfuseSecondary;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Checkbox
                    id="infuse_primary"
                    title={t('device.infuse_primary')}
                    checked={value}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={(e) => {
                      if (
                        e.target.checked &&
                        watchInfusionType === InfusionTypesEnum.INTERMITTENT
                      ) {
                        setValue('infuse_until_empty', 'ON');
                      }
                      if (!e.target.checked) {
                        // Reset value for allow secondary + infusion_complete +infuse_until_empty when infuse primary is unchecked
                        setValue('allow_secondary', null);
                        setValue('infusion_complete', null);

                        if (
                          watchInfusionType === InfusionTypesEnum.INTERMITTENT
                        ) {
                          setValue('infuse_until_empty', null);
                        }
                      }
                      onChange(e);
                    }}
                    value={'infuse_primary'}
                    disabled={!canManage}
                    data-testid="infuse_primary"
                  />
                )}
              />
            </div>

            {!!watchInfusePrimary && (
              <div className="customRow pt-2">
                <div className="device-concentration-primary-secondary2--width">
                  {t('device.allow_secondary')}
                  <span
                    data-tooltip-id="allow_secondary-tooltip"
                    className="ps-1 tooltip_over_disabled_overlay"
                  >
                    <IoMdHelpCircle color="#fff" size={24} />
                  </span>
                  <ReactTooltip id="allow_secondary-tooltip">
                    {t('device.allow_secondary__tooltip')}
                  </ReactTooltip>
                </div>
                <div>
                  <div className="customRow align-items-start align-basic-radios">
                    <RadioInput
                      {...register('allow_secondary', {
                        required: true,
                      })}
                      value={0}
                      id="allow-secondary-off"
                      data-testid="allow_secondary"
                      title={t('buttons.off')}
                    />

                    <RadioInput
                      {...register('allow_secondary', {
                        required: true,
                      })}
                      value={1}
                      id="allow-secondary-on"
                      data-testid="allow_secondary"
                      title={t('buttons.on')}
                    />
                  </div>
                  <div>
                    {(watchAllowSecondary === null ||
                      watchAllowSecondary === 'null') && (
                      <div className="invalid-feedback">
                        {t('device.errors.one_option__required')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* INFUSE UNTIL EMPTY */}
        {!!record &&
        watchInfusePrimary &&
        (watchInfusionType === InfusionTypesEnum.INTERMITTENT || !canManage) ? (
          <InfuseUntilEmpty register={register} canManage={true} />
        ) : null}

        {/* INFUSION COMPLETE */}
        {watchInfusePrimary &&
          ((watchInfusionType === InfusionTypesEnum.INTERMITTENT &&
            watchInfuseUntilEmpty === 'OFF') ||
            watchInfusionType !== InfusionTypesEnum.INTERMITTENT) && (
            <InfusionComplete
              errors={errors}
              register={register}
              canManage={canManage}
            />
          )}

        {/* SECONDARY INFUSION SECTION */}
        <div className="position-relative pt-3">
          {!canManage && (
            <div className="device-concentration--disabled-item"></div>
          )}
          <div className="device-concentration-primary-secondary--width pt-4">
            <Controller
              control={control}
              name="infuse_secondary"
              rules={{
                validate: {
                  primaryOrSecondarySelected: (value) => {
                    return watchInfusePrimary || value;
                  },
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  id="infuse_secondary"
                  title={t('device.infuse_secondary')}
                  checked={value}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={'infuse_secondary'}
                  disabled={!canManage}
                  data-testid="infuse_secondary"
                />
              )}
            />
          </div>
        </div>

        <div>
          {!watchInfusePrimary && !watchInfuseSecondary && (
            <div className="invalid-feedback">
              {t('device.errors.infuse_primary_or_secondary__required')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfuseAs;
