import { UseFormRegister } from 'react-hook-form';
import { ConcentrationFormData } from 'interfaces/concentration';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import RadioInput from 'components/UI/RadioInput/RadioInput';

type InfuseUntilEmptyProps = {
  canManage: boolean;
  register: UseFormRegister<ConcentrationFormData>;
};

const InfuseUntilEmpty: FC<InfuseUntilEmptyProps> = ({
  canManage,
  register,
}) => {
  const { t } = useTranslation();

  return (
    <div className="customRow pt-2">
      <div className="device-concentration-primary-secondary2--width">
        {t('device.infuse_until_empty')}
      </div>
      <div className="customRow ps-0  align-basic-radios">
        <RadioInput
          {...register('infuse_until_empty')}
          value={'OFF'}
          id="infuse_until_empty__off"
          disabled={!canManage}
          title={t('device.infuse_until_empty__off')}
          data-testid="infuse_until_empty"
        />
        <RadioInput
          {...register('infuse_until_empty')}
          value={'ON'}
          id="infuse_until_empty__on"
          disabled={!canManage}
          title={t('device.infuse_until_empty__on')}
          data-testid="infuse_until_empty"
        />
      </div>
    </div>
  );
};

export default InfuseUntilEmpty;
