import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { CareArea } from 'interfaces/care-area';
import { Facility } from 'interfaces/facility';
import { toast } from 'react-hot-toast';
import { parseErrorMessage } from 'helpers/parse-error-message';
import DrugService from 'services/DrugService';
import { ListDrug } from 'interfaces/list-drug';
import { ListConcentration } from 'interfaces/list-concentration';
import { sortConcentrations } from 'helpers/drugs';
import DebouncedInput from 'components/UI/DebouncedInput/DebouncedInput';
import { ColumnFiltersState } from '@tanstack/react-table';
import ListPagination from 'components/UI/ListPagination/ListPagination';
import { useDispatch } from 'react-redux';
import { deviceActions } from 'store/slices/device';

type ListDrugsProps = {
  careArea: CareArea;
  facility: Facility;
  canManage: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ListDrugs: FC<ListDrugsProps> = ({ canManage, facility, careArea }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState<string>('');
  const [listFilters, setListFilters] = useState<ColumnFiltersState>([]);
  const [selectedDrugId, setSelectedDrugId] = useState<null | number>(null);
  const dispatch = useDispatch();

  const { data: listDrugs } = useQuery(
    [
      ReactQueryKeys.LIST_DRUGS,
      page,
      facility.id,
      careArea.id,
      listFilters,
      pageSize,
    ],
    () =>
      DrugService.getListDrugsWithListConcentrations({
        pageIndex: page,
        pageSize: pageSize,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
        columnFilters: listFilters,
      }),
    { keepPreviousData: true },
  );

  const updateFilter = (value: string) => {
    setListFilters([
      {
        id: 'name',
        value,
      },
    ]);
  };

  const handleListConcentrationClick = async (
    listConcentration: ListConcentration,
    listDrugId: ListDrug['id'],
  ) => {
    try {
      const { data: concentration } =
        await DrugService.assignConcentrationToCareArea({
          careAreaId: Number(careArea.id),
          facilityId: Number(facility.id),
          listConcentrationId: Number(listConcentration.id),
          listDrugId: Number(listDrugId),
        });

      dispatch(
        deviceActions.setExpandedDrug({
          id: +concentration.drug_id,
        }),
      );
      dispatch(
        deviceActions.setExpandedTherapies({
          id: +concentration.list_concentration_id,
        }),
      );

      queryClient.invalidateQueries([ReactQueryKeys.DRUGS]);
      queryClient.invalidateQueries([ReactQueryKeys.FACILITY_CARE_AREAS]);

      toast.success(
        t('device.messages.concentration_assign__success', {
          name: listConcentration.name,
          interpolation: {
            escapeValue: false,
          },
        }),
      );
    } catch (e: any) {
      let message = t('device.messages.concentration_assign__error');
      if (
        e.response.data?.statusCode === 400 ||
        e.response.data?.statusCode === 502
      ) {
        message = parseErrorMessage(e.response.data);
      }
      toast.error(message);
    }
  };

  const handleListDrugClick = (listDrug: ListDrug) => {
    setSelectedDrugId(Number(listDrug.id));
  };

  return (
    <>
      <DebouncedInput
        placeholder={t('device.drugs_search_box')}
        value={search}
        type="text"
        onChange={(value) => {
          setSearch(value as string);
          updateFilter(value as string);
        }}
        data-testid="list-drugs-search-box"
        className="form-control form-control rounded mb-4 mt-4"
      />

      {listDrugs?.data?.records && listDrugs.data.records.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {listDrugs?.data?.records && listDrugs.data.records.length > 0 && (
        <div className="master-list device__care-area--full-list">
          {listDrugs.data.records.map((listDrug: ListDrug) => {
            return (
              <div key={`drug_group_${listDrug.id}`}>
                <a
                  key={`drug_${listDrug.id}`}
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleListDrugClick(listDrug);
                  }}
                  className={`master-list__item device-item ${
                    selectedDrugId === Number(listDrug.id) ? 'active' : ''
                  }`}
                  data-testid={`handle-list-drug-click-${listDrug.id}`}
                >
                  {listDrug.name}
                </a>

                {/* Concentrations for the drug */}
                {Array.isArray(listDrug.concentrations) &&
                listDrug.concentrations.length === 0 ? (
                  <div
                    className={`alert mb-0 device-list-drug--concentration-alert
                  ${
                    selectedDrugId === Number(listDrug.id)
                      ? 'active-concentration'
                      : ''
                  }
                  `}
                    data-testid={`list-drug-without-concentrations-${listDrug.id}`}
                  >
                    {t('device.messages.no_concentrations')}
                  </div>
                ) : null}

                {Array.isArray(listDrug.concentrations) &&
                  listDrug.concentrations.length > 0 &&
                  sortConcentrations(
                    listDrug.concentrations as ListConcentration[],
                  ).map((concentration: ListConcentration) => {
                    return (
                      <a
                        key={`drug_${listDrug.id}_concentration_${concentration.id}`}
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleListConcentrationClick(
                            concentration,
                            listDrug.id,
                          );
                        }}
                        className={`master-list__item device-item device-list-drug--concentration
                            ${
                              selectedDrugId === Number(listDrug.id)
                                ? 'active-concentration'
                                : ''
                            }

                            `}
                        data-testid={`handle-list-concentration-click-${listDrug.id}-${concentration.id}`}
                      >
                        {concentration.name}
                      </a>
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}
      {listDrugs?.data && (
        <ListPagination
          canGetNextPage={
            Number(listDrugs?.data.pageIndex + 1) <
            Number(listDrugs?.data.totalPages)
          }
          canGetPreviousPage={Number(listDrugs?.data.pageIndex) > 0}
          nextPage={() => {
            setPage(page + 1);
          }}
          previousPage={() => {
            setPage(page - 1);
          }}
          pageCount={listDrugs?.data.totalPages}
          pageIndex={page}
          pageSize={pageSize}
          setPageSize={(pageSize: number) => setPageSize(pageSize)}
          setPageIndex={(pageIndex: number) => setPage(pageIndex)}
        />
      )}
    </>
  );
};

export default ListDrugs;
