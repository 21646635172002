import {
  ConcentrationTypesEnum,
  DrugUnitsEnum,
  LOAD_BOLUS_UNITS_LIMITS,
  LOAD_BOLUS_UNITS_MAP,
  TimeEnum,
  UNITS_CONVERSION,
} from '../constants/drugs';
import { Concentration } from '../interfaces/concentration';
import { calculateConcentrationValue } from './drugs';
import { BigNumber } from 'bignumber.js';

export const calculateDoseRateLimit = (
  patientWeight: number,
  concentration: number,
  isWeightBased: boolean,
  concentrationUnit: string,
  timeUnit: string,
  doseModeUnit: string,
  deviceRateLimit: number,
) => {
  const unitFactor = getUnitFactor(doseModeUnit, concentrationUnit);
  const timeFactor = getTimeFactor(timeUnit);

  if (isWeightBased) {
    return BigNumber(
      BigNumber(deviceRateLimit)
        .multipliedBy(concentration)
        .dividedBy(unitFactor),
    ).dividedBy(BigNumber(patientWeight).multipliedBy(timeFactor));
  }

  return BigNumber(
    BigNumber(deviceRateLimit).multipliedBy(concentration),
  ).dividedBy(BigNumber(timeFactor).multipliedBy(unitFactor));
};

/**
 * Calculate Dose Rate limits
 *
 * @param amount Amount based on which the precision is calculated
 * @returns number
 */
export const calculateDoseRateHardLimits = (
  concentration: Concentration,
  isWeightBased: boolean,
  doseModeUnit: string | null | undefined,
  doseModeTimeUnit: string | null | undefined,
  concentrationLHL: null | number | undefined | string,
  concentrationUHL: null | number | undefined | string,
) => {
  if (!doseModeUnit || !doseModeTimeUnit) {
    return {
      min: 0,
      max: 0,
    };
  }

  const CONCENTRATION_LOWER_LIMIT = 0.001;
  const CONCENTRATION_UPPER_LIMIT = 9999;

  const DEVICE_RATE_LOWER_LIMIT = 0.5;
  const DEVICE_RATE_UPPER_LIMIT = 1000;

  switch (concentration.type) {
    case ConcentrationTypesEnum.FULL: {
      if (doseModeUnit === DrugUnitsEnum.MILLILITER) {
        return {
          min: DEVICE_RATE_LOWER_LIMIT,
          max: DEVICE_RATE_UPPER_LIMIT,
        };
      }

      const minBN: BigNumber = calculateDoseRateLimit(
        Number(concentration.care_area_patient_weight_upper_hard_limit),
        calculateConcentrationValue(
          concentration.drug_amount! / concentration.volume!,
        ),
        isWeightBased,
        concentration.drug_unit,
        doseModeTimeUnit,
        doseModeUnit,
        DEVICE_RATE_LOWER_LIMIT,
      );

      const maxBN: BigNumber = calculateDoseRateLimit(
        Number(concentration.care_area_patient_weight_lower_hard_limit),
        calculateConcentrationValue(
          concentration.drug_amount! / concentration.volume!,
        ),
        isWeightBased,
        concentration.drug_unit,
        doseModeTimeUnit,
        doseModeUnit,
        DEVICE_RATE_UPPER_LIMIT,
      );

      let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
      min = min < CONCENTRATION_LOWER_LIMIT ? CONCENTRATION_LOWER_LIMIT : min;
      min = min > CONCENTRATION_UPPER_LIMIT ? CONCENTRATION_UPPER_LIMIT : min;

      let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
      max = max > CONCENTRATION_UPPER_LIMIT ? CONCENTRATION_UPPER_LIMIT : max;
      max = max < CONCENTRATION_LOWER_LIMIT ? CONCENTRATION_LOWER_LIMIT : max;

      return {
        min,
        max,
      };
    }
    case ConcentrationTypesEnum.UNITS_ONLY: {
      if (doseModeUnit === DrugUnitsEnum.MILLILITER) {
        return {
          min: DEVICE_RATE_LOWER_LIMIT,
          max: DEVICE_RATE_UPPER_LIMIT,
        };
      }

      const minConcentrationLHL =
        !isNaN(concentrationLHL as any) && Number(concentrationLHL) > 0
          ? Number(concentrationLHL)
          : CONCENTRATION_LOWER_LIMIT;

      const maxConcentrationLHL =
        !isNaN(concentrationUHL as any) && Number(concentrationUHL) > 0
          ? Number(concentrationUHL)
          : CONCENTRATION_UPPER_LIMIT;

      const minBN = calculateDoseRateLimit(
        Number(concentration.care_area_patient_weight_upper_hard_limit),
        minConcentrationLHL,
        isWeightBased,
        concentration.drug_unit,
        doseModeTimeUnit,
        doseModeUnit,
        DEVICE_RATE_LOWER_LIMIT,
      );

      const maxBN = calculateDoseRateLimit(
        Number(concentration.care_area_patient_weight_lower_hard_limit),
        maxConcentrationLHL,
        isWeightBased,
        concentration.drug_unit,
        doseModeTimeUnit,
        doseModeUnit,
        DEVICE_RATE_UPPER_LIMIT,
      );

      let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
      min = min < CONCENTRATION_LOWER_LIMIT ? CONCENTRATION_LOWER_LIMIT : min;
      min = min > CONCENTRATION_UPPER_LIMIT ? CONCENTRATION_UPPER_LIMIT : min;

      let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
      max = max > CONCENTRATION_UPPER_LIMIT ? CONCENTRATION_UPPER_LIMIT : max;
      max = max < CONCENTRATION_LOWER_LIMIT ? CONCENTRATION_LOWER_LIMIT : max;

      return {
        min,
        max,
      };
    }
    case ConcentrationTypesEnum.MILLILITER_BASED: {
      return {
        min: DEVICE_RATE_LOWER_LIMIT,
        max: DEVICE_RATE_UPPER_LIMIT,
      };
    }
  }
};

/**
 * Get Loading and Bolus Units
 *
 * @param watchWeightBased
 * @param record Concentration
 * @returns Array
 */
export const getLoadingBolusUnits = (
  record: Concentration | null,
  watchWeightBased: boolean,
  doseModeUnit: string | null | undefined,
  doseModeTime: string | null | undefined,
) => {
  if (!record || !doseModeUnit || !doseModeTime) return [];
  let doseMode = doseModeUnit + ' / ' + doseModeTime;
  if (watchWeightBased) {
    doseMode = doseModeUnit + ' / kg / ' + doseModeTime;
  }

  return LOAD_BOLUS_UNITS_MAP[doseMode as keyof typeof LOAD_BOLUS_UNITS_MAP];
};

/**
 * Get Loading and Bolus Limits
 *
 * @param watchWeightBased
 * @param record Concentration
 * @param loadAmountUnit
 * @returns Array
 */
export const getLoadingBolusLimits = (
  record: Concentration | null,
  loadAmountUnit: { label: string; value: string } | null | undefined,
  concentrationLHL: null | number | undefined | string,
  concentrationUHL: null | number | undefined | string,
) => {
  const VOLUME_MIN = 1;
  const VOLUME_MAX = 4000;

  const CONCENTRATION_LOWER_LIMIT = 0.001;
  const CONCENTRATION_UPPER_LIMIT = 9999;

  const BOLUS_LOAD_AMOUNT_LOWER_LIMIT = 0.5;
  const BOLUS_LOAD_AMOUNT_UPPER_LIMIT = 1000;

  if (!loadAmountUnit || !record) return { min: 0, max: 0 };

  const limits = { min: 0, max: 0 };

  if (loadAmountUnit.value === DrugUnitsEnum.MILLILITER) {
    return {
      min: BOLUS_LOAD_AMOUNT_LOWER_LIMIT,
      max: BOLUS_LOAD_AMOUNT_UPPER_LIMIT,
    };
  } else if (loadAmountUnit.value === 'mL / kg') {
    const MAX_LIMITS = LOAD_BOLUS_UNITS_LIMITS[DrugUnitsEnum.MILLILITER].weight;

    switch (record.type) {
      case ConcentrationTypesEnum.FULL: {
        const minBN: BigNumber = BigNumber(VOLUME_MIN).dividedBy(
          record.care_area_patient_weight_upper_hard_limit!,
        );
        const maxBN: BigNumber = BigNumber(record.volume!).dividedBy(
          record.care_area_patient_weight_lower_hard_limit!,
        );

        let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
        min = min < MAX_LIMITS.min ? MAX_LIMITS.min : min;
        min = min > MAX_LIMITS.max ? MAX_LIMITS.max : min;

        let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
        max = max > MAX_LIMITS.max ? MAX_LIMITS.max : max;
        max = max < MAX_LIMITS.min ? MAX_LIMITS.min : max;

        return {
          min,
          max,
        };
      }

      case ConcentrationTypesEnum.MILLILITER_BASED:
      case ConcentrationTypesEnum.UNITS_ONLY: {
        const minBN: BigNumber = BigNumber(VOLUME_MIN).dividedBy(
          record.care_area_patient_weight_upper_hard_limit!,
        );
        const maxBN: BigNumber = BigNumber(VOLUME_MAX).dividedBy(
          record.care_area_patient_weight_lower_hard_limit!,
        );

        let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
        min = min < MAX_LIMITS.min ? MAX_LIMITS.min : min;
        min = min > MAX_LIMITS.max ? MAX_LIMITS.max : min;

        let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
        max = max > MAX_LIMITS.max ? MAX_LIMITS.max : max;
        max = max < MAX_LIMITS.min ? MAX_LIMITS.min : max;

        return {
          min,
          max,
        };
      }
    }
  } else {
    const weightBased = loadAmountUnit.value.split(' ').pop() === 'kg';
    // Non Weight based
    if (!weightBased) {
      const unit = loadAmountUnit.value.split(
        ' ',
      )[0] as keyof typeof LOAD_BOLUS_UNITS_LIMITS;
      const MAX_LIMITS = LOAD_BOLUS_UNITS_LIMITS[unit].nonWeight;

      switch (record.type) {
        case ConcentrationTypesEnum.FULL: {
          const unitFactor = getUnitFactor(record.drug_unit, String(unit));
          const concentration = calculateConcentrationValue(
            record.drug_amount! / record.volume!,
          );
          const minBN: BigNumber = BigNumber(VOLUME_MIN)
            .multipliedBy(unitFactor)
            .multipliedBy(concentration);
          const maxBN: BigNumber = BigNumber(record.volume!)
            .multipliedBy(unitFactor)
            .multipliedBy(concentration);

          let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
          min = min < MAX_LIMITS.min ? MAX_LIMITS.min : min;
          min = min > MAX_LIMITS.max ? MAX_LIMITS.max : min;

          let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
          max = max > MAX_LIMITS.max ? MAX_LIMITS.max : max;
          max = max < MAX_LIMITS.min ? MAX_LIMITS.min : max;

          return {
            min,
            max,
          };
        }
        case ConcentrationTypesEnum.UNITS_ONLY: {
          const unitFactor = getUnitFactor(record.drug_unit, String(unit));

          const minConcentrationLHL =
            !isNaN(concentrationLHL as any) && Number(concentrationLHL) > 0
              ? Number(concentrationLHL)
              : CONCENTRATION_LOWER_LIMIT;

          const maxConcentrationUHL =
            !isNaN(concentrationUHL as any) && Number(concentrationUHL) > 0
              ? Number(concentrationUHL)
              : CONCENTRATION_UPPER_LIMIT;

          const minBN: BigNumber = BigNumber(VOLUME_MIN)
            .multipliedBy(unitFactor)
            .multipliedBy(minConcentrationLHL);
          const maxBN: BigNumber = BigNumber(VOLUME_MAX)
            .multipliedBy(unitFactor)
            .multipliedBy(maxConcentrationUHL);

          let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
          min = min < MAX_LIMITS.min ? MAX_LIMITS.min : min;
          min = min > MAX_LIMITS.max ? MAX_LIMITS.max : min;

          let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
          max = max > MAX_LIMITS.max ? MAX_LIMITS.max : max;
          max = max < MAX_LIMITS.min ? MAX_LIMITS.min : max;

          return {
            min,
            max,
          };
        }
      }
    } //  Weight based
    else {
      const unit = loadAmountUnit.value.split(
        ' ',
      )[0] as keyof typeof LOAD_BOLUS_UNITS_LIMITS;
      const MAX_LIMITS = LOAD_BOLUS_UNITS_LIMITS[unit]?.weight;
      switch (record.type) {
        case ConcentrationTypesEnum.FULL: {
          const unitFactor = getUnitFactor(record.drug_unit, String(unit));
          const concentration = calculateConcentrationValue(
            record.drug_amount! / record.volume!,
          );

          const minBN: BigNumber = BigNumber(VOLUME_MIN)
            .multipliedBy(unitFactor)
            .multipliedBy(concentration)
            .dividedBy(record.care_area_patient_weight_upper_hard_limit!);
          const maxBN: BigNumber = BigNumber(record.volume!)
            .multipliedBy(unitFactor)
            .multipliedBy(concentration)
            .dividedBy(record.care_area_patient_weight_lower_hard_limit!);

          let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
          min = min < MAX_LIMITS.min ? MAX_LIMITS.min : min;
          min = min > MAX_LIMITS.max ? MAX_LIMITS.max : min;

          let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
          max = max > MAX_LIMITS.max ? MAX_LIMITS.max : max;
          max = max < MAX_LIMITS.min ? MAX_LIMITS.min : max;

          return {
            min,
            max,
          };
        }
        case ConcentrationTypesEnum.UNITS_ONLY: {
          const unitFactor = getUnitFactor(record.drug_unit, String(unit));
          const minConcentrationLHL =
            !isNaN(concentrationLHL as any) && Number(concentrationLHL) > 0
              ? Number(concentrationLHL)
              : CONCENTRATION_LOWER_LIMIT;

          const maxConcentrationLHL =
            !isNaN(concentrationUHL as any) && Number(concentrationUHL) > 0
              ? Number(concentrationUHL)
              : CONCENTRATION_UPPER_LIMIT;

          const minBN: BigNumber = BigNumber(VOLUME_MIN)
            .multipliedBy(unitFactor)
            .multipliedBy(minConcentrationLHL)
            .dividedBy(record.care_area_patient_weight_upper_hard_limit!);
          const maxBN: BigNumber = BigNumber(VOLUME_MAX)
            .multipliedBy(unitFactor)
            .multipliedBy(maxConcentrationLHL)
            .dividedBy(record.care_area_patient_weight_lower_hard_limit!);

          let min = Number(minBN.toFixed(3, BigNumber.ROUND_DOWN));
          min = min < MAX_LIMITS.min ? MAX_LIMITS.min : min;
          min = min > MAX_LIMITS.max ? MAX_LIMITS.max : min;

          let max = Number(maxBN.toFixed(3, BigNumber.ROUND_DOWN));
          max = max > MAX_LIMITS.max ? MAX_LIMITS.max : max;
          max = max < MAX_LIMITS.min ? MAX_LIMITS.min : max;

          return {
            min,
            max,
          };
        }
      }
    }
  }

  return limits;
};

// Convert concentration amount unit to selected dose unit (mg in grams)
export const getTimeFactor = (timeUnit: string): BigNumber => {
  switch (timeUnit) {
    case TimeEnum.DAY:
      return BigNumber(1).dividedBy(24);
    case TimeEnum.HOUR:
      return BigNumber(1);
    case TimeEnum.MINUTE:
      return BigNumber(60);
  }

  return BigNumber(1);
};

export const getUnitFactor = (
  doseModeUnit: string,
  concentrationUnit: string,
): BigNumber => {
  if (!concentrationUnit || !doseModeUnit) return BigNumber(1);

  // @ts-ignore
  return BigNumber(UNITS_CONVERSION[doseModeUnit][concentrationUnit]);
};

// Check if dose limits are not in range
export const wrongLimits = (limits?: { min: number; max: number }) => {
  if (!limits || isNaN(limits?.min as any) || isNaN(limits?.max as any))
    return true;

  return limits!.min >= limits!.max;
};

/**
 * Get Duration Limits
 *
 * @param watchDoseModeType
 * @param record Concentration
 * @returns Arrayduration
 */
export const getDurationLimits = (
  record: Concentration | null,
  watchDoseModeType?: { label: string; value: string } | null,
) => {
  const DEVICE_DURATION_LOWER_LIMIT = 2; // 2 minutes
  const DEVICE_DURATION_UPPER_LIMIT = 24 * 60; // 24 hours in minutes

  const DEVICE_RATE_UPPER_LIMIT = 1000;
  const DEVICE_RATE_LOWER_LIMIT = 0.5;

  if (!record || !watchDoseModeType) return { min: 0, max: 0 };

  if (record.type === ConcentrationTypesEnum.FULL) {
    let min = Math.floor(
      (Number(record.volume) / DEVICE_RATE_UPPER_LIMIT) * 60,
    );
    let max = Math.floor(
      (Number(record.volume) / DEVICE_RATE_LOWER_LIMIT) * 60,
    );

    max = max > 24 * 60 ? 24 * 60 : max;
    min = min < 2 ? 2 : min;

    return {
      min,
      max,
    };
  } else {
    return {
      min: DEVICE_DURATION_LOWER_LIMIT,
      max: DEVICE_DURATION_UPPER_LIMIT,
    };
  }
};
