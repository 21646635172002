export const DRUG_AMOUNT_MIN = 0.001;
export const DRUG_AMOUNT_MAX = 99999;
export const DRUG_VOLUME_MIN = 1;
export const DRUG_VOLUME_MAX = 4000;
export const DRUG_CONCENTRATION_MIN = 0.001;
export const DRUG_CONCENTRATION_MAX = 9999;

export const MINUTES = 'minutes';
export const SECONDS = 'seconds';

export enum InfusionTypesEnum {
  CONTINUOUS = 'continuous',
  INTERMITTENT = 'intermittent',
  MULTI_STEP = 'multi_step',
}

export const INFUSION_TYPES_VALUES = {
  continuous: 'Continuous',
  intermittent: 'Intermittent',
  // multi_step: 'Multi-step',
};

export const INFUSION_TYPES_VALUES_NO_INTERMITTENT = {
  continuous: 'Continuous',
  // multi_step: 'Multi-step',
};

export const INFUSION_TYPES_IDS = {
  continuous: 0,
  intermittent: 1,
  multi_step: 2,
};

export enum ConcentrationTypesEnum {
  FULL = 'full',
  UNITS_ONLY = 'units_only',
  MILLILITER_BASED = 'ml_based',
}

type ConcentrationTypesUnion =
  (typeof ConcentrationTypesEnum)[keyof typeof ConcentrationTypesEnum];

type ConcentrationTypeObjectsWithMandatoryKeys<T> = {
  [K in ConcentrationTypesUnion]: T;
};
export const CONCENTRATION_TYPES_VALUES: ConcentrationTypeObjectsWithMandatoryKeys<string> =
  {
    full: 'Full',
    units_only: 'Units Only',
    ml_based: 'mL-based',
  };

export const CONCENTRATION_TYPES_VALUES_FOR_SELECTOR: ConcentrationTypeObjectsWithMandatoryKeys<string> =
  {
    full: 'Full (Drug Amount and Volume)',
    units_only: 'Drug Units Only ( _ / _ mL )',
    ml_based: 'mL-based (Fluids)',
  } as const;

export const CONCENTRATION_TYPES_IDS: ConcentrationTypeObjectsWithMandatoryKeys<number> =
  {
    full: 0,
    units_only: 1,
    ml_based: 2,
  };

export enum DrugUnitsEnum {
  MILLILITER = 'mL',
  GRAM = 'gram',
  MILLIGRAM = 'mg',
  MICROGRAM = 'mcg',
  NANOGRAM = 'ng',
  UNIT = 'Unit',
  MILLIUNIT = 'mUnit',
  MILLIEQUIVALENT = 'mEq',
  MILLIMOLE = 'mmol',
}

export const DRUG_UNITS = [
  'gram',
  'mg',
  'mcg',
  'ng',
  'Unit',
  'mUnit',
  'mEq',
  'mmol',
];

export const DRUG_UNITS_SORTING = {
  gram: 1,
  mg: 2,
  mcg: 3,
  ng: 4,
  Unit: 5,
  mUnit: 6,
  mEq: 7,
  mmol: 8,
};

export enum TimeEnum {
  DAY = 'day',
  HOUR = 'hr',
  MINUTE = 'min',
}

export enum DoseModeTypeEnum {
  NON_WEIGHT_BASED = 'non_weight_based',
  WEIGHT_BASED = 'weight_based',
  BSA_BASED = 'bsa_based',
}

export const DOSE_MODE_WEIGHT_VALUES = {
  non_weight_based: '',
  weight_based: '/ kg',
};

export const DOSE_MODE_TYPES_VALUES = {
  non_weight_based: '',
  weight_based: '/ kg',
  bsa_based: '/ m²',
};

export const DOSE_MODE_UNITS_MAP = {
  [ConcentrationTypesEnum.MILLILITER_BASED]: {
    nonWeight: {
      mL: ['hr'],
    },
    weight: {
      mL: ['min', 'hr'],
    },
  },
  gram: {
    nonWeight: {
      gram: ['min', 'hr'],
      mg: ['min', 'hr'],
      mcg: ['min', 'hr'],
      mL: ['hr'],
    },
    weight: {
      mg: ['min', 'hr', 'day'],
      mcg: ['min', 'hr', 'day'],
      mL: ['min', 'hr'],
    },
  },
  mg: {
    nonWeight: {
      gram: ['hr'],
      mg: ['min', 'hr'],
      mcg: ['min', 'hr'],
      ng: ['min'],
      mL: ['hr'],
    },
    weight: {
      mg: ['min', 'hr', 'day'],
      mcg: ['min', 'hr', 'day'],
      ng: ['day'],
      mL: ['min', 'hr'],
    },
  },
  mcg: {
    nonWeight: {
      gram: ['hr'],
      mg: ['min', 'hr'],
      mcg: ['min', 'hr'],
      ng: ['min'],
      mL: ['hr'],
    },
    weight: {
      mg: ['min', 'hr', 'day'],
      mcg: ['min', 'hr', 'day'],
      mL: ['min', 'hr'],
    },
  },
  ng: {
    nonWeight: {
      ng: ['min'],
      mL: ['hr'],
    },
    weight: {
      ng: ['min', 'day'],
      mL: ['min', 'hr'],
    },
  },
  Unit: {
    nonWeight: {
      Unit: ['min', 'hr'],
      mUnit: ['min'],
      mL: ['hr'],
    },
    weight: {
      Unit: ['min', 'hr'],
      mUnit: ['min', 'hr'],
      mL: ['min', 'hr'],
    },
  },
  mUnit: {
    nonWeight: {
      Unit: ['min', 'hr'],
      mUnit: ['min'],
      mL: ['hr'],
    },
    weight: {
      Unit: ['min', 'hr'],
      mUnit: ['min', 'hr'],
      mL: ['min', 'hr'],
    },
  },
  mEq: {
    nonWeight: {
      mEq: ['min', 'hr'],
      mL: ['hr'],
    },
    weight: {
      mEq: ['hr', 'day'],
      mL: ['min', 'hr'],
    },
  },
  mmol: {
    nonWeight: {
      mmol: ['hr'],
      mL: ['hr'],
    },
    weight: {
      mmol: ['hr', 'day'],
      mL: ['min', 'hr'],
    },
  },
};

/**
 * Used for calculating Unit Factor in concentrations
 */
export const UNITS_CONVERSION = {
  gram: {
    gram: 1,
    mg: 1000,
    mcg: 1000000,
    ng: 1000000000,
  },
  mg: {
    gram: 0.001,
    mg: 1,
    mcg: 1000,
    ng: 1000000,
  },
  mcg: {
    gram: 0.000001,
    mg: 0.001,
    mcg: 1,
    ng: 1000,
  },
  ng: {
    gram: 0.000000001,
    mg: 0.000001,
    mcg: 0.001,
    ng: 1,
  },
  mmol: {
    mmol: 1,
  },
  Unit: {
    Unit: 1,
    mUnit: 1000,
  },
  mUnit: {
    Unit: 0.001,
    mUnit: 1,
  },
  mEq: {
    mEq: 1,
  },
};

export const LOAD_BOLUS_UNITS_MAP = {
  [`${DrugUnitsEnum.MILLILITER} / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.MILLILITER,
  ],
  [`${DrugUnitsEnum.MILLILITER} / kg / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.MILLILITER,
    DrugUnitsEnum.MILLILITER + ' / kg',
  ],
  [`${DrugUnitsEnum.MILLILITER} / kg / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.MILLILITER,
    DrugUnitsEnum.MILLILITER + ' / kg',
  ],
  [`${DrugUnitsEnum.GRAM} / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.GRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.GRAM} / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.GRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.MILLIGRAM} / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.GRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
  ],
  [`${DrugUnitsEnum.MILLIGRAM} / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.GRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.MILLIGRAM} / kg / ${TimeEnum.DAY}`]: [
    DrugUnitsEnum.GRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLIGRAM} / kg`,
    `${DrugUnitsEnum.MICROGRAM} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.MILLIGRAM} / kg / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.GRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLIGRAM} / kg`,
    `${DrugUnitsEnum.MICROGRAM} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.MILLIGRAM} / kg / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.GRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLIGRAM} / kg`,
    `${DrugUnitsEnum.MICROGRAM} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],

  [`${DrugUnitsEnum.MICROGRAM} / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MILLILITER,
  ],
  [`${DrugUnitsEnum.MICROGRAM} / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.MICROGRAM} / kg / ${TimeEnum.DAY}`]: [
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLIGRAM} / kg`,
    `${DrugUnitsEnum.MICROGRAM} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.MICROGRAM} / kg / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLIGRAM} / kg`,
    `${DrugUnitsEnum.MICROGRAM} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.MICROGRAM} / kg / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.MILLIGRAM,
    DrugUnitsEnum.MICROGRAM,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLIGRAM} / kg`,
    `${DrugUnitsEnum.MICROGRAM} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],

  [`${DrugUnitsEnum.NANOGRAM} / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.NANOGRAM,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.NANOGRAM} / kg / ${TimeEnum.DAY}`]: [
    DrugUnitsEnum.NANOGRAM,
    DrugUnitsEnum.MILLILITER,
  ],
  [`${DrugUnitsEnum.NANOGRAM} / kg / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.NANOGRAM,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.UNIT} / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.UNIT,
    DrugUnitsEnum.MILLIUNIT,
    DrugUnitsEnum.MILLILITER,
  ],
  [`${DrugUnitsEnum.UNIT} / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.UNIT,
    DrugUnitsEnum.MILLIUNIT,
    DrugUnitsEnum.MILLILITER,
  ],
  [`${DrugUnitsEnum.MILLIUNIT} / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.UNIT,
    DrugUnitsEnum.MILLIUNIT,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.UNIT} / kg / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.UNIT,
    DrugUnitsEnum.MILLIUNIT,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.UNIT} / kg`,
    `${DrugUnitsEnum.MILLIUNIT} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.UNIT} / kg / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.UNIT,
    DrugUnitsEnum.MILLIUNIT,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.UNIT} / kg`,
    `${DrugUnitsEnum.MILLIUNIT} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.MILLIUNIT} / kg / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.UNIT,
    DrugUnitsEnum.MILLIUNIT,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.UNIT} / kg`,
    `${DrugUnitsEnum.MILLIUNIT} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.MILLIUNIT} / kg / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.UNIT,
    DrugUnitsEnum.MILLIUNIT,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.UNIT} / kg`,
    `${DrugUnitsEnum.MILLIUNIT} / kg`,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],

  [`${DrugUnitsEnum.MILLIEQUIVALENT} / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.MILLIEQUIVALENT,
    DrugUnitsEnum.MILLILITER,
  ],
  [`${DrugUnitsEnum.MILLIEQUIVALENT} / ${TimeEnum.MINUTE}`]: [
    DrugUnitsEnum.MILLIEQUIVALENT,
    DrugUnitsEnum.MILLILITER,
  ],

  [`${DrugUnitsEnum.MILLIEQUIVALENT} / kg / ${TimeEnum.DAY}`]: [
    DrugUnitsEnum.MILLIEQUIVALENT,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],
  [`${DrugUnitsEnum.MILLIEQUIVALENT} / kg / ${TimeEnum.HOUR}`]: [
    DrugUnitsEnum.MILLIEQUIVALENT,
    DrugUnitsEnum.MILLILITER,
    `${DrugUnitsEnum.MILLILITER} / kg`,
  ],

  [`${DrugUnitsEnum.MILLIMOLE} / ${TimeEnum.HOUR}`]: [],
  [`${DrugUnitsEnum.MILLIMOLE} / kg / ${TimeEnum.DAY}`]: [],
  [`${DrugUnitsEnum.MILLIMOLE} / kg / ${TimeEnum.HOUR}`]: [],
};

export const LOAD_BOLUS_UNITS_LIMITS = {
  mL: {
    nonWeight: {
      min: 0.1,
      max: 1000,
    },
    weight: {
      min: 0.1,
      max: 99,
    },
  },
  gram: {
    nonWeight: {
      min: 0.1,
      max: 999,
    },
    weight: {
      min: 0,
      max: 0,
    },
  },
  mg: {
    nonWeight: {
      min: 0.01,
      max: 9999,
    },
    weight: {
      min: 0.01,
      max: 9999,
    },
  },
  mcg: {
    nonWeight: {
      min: 0.01,
      max: 9999,
    },
    weight: {
      min: 0.01,
      max: 9999,
    },
  },
  ng: {
    nonWeight: {
      min: 0.01,
      max: 9999,
    },
    weight: {
      min: 0,
      max: 0,
    },
  },
  Unit: {
    nonWeight: {
      min: 0.01,
      max: 10000,
    },
    weight: {
      min: 0.01,
      max: 9999,
    },
  },
  mUnit: {
    nonWeight: {
      min: 0.01,
      max: 9999,
    },
    weight: {
      min: 0.01,
      max: 9999,
    },
  },
  mEq: {
    nonWeight: {
      min: 0.1,
      max: 999,
    },
    weight: {
      min: 0.01,
      max: 999,
    },
  },
  mmol: {
    nonWeight: {
      min: 0.1,
      max: 999,
    },
    weight: {
      min: 0.1,
      max: 999,
    },
  },
};
