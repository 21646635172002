import { useTranslation } from 'react-i18next';
import packageJson from '../../../package.json';
import { FiChevronRight } from 'react-icons/fi';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import HelpService from 'services/HelpService';
import toast from 'react-hot-toast';
import axios from 'axios';

export const Help = () => {
  const { t } = useTranslation();
  const today = dayjs();

  const { isLoading, isFetching, error, data, isError } = useQuery({
    queryKey: [ReactQueryKeys.HELP],
    queryFn: HelpService.getHelpInfo,
  });

  if (isError) {
    toast.error(
      axios.isAxiosError(error)
        ? error?.response?.data?.message
        : t('errors.default'),
    );
  }

  return (
    <>
      <h4 className="pb-5">{t('help.title')} </h4>
      <div className="main-container with-tabs">
        <ul className="list-unstyled d-flex flex-column gap-3">
          <li className="d-flex">
            <b className="flex-fill pe-3">{t('help.name')}</b>
            {(isLoading || isFetching) && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </li>
          <li>
            {t('help.version')} FE {packageJson.version} / BE{' '}
            {data?.data?.['drug-library-be'].version}
          </li>
          <li>&nbsp;</li>
          <li>PN 10003</li>
          <li>{t('help.UDI')} 1234567890</li>
          <li>
            {t('help.copyright')} {today.format('YYYY')}
          </li>
          <li>
            <a
              href="https://druglibrary-public-docs.s3.us-east-2.amazonaws.com/AMGIS+Drug+Library+User+Manual.pdf"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-reset help-link"
            >
              <span> {t('help.userManual')}</span>
              <FiChevronRight size={26} />
            </a>
          </li>
          {/* <li>
            <a
              href="#"
              className="text-decoration-none text-reset help-link"
            >
              <span>{t('help.faq')}</span> <FiChevronRight size={26} />
            </a>
          </li> */}
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:techsupport@amgis.us?subject=Amgis Technical Support"
              className="text-decoration-none text-reset help-link"
            >
              <span>{t('help.contactSupport')}</span>
              <FiChevronRight size={26} />
            </a>
          </li>
          <li>
            <a
              href="https://www.termsfeed.com/live/54e2c6e1-1d51-4c8c-9a52-e81a8efcccf2"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-reset help-link"
            >
              <span> {t('help.privacyPolicy')}</span>
              <FiChevronRight size={30} />
            </a>
          </li>
          <li>
            <a
              href="https://druglibrary-public-docs.s3.us-east-2.amazonaws.com/Drug+Library+Application+EULA.pdf"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-reset help-link"
            >
              <span>{t('help.licenseAgreement')} </span>
              <FiChevronRight size={26} />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
