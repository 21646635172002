import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';
import { Header } from '@tanstack/react-table';
import { Deployment } from 'interfaces/deployment';
import { useTranslation } from 'react-i18next';
import './DateRangeInput.scss';

type TypeMyOnchange = true;
type onChangeFn = ReactDatePickerProps<never, TypeMyOnchange>['onChange'];
type DateRangeInputType = Parameters<onChangeFn>[0];

const DateRangeInput = ({
  header,
}: {
  header: Header<Deployment, unknown>;
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react/display-name
  const DatePickerCustomInput = forwardRef<any, any>(
    // eslint-disable-next-line react/prop-types
    ({ value, onClick }, ref) => (
      <>
        <input
          className={'form-control form-control-sm rounded'}
          onClick={onClick}
          readOnly
          ref={ref}
          defaultValue={value}
          placeholder={t(`deployment.table.placeholder_${header.column.id}`)}
          data-tooltip-content={value}
          data-tooltip-id={'datepicker_tooltip'}
          style={{ fontSize: '.875rem' }}
        />
        <ReactTooltip
          place="bottom"
          className="toolTip"
          id="datepicker_tooltip"
        />
      </>
    ),
  );

  const dates: DateRangeInputType =
    (header.column.getFilterValue() as DateRangeInputType) ?? [null, null];

  const onChange: onChangeFn = (dates) => {
    header.column.setFilterValue(dates);
  };

  return (
    <DatePicker<never, TypeMyOnchange>
      selectsRange
      selected={dates[0]}
      onChange={onChange}
      startDate={dates[0]}
      endDate={dates[1]}
      customInput={<DatePickerCustomInput />}
      isClearable={true}
      maxDate={new Date()}
    />
  );
};

export default DateRangeInput;
