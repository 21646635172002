import { UserCredentials } from 'interfaces/deployment';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CredentialsFieldProps<T extends UserCredentials> = UseFormReturn<T>;

const CredentialsFields = <Y extends UserCredentials>({
  formMethods,
  isModal,
  disabled = false,
}: {
  formMethods: CredentialsFieldProps<Y>;
  isModal?: boolean;
  disabled?: boolean;
}) => {
  const password: keyof Pick<Y, 'password'> = 'password';
  const username: keyof Pick<Y, 'username'> = 'username';

  const {
    register,
    formState: { errors },
  } = formMethods;

  const { t } = useTranslation();

  const usernameHTML = (
    <div className="pb-3">
      <input
        className="form-control form-control-lg rounded-1"
        placeholder="Username (email)"
        data-testid={username}
        autoComplete="new-username"
        // @ts-ignore
        {...register(username, {
          required: true,
        })}
        disabled={disabled}
      />
      {errors.username?.type === 'required' && (
        <div className="invalid-feedback w-100">
          {t('deployment.errors.missing__crendentials')}
        </div>
      )}
    </div>
  );

  const passwordHTML = (
    <div className="pb-3">
      <input
        className="form-control form-control-lg rounded-1 mb-2"
        type="password"
        data-testid={password}
        autoComplete="new-password"
        placeholder="Password"
        // @ts-ignore
        {...register(password, {
          required: true,
        })}
        disabled={disabled}
      />

      {errors.password?.type === 'required' && (
        <div className="invalid-feedback w-100">
          {t('deployment.errors.missing__crendentials')}
        </div>
      )}
    </div>
  );

  if (!isModal) {
    return (
      <div className="d-flex flex-column">
        {usernameHTML}
        {passwordHTML}
      </div>
    );
  }
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex flex-column w-100">{usernameHTML}</div>

      <div className="d-flex flex-column w-100">{passwordHTML}</div>
    </div>
  );
};

export default CredentialsFields;
