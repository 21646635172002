import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import DebouncedInput from 'components/UI/DebouncedInput/DebouncedInput';
import { ColumnFiltersState } from '@tanstack/react-table';
import ListPagination from 'components/UI/ListPagination/ListPagination';
import { Advisory } from 'interfaces/advisory';
import AdvisoryService from 'services/AdvisoryService';
import { Drug } from 'interfaces/drug';
import TextareaAutosize from 'react-textarea-autosize';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import DrugService from 'services/DrugService';
import { SweetAlertResult } from 'sweetalert2';
import { toast } from 'react-hot-toast';
import { ScreenEnum } from 'pages/Device/enums';

type ListAdvisoriesProps = {
  drug: Drug;
  updateDrug: Function;
  setScreen: Function;
};

const ListAdvisories: FC<ListAdvisoriesProps> = ({
  drug,
  updateDrug,
  setScreen,
}) => {
  const { t } = useTranslation();
  const PAGE_INDEX_DEFAULT_STATE = 0;
  const [page, setPage] = useState(PAGE_INDEX_DEFAULT_STATE);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState<string>('');
  const [listFilters, setListFilters] = useState<ColumnFiltersState>([]);
  const [selectedAdvisory, setSelectedAdvisory] = useState<null | Advisory>(
    null,
  );
  const messageWrapperRef = useRef<any | null>(null);
  const [messagePadding, setMessagePadding] = useState(0);
  const [messageHeight, setMessageHeight] = useState(0);

  useEffect(() => {
    if (drug.advisory_id) {
      setSelectedAdvisory({
        id: drug.advisory_id,
        name: drug.advisory_name,
        message: drug.advisory_message,
        facility_id: drug.advisory_facility_id,
        is_basic: drug.advisory_is_basic,
      });
    }
  }, [drug]);

  // Set initial message padding
  useEffect(() => {
    if (!messageWrapperRef?.current) return;

    const wrapperHeight = messageWrapperRef.current.offsetHeight;

    const heigth = messageHeight === 0 ? 40 : messageHeight;
    const padding = (1 - heigth / wrapperHeight) * wrapperHeight * 0.3;

    setMessagePadding(padding);
  }, [messageHeight]);

  const handleMessageHeightChange = (height: number) => {
    setMessageHeight(height);
  };

  const { data: listAdvisories } = useQuery(
    [ReactQueryKeys.ADVISORIES, page, listFilters, pageSize],
    () =>
      AdvisoryService.get({
        pageIndex: page,
        pageSize: pageSize,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
        columnFilters: listFilters,
      }),
    { keepPreviousData: true },
  );

  const updateFilter = (value: string) => {
    setListFilters([
      {
        id: 'name',
        value,
      },
    ]);
  };

  const handleAdvisoryClick = (advisory: Advisory) => {
    setSelectedAdvisory(advisory);
  };

  const handleCancelAdvisory = () => {
    setScreen(ScreenEnum.Overview);
  };
  /**
   * Assign advisory to the drug
   *
   * @param advisory Advisory
   */
  const handleAssignAdvisory = (advisory: Advisory) => {
    firePreConfirmAlert({
      focusCancelBtn: false,
      title: t('device.messages.modal__assign_advisiory__title'),
      html: t('device.messages.modal__assign_advisory__content', {
        name: advisory.name,
      }),
      preConfirm: () => {
        return DrugService.assignAdvisory(String(drug.id), {
          advisory_id: Number(advisory.id),
        })
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        updateDrug(drug.id);
        setScreen(ScreenEnum.Overview);
        toast.success(t('device.messages.assign_advisory__success'));
      }
    });
  };

  return (
    <div className="row" data-testid="advisories-screen">
      <div className="col-6">
        <h6>
          {t('device.messages.assign_advisory_title', {
            name: drug.name,
          })}
        </h6>
        <DebouncedInput
          placeholder={t('device.advisories_search_box')}
          value={search}
          type="text"
          onChange={(value) => {
            setSearch(value as string);
            updateFilter(value as string);

            //set pagination back to first page
            setPage(PAGE_INDEX_DEFAULT_STATE);
          }}
          className="form-control rounded mb-3 mt-4"
          data-testid="advisories-search-box"
        />

        {listAdvisories?.data?.records &&
          listAdvisories.data.records.length === 0 && (
            <div className="alert mb-0">{t('messages.no_records')}</div>
          )}

        {listAdvisories?.data?.records &&
          listAdvisories.data.records.length > 0 && (
            <div className="master-list device__advisories--full-list">
              {listAdvisories.data.records.map((advisory: Advisory) => {
                if (!drug.is_basic && advisory.is_basic) {
                  return null;
                }

                return (
                  <a
                    key={`drug_${advisory.id}`}
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAdvisoryClick(advisory);
                    }}
                    className={`master-list__item device-item ${
                      selectedAdvisory?.id === advisory.id ? 'active' : ''
                    }`}
                    data-testid={`advisory_${advisory.id}`}
                  >
                    {advisory.name}
                  </a>
                );
              })}
            </div>
          )}

        {/* Pagination */}
        {listAdvisories?.data && (
          <ListPagination
            canGetNextPage={
              Number(listAdvisories?.data.pageIndex) <
              Number(listAdvisories?.data.totalPages)
            }
            canGetPreviousPage={Number(listAdvisories?.data.pageIndex) > 0}
            nextPage={() => {
              setPage(page + 1);
            }}
            previousPage={() => {
              setPage(page - 1);
            }}
            pageCount={listAdvisories?.data.totalPages}
            pageIndex={page}
            pageSize={pageSize}
            setPageSize={(pageSize: number) => setPageSize(pageSize)}
            setPageIndex={(pageIndex: number) => setPage(pageIndex)}
            hidePageSize={true}
          />
        )}
      </div>
      <div className="col-6" style={{ paddingTop: '43px' }}>
        {selectedAdvisory ? (
          <div>
            <div className={`advisory-form__message-wrapper`}>
              <div className={`advisory-form__message-title`}>
                {selectedAdvisory?.is_basic
                  ? t('advisories.basic_mode_advisory')
                  : t('advisories.clinical_advisory')}
              </div>

              <div
                className="advisory-form__message-body"
                style={{
                  paddingTop: messagePadding,
                }}
                ref={messageWrapperRef}
              >
                <TextareaAutosize
                  className="advisories-form__message"
                  onChange={() => {}}
                  value={selectedAdvisory.message}
                  disabled={true}
                  onHeightChange={handleMessageHeightChange}
                />
              </div>
              <div className="advisory-form__message-buttons">
                <span className="advisory-form__message-buttons--back opacity-25">
                  {t('buttons.back').toUpperCase()}
                </span>
                <span className="advisory-form__message-buttons--ok opacity-25">
                  {t('buttons.ok')}
                </span>
              </div>
            </div>
            <div className="pt-4">
              <button
                className="btn btn-lg rounded btn-primary me-3"
                onClick={() => handleAssignAdvisory(selectedAdvisory)}
                data-testid={`handle-assign-advisory-button-test-id-${selectedAdvisory.id}`}
              >
                {t('buttons.save')}
              </button>
              <button
                className="btn btn-lg rounded btn-secondary"
                onClick={() => handleCancelAdvisory()}
                data-testid={`handle-cancel-advisory-button-test-id-${selectedAdvisory.id}`}
              >
                {t('buttons.cancel')}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <button
              className="btn btn-lg rounded btn-secondary"
              onClick={() => handleCancelAdvisory()}
              data-testid={
                'handle-cancel-advisory-button-test-id-no-selected-advisory'
              }
            >
              {t('buttons.cancel')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListAdvisories;
