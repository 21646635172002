import {
  ConcentrationTypesEnum,
  CONCENTRATION_TYPES_VALUES,
  DrugUnitsEnum,
  DRUG_UNITS_SORTING,
  InfusionTypesEnum,
  DOSE_MODE_UNITS_MAP,
  DOSE_MODE_WEIGHT_VALUES,
  DOSE_MODE_TYPES_VALUES,
  DoseModeTypeEnum,
  INFUSION_TYPES_VALUES,
} from '../constants/drugs';
import {
  Concentration,
  ConcentrationFormData,
} from '../interfaces/concentration';
import { DrugConcentration } from '../interfaces/drug-concentration';
import { Therapy } from '../interfaces/therapy';

export const calculateConcentration = (
  drugAmount?: number | string | null,
  volume?: number | string | null,
) => {
  // @ts-ignore
  if (!isNaN(drugAmount) && !isNaN(volume)) {
    drugAmount = Number(drugAmount);
    volume = Number(volume);
    if (drugAmount > 0) {
      return drugAmount / volume;
    }
  }

  return 0;
};

export const calculateConcentrationValue = (concentration: number) => {
  const floorValue = Math.floor(concentration);
  let result = 0;

  if (floorValue === 0) {
    result = Number(concentration.toFixed(3));
  } else if (floorValue >= 1 && floorValue < 10) {
    result = Number(concentration.toFixed(2));
  } else if (floorValue >= 10 && floorValue < 100) {
    result = Number(concentration.toFixed(1));
  } else if (floorValue >= 100) {
    result = Number(floorValue);
  }

  return result >= Infinity ? 0 : result;
};

/**
 * Calculate variable precision
 *
 * 0.001 (thousandth) for values between 0.001 - 0.999 (inclusive)
 * 0.01 (hundredth) for values between 1 - 9.99 (inclusive)
 * 0.1 (tenth) for values between 10 - 99.9 (inclusive)
 * 1 (Whole Number) for values between 100 - 9,999 (inclusive)
 *
 * @param amount Amount based on which the precision is calculated
 * @returns number
 */
export const calculateVariablePrecision = (amount?: string | null | number) => {
  if (
    amount === undefined ||
    amount === null ||
    amount === '' ||
    amount === '.'
  )
    return 3;

  const value = Number(amount);
  const floorValue = Math.floor(value);

  if (floorValue === 0) {
    return 3;
  } else if (floorValue >= 1 && floorValue < 10) {
    return 2;
  } else if (floorValue >= 10 && floorValue < 100) {
    return 1;
  } else if (floorValue >= 100) {
    return 0;
  }

  return 3;
};

/**
 * Validate if the entered number of decimal is valid
 *
 * @param value The value to be checked
 * @param allowedDecimals Number of allowed decimals
 * @returns
 */
export const validatePrecision = (value: any, allowedDecimals: number) => {
  const containsDot = String(value).includes('.');

  if (isNaN(value as any) || !containsDot) return true;

  const numDecimals = String(value).split('.')[1].length;

  if (containsDot && allowedDecimals === 0) return false;

  return numDecimals <= allowedDecimals;
};

/**
 * @description
 * Get calculated concentration
 * When type is "full" return calculation, together with amount and volume
 *
 * @param concentrationType Type of the concentration
 * @param drugUnit Drug Unit
 * @param drugAmount Drug Amount
 * @param volume Volume
 * @returns {string}
 */
export const getConcentration = (
  concentrationType?: string,
  drugUnit?: string,
  drugAmount?: number | string | null,
  volume?: number | string | null,
) => {
  let calculatedConcentration: string | number = '';

  switch (concentrationType) {
    case ConcentrationTypesEnum.FULL:
      if (!drugUnit) return '';

      // @ts-ignore
      if (!isNaN(drugAmount) && !isNaN(volume)) {
        drugAmount = Number(drugAmount);
        volume = Number(volume);
        if (drugAmount > 0) {
          calculatedConcentration = calculateConcentrationValue(
            drugAmount / volume,
          );
        }
      }

      return `${drugAmount} ${drugUnit} / ${volume} ${
        DrugUnitsEnum.MILLILITER
      } (${calculatedConcentration} ${drugUnit || ''} / ${
        DrugUnitsEnum.MILLILITER
      })`;

    case ConcentrationTypesEnum.UNITS_ONLY:
      if (!drugUnit) return '';
      return `_ ${drugUnit} / _ ${DrugUnitsEnum.MILLILITER}`;

    default:
      return CONCENTRATION_TYPES_VALUES[
        ConcentrationTypesEnum.MILLILITER_BASED
      ];
  }
};

/**
 * @description
 * Get calculated concentration
 * When type is "full" return just calculation, without displaying amount and volume
 *
 * @param concentrationType Type of the concentration
 * @param drugUnit Drug Unit
 * @param drugAmount Drug Amount
 * @param volume Volume
 * @returns {string}
 */
export const getCalculatedConcentration = (
  concentrationType?: string,
  drugUnit?: string,
  drugAmount?: number | string | null,
  volume?: number | string | null,
) => {
  let calculatedConcentration: string | number = '';

  switch (concentrationType) {
    case ConcentrationTypesEnum.FULL:
      if (!drugUnit) return '';

      // @ts-ignore
      if (!isNaN(drugAmount) && !isNaN(volume)) {
        drugAmount = Number(drugAmount);
        volume = Number(volume);
        if (drugAmount > 0) {
          calculatedConcentration = calculateConcentrationValue(
            drugAmount / volume,
          );
        }
      }

      return `${calculatedConcentration} ${drugUnit || ''} / ${
        DrugUnitsEnum.MILLILITER
      }`;

    case ConcentrationTypesEnum.UNITS_ONLY:
      if (!drugUnit) return '';
      return `_ ${drugUnit} / _ ${DrugUnitsEnum.MILLILITER}`;

    default:
      return CONCENTRATION_TYPES_VALUES[
        ConcentrationTypesEnum.MILLILITER_BASED
      ];
  }
};

/**
 * Sort concentrations
 *
 * @param {any[]} concentrations List of concentrations
 * @returns
 */
export const sortConcentrations = (concentrations: any[]) => {
  return concentrations.sort((a, b) => {
    if (a.type === b.type) {
      if (a.type === ConcentrationTypesEnum.FULL) {
        // Sort firt by drug unit
        if (a.drug_unit !== b.drug_unit) {
          return (
            DRUG_UNITS_SORTING[a.drug_unit as keyof typeof DRUG_UNITS_SORTING] -
            DRUG_UNITS_SORTING[b.drug_unit as keyof typeof DRUG_UNITS_SORTING]
          );
        }

        // If concentration type is full - sort descending Highest to Lowest
        // if equal - sort first record with bigger drug amount
        const bCalc = Number(b.drug_amount) / Number(b.volume);
        const aCalc = Number(a.drug_amount) / Number(a.volume);
        return bCalc === aCalc
          ? Number(b.drug_amount) - Number(a.drug_amount)
          : bCalc - aCalc;
      } else if (a.type === ConcentrationTypesEnum.UNITS_ONLY) {
        return (
          DRUG_UNITS_SORTING[a.drug_unit as keyof typeof DRUG_UNITS_SORTING] -
          DRUG_UNITS_SORTING[b.drug_unit as keyof typeof DRUG_UNITS_SORTING]
        );
      }
      return a.name.localeCompare(b.name, 'en', { numeric: true });
    }
    // When records have different concentration type, sort them this way: mL-based, full, Units Only
    if (a.type === ConcentrationTypesEnum.MILLILITER_BASED) return -10000;
    if (b.type === ConcentrationTypesEnum.MILLILITER_BASED) return 10000;
    if (a.type === ConcentrationTypesEnum.UNITS_ONLY) return 10000;
    if (b.type === ConcentrationTypesEnum.UNITS_ONLY) return -10000;

    return 1;
  });
};

// Sort grouped concentrations - Therapies
export const sortTherapies = (
  concentrations: DrugConcentration[],
  therapyLabel: string,
) => {
  return concentrations
    .sort((a, b) => a.calculated.localeCompare(b.calculated))
    .map((concentration: DrugConcentration, index: number) => {
      return {
        ...concentration,
        calculated: concentration.hasAssignedTherapy
          ? concentration.calculated
          : `${therapyLabel} #${index + 1}`,
      };
    });
};

/**
 * Get Drug Unit Values
 *
 * @param watchInfusionType
 * @param watchWeightBased
 * @param record Concentration
 * @returns Array
 */
export const getDrugUnitValues = (
  watchWeightBased: boolean,
  record: Concentration | null,
  watchInfusionType?: { label: string; value: string } | null,
) => {
  if (
    !record ||
    !watchInfusionType ||
    watchInfusionType?.value === InfusionTypesEnum.INTERMITTENT
  ) {
    return [];
  }

  if (record?.type === ConcentrationTypesEnum.MILLILITER_BASED) {
    if (watchWeightBased) {
      return Object.keys(
        DOSE_MODE_UNITS_MAP[ConcentrationTypesEnum.MILLILITER_BASED].weight,
      );
    } else {
      return Object.keys(
        DOSE_MODE_UNITS_MAP[ConcentrationTypesEnum.MILLILITER_BASED].nonWeight,
      );
    }
  } else {
    if (watchWeightBased) {
      return Object.keys(
        DOSE_MODE_UNITS_MAP[
          record?.drug_unit as keyof typeof DOSE_MODE_UNITS_MAP
        ].weight,
      );
    } else {
      return Object.keys(
        DOSE_MODE_UNITS_MAP[
          record?.drug_unit as keyof typeof DOSE_MODE_UNITS_MAP
        ].nonWeight,
      );
    }
  }
};

/**
 * Get Dose Mode Values
 *
 * @param watchInfusionType
 * @param watchWeightBased
 * @param record Concentration
 * @returns Array
 */
export const getDoseModeTimeValues = (
  watchWeightBased: boolean,
  record: Concentration | null,
  watchInfusionType?: { label: string; value: string } | null,
  watchDoseModeUnit?: { label: string; value: string } | null,
) => {
  if (
    !watchInfusionType ||
    !record ||
    !watchDoseModeUnit ||
    watchInfusionType?.value === InfusionTypesEnum.INTERMITTENT
  ) {
    return [];
  }

  if (record?.type === ConcentrationTypesEnum.MILLILITER_BASED) {
    if (watchWeightBased) {
      const mapping: { [key: string]: string[] } =
        DOSE_MODE_UNITS_MAP[ConcentrationTypesEnum.MILLILITER_BASED].weight;
      return mapping ? mapping[watchDoseModeUnit?.value] : [];
    } else {
      const mapping: { [key: string]: string[] } =
        DOSE_MODE_UNITS_MAP[ConcentrationTypesEnum.MILLILITER_BASED].nonWeight;
      return mapping ? mapping[watchDoseModeUnit?.value] : [];
    }
  } else {
    if (watchWeightBased) {
      const mapping: { [key: string]: string[] } =
        DOSE_MODE_UNITS_MAP[
          record?.drug_unit as keyof typeof DOSE_MODE_UNITS_MAP
        ].weight;
      return mapping ? mapping[watchDoseModeUnit?.value] : [];
    } else {
      const mapping: { [key: string]: string[] } =
        DOSE_MODE_UNITS_MAP[
          record?.drug_unit as keyof typeof DOSE_MODE_UNITS_MAP
        ].nonWeight;
      return mapping ? mapping[watchDoseModeUnit?.value] : [];
    }
  }
};

/**
 * Get Dose Mode Type Values
 *
 * @param watchInfusionType
 * @param record Concentration
 * @returns Array
 */
export const getDoseModeTypeValues = (
  watchInfusionType: any,
  record: Concentration | null,
) => {
  if (
    !watchInfusionType ||
    !record ||
    watchInfusionType?.value !== InfusionTypesEnum.INTERMITTENT
  ) {
    return [];
  }

  if (
    record?.type === ConcentrationTypesEnum.MILLILITER_BASED ||
    record?.drug_unit === DrugUnitsEnum.MILLIEQUIVALENT ||
    record?.drug_unit === DrugUnitsEnum.MILLIMOLE
  ) {
    return DOSE_MODE_WEIGHT_VALUES;
  }

  return DOSE_MODE_TYPES_VALUES;
};

/**
 * Get dose mode metric
 *
 * @param doseModeValueMetric non-weight, weight based, bsa based
 * @returns
 */
export const getDoseModeValueMetric = (doseModeValueMetric?: string) => {
  switch (doseModeValueMetric) {
    case DoseModeTypeEnum.WEIGHT_BASED:
      return 'kg';
    case DoseModeTypeEnum.BSA_BASED:
      return 'm²';
    default:
      return '';
  }
};

/**
 * Format data before is being send to update the concnetration
 *
 * @param record Concentration record
 * @param formData Form data that is submitted
 * @returns
 */
export const formatConcentrationPayload = (
  record: Concentration,
  formData: ConcentrationFormData,
) => {
  let therapyId;
  let infusionType;
  let doseModeUnit;
  let doseModeType;
  let doseModeTime;
  let weightBased;

  if (record.is_complete) {
    therapyId = record.therapy_id === null ? null : Number(record.therapy_id);
    // If the therapy is entered after
    if (record.therapy_required && therapyId === null) {
      therapyId = Number(formData?.therapy?.value);
    }
    infusionType = record.infusion_type;
    doseModeUnit = record.dose_mode_unit;
    doseModeType = record.dose_mode_type;
    doseModeTime = record.dose_mode_time;
    weightBased = !!record.weight_based;
  } else {
    therapyId = formData?.therapy?.value
      ? Number(formData?.therapy?.value)
      : null;
    infusionType = formData.infusion_type?.value;
    doseModeUnit = formData.dose_mode_unit?.value
      ? formData.dose_mode_unit?.value
      : null;
    doseModeType = formData.dose_mode_type?.value
      ? formData.dose_mode_type?.value
      : null;
    doseModeTime = formData.dose_mode_time?.value
      ? formData.dose_mode_time?.value
      : null;
    weightBased = formData.weight_based;
  }

  return {
    ...formData,
    is_active: true,
    is_complete: true,
    therapy_id: therapyId,
    is_complete_therapy: record.therapy_required
      ? therapyId
        ? true
        : false
      : true,
    infusion_type: infusionType,
    dose_mode_unit: doseModeUnit,
    dose_mode_type: doseModeType,
    dose_mode_time: doseModeTime,
    weight_based: weightBased,
    infuse_primary: formData.infuse_primary,
    allow_secondary:
      !isNaN(formData.allow_secondary as any) &&
      formData.allow_secondary !== null
        ? parseInt(formData.allow_secondary as any)
        : null,
    infuse_secondary: formData.infuse_secondary,

    // Concentration Limits
    concentration_lower_hard_limit: !isNaN(
      formData.concentration_lower_hard_limit as any,
    )
      ? parseFloat(formData.concentration_lower_hard_limit as any)
      : null,
    concentration_lower_soft_limit: !isNaN(
      formData.concentration_lower_soft_limit as any,
    )
      ? parseFloat(formData.concentration_lower_soft_limit as any)
      : null,
    concentration_upper_soft_limit: !isNaN(
      formData.concentration_upper_soft_limit as any,
    )
      ? parseFloat(formData.concentration_upper_soft_limit as any)
      : null,
    concentration_upper_hard_limit: !isNaN(
      formData.concentration_upper_hard_limit as any,
    )
      ? parseFloat(formData.concentration_upper_hard_limit as any)
      : null,
    allow_concentration_limits: !!formData.allow_concentration_limits,

    // Dose Rate Limits
    dose_rate_lower_hard_limit: !isNaN(
      formData.dose_rate_lower_hard_limit as any,
    )
      ? parseFloat(formData.dose_rate_lower_hard_limit as any)
      : null,
    dose_rate_lower_soft_limit: !isNaN(
      formData.dose_rate_lower_soft_limit as any,
    )
      ? parseFloat(formData.dose_rate_lower_soft_limit as any)
      : null,
    dose_rate_upper_soft_limit: !isNaN(
      formData.dose_rate_upper_soft_limit as any,
    )
      ? parseFloat(formData.dose_rate_upper_soft_limit as any)
      : null,
    dose_rate_upper_hard_limit: !isNaN(
      formData.dose_rate_upper_hard_limit as any,
    )
      ? parseFloat(formData.dose_rate_upper_hard_limit as any)
      : null,
    allow_load: !!formData.allow_load,

    // Load Amount Limits
    load_amount_lower_hard_limit: !isNaN(
      formData.load_amount_lower_hard_limit as any,
    )
      ? parseFloat(formData.load_amount_lower_hard_limit as any)
      : null,
    load_amount_lower_soft_limit: !isNaN(
      formData.load_amount_lower_soft_limit as any,
    )
      ? parseFloat(formData.load_amount_lower_soft_limit as any)
      : null,
    load_amount_upper_soft_limit: !isNaN(
      formData.load_amount_upper_soft_limit as any,
    )
      ? parseFloat(formData.load_amount_upper_soft_limit as any)
      : null,
    load_amount_upper_hard_limit: !isNaN(
      formData.load_amount_upper_hard_limit as any,
    )
      ? parseFloat(formData.load_amount_upper_hard_limit as any)
      : null,
    load_time_lower_hard_limit: !isNaN(
      formData.load_time_lower_hard_limit as any,
    )
      ? parseFloat(formData.load_time_lower_hard_limit as any)
      : null,
    load_time_lower_soft_limit: !isNaN(
      formData.load_time_lower_soft_limit as any,
    )
      ? parseFloat(formData.load_time_lower_soft_limit as any)
      : null,
    load_time_upper_soft_limit: !isNaN(
      formData.load_time_upper_soft_limit as any,
    )
      ? parseFloat(formData.load_time_upper_soft_limit as any)
      : null,
    load_time_upper_hard_limit: !isNaN(
      formData.load_time_upper_hard_limit as any,
    )
      ? parseFloat(formData.load_time_upper_hard_limit as any)
      : null,
    load_amount_units: formData.load_amount_units?.value
      ? formData.load_amount_units?.value
      : null,
    load_time_units: formData.load_time_units?.value
      ? formData.load_time_units?.value
      : null,

    // Bolus Limits
    allow_bolus: !!formData.allow_bolus,
    bolus_amount_lower_hard_limit: !isNaN(
      formData.bolus_amount_lower_hard_limit as any,
    )
      ? parseFloat(formData.bolus_amount_lower_hard_limit as any)
      : null,
    bolus_amount_lower_soft_limit: !isNaN(
      formData.bolus_amount_lower_soft_limit as any,
    )
      ? parseFloat(formData.bolus_amount_lower_soft_limit as any)
      : null,
    bolus_amount_upper_soft_limit: !isNaN(
      formData.bolus_amount_upper_soft_limit as any,
    )
      ? parseFloat(formData.bolus_amount_upper_soft_limit as any)
      : null,
    bolus_amount_upper_hard_limit: !isNaN(
      formData.bolus_amount_upper_hard_limit as any,
    )
      ? parseFloat(formData.bolus_amount_upper_hard_limit as any)
      : null,
    bolus_time_lower_hard_limit: !isNaN(
      formData.bolus_time_lower_hard_limit as any,
    )
      ? parseFloat(formData.bolus_time_lower_hard_limit as any)
      : null,
    bolus_time_lower_soft_limit: !isNaN(
      formData.bolus_time_lower_soft_limit as any,
    )
      ? parseFloat(formData.bolus_time_lower_soft_limit as any)
      : null,
    bolus_time_upper_soft_limit: !isNaN(
      formData.bolus_time_upper_soft_limit as any,
    )
      ? parseFloat(formData.bolus_time_upper_soft_limit as any)
      : null,
    bolus_time_upper_hard_limit: !isNaN(
      formData.bolus_time_upper_hard_limit as any,
    )
      ? parseFloat(formData.bolus_time_upper_hard_limit as any)
      : null,
    bolus_amount_units: formData.bolus_amount_units?.value
      ? formData.bolus_amount_units?.value
      : null,
    bolus_time_units: formData.bolus_time_units?.value
      ? formData.bolus_time_units?.value
      : null,
    // Allow Rapid Complete is null if it is not intermittent or basic
    ...(infusionType === InfusionTypesEnum.INTERMITTENT || record?.is_basic
      ? { allow_rapid_complete: formData.allow_rapid_complete === 'ON' }
      : { allow_rapid_complete: null }),
    //if infuse_until_empty is not null, we force it into a boolean
    ...(formData.infuse_until_empty === null
      ? { infuse_until_empty: null }
      : { infuse_until_empty: formData.infuse_until_empty === 'ON' }),
    delivery_pressure: formData.delivery_pressure === 'ON',

    // Drug Amount Limits
    drug_amount_lower_hard_limit: !isNaN(
      formData.drug_amount_lower_hard_limit as any,
    )
      ? parseFloat(formData.drug_amount_lower_hard_limit as any)
      : null,
    drug_amount_lower_soft_limit: !isNaN(
      formData.drug_amount_lower_soft_limit as any,
    )
      ? parseFloat(formData.drug_amount_lower_soft_limit as any)
      : null,
    drug_amount_upper_soft_limit: !isNaN(
      formData.drug_amount_upper_soft_limit as any,
    )
      ? parseFloat(formData.drug_amount_upper_soft_limit as any)
      : null,
    drug_amount_upper_hard_limit: !isNaN(
      formData.drug_amount_upper_hard_limit as any,
    )
      ? parseFloat(formData.drug_amount_upper_hard_limit as any)
      : null,

    // Dose Amount Limits
    dose_lower_hard_limit: !isNaN(formData.dose_lower_hard_limit as any)
      ? parseFloat(formData.dose_lower_hard_limit as any)
      : null,
    dose_lower_soft_limit: !isNaN(formData.dose_lower_soft_limit as any)
      ? parseFloat(formData.dose_lower_soft_limit as any)
      : null,
    dose_upper_soft_limit: !isNaN(formData.dose_upper_soft_limit as any)
      ? parseFloat(formData.dose_upper_soft_limit as any)
      : null,
    dose_upper_hard_limit: !isNaN(formData.dose_upper_hard_limit as any)
      ? parseFloat(formData.dose_upper_hard_limit as any)
      : null,

    max_rate_increase: !isNaN(formData.max_rate_increase as any)
      ? parseFloat(formData.max_rate_increase as any)
      : null,
  };
};

/**
 * Format data before is set as initial form data
 *
 * @param initialFormData
 * @param concentration
 * @param therapies
 * @returns
 */
export const formatConcentrationResult = (
  initialFormData: ConcentrationFormData,
  concentration: Concentration,
  therapies: Therapy[],
) => {
  const allowRapidCompleteObj: Pick<
    ConcentrationFormData,
    'allow_rapid_complete'
  > = {
    allow_rapid_complete:
      concentration.allow_rapid_complete === null ||
      !concentration.allow_rapid_complete
        ? 'OFF'
        : 'ON',
  };
  const infuseUntilEmptyObj: Pick<ConcentrationFormData, 'infuse_until_empty'> =
    {
      infuse_until_empty:
        concentration.infuse_until_empty === null
          ? null
          : concentration.infuse_until_empty
            ? 'ON'
            : 'OFF',
    };

  const deliveryPressure: Pick<ConcentrationFormData, 'delivery_pressure'> = {
    delivery_pressure:
      concentration.delivery_pressure === null ||
      !!concentration.delivery_pressure
        ? 'ON'
        : 'OFF',
  };

  const data = Object.assign(
    { ...initialFormData },
    {
      ...concentration,
      is_active: concentration.is_active,
      is_complete: concentration.is_complete,
      therapy: null,
      infusion_type: concentration.infusion_type
        ? {
            label:
              INFUSION_TYPES_VALUES[
                concentration.infusion_type as keyof typeof INFUSION_TYPES_VALUES
              ],
            value: concentration.infusion_type,
          }
        : null,
      dose_mode_unit: concentration.dose_mode_unit
        ? {
            label: concentration.dose_mode_unit,
            value: concentration.dose_mode_unit,
          }
        : null,
      dose_mode_time: concentration.dose_mode_time
        ? {
            label: concentration.dose_mode_time,
            value: concentration.dose_mode_time,
          }
        : null,
      dose_mode_type: concentration.dose_mode_type
        ? {
            label: concentration.dose_mode_type,
            value: concentration.dose_mode_type,
          }
        : null,
      weight_based: !!concentration.weight_based,
      infuse_primary: !!concentration.infuse_primary,
      infuse_secondary: !!concentration.infuse_secondary,
      allow_secondary: !isNaN(concentration.allow_secondary as any)
        ? String(concentration.allow_secondary)
        : null,
      allow_load: !!concentration.allow_load,
      load_amount_units: concentration.load_amount_units
        ? {
            label: concentration.load_amount_units,
            value: concentration.load_amount_units,
          }
        : null,
      load_time_units: concentration.load_time_units
        ? {
            label: concentration.load_time_units,
            value: concentration.load_time_units,
          }
        : null,
      allow_bolus: !!concentration.allow_bolus,
      bolus_amount_units: concentration.bolus_amount_units
        ? {
            label: concentration.bolus_amount_units,
            value: concentration.bolus_amount_units,
          }
        : null,
      bolus_time_units: concentration.bolus_time_units
        ? {
            label: concentration.bolus_time_units,
            value: concentration.bolus_time_units,
          }
        : null,

      ...allowRapidCompleteObj,

      ...infuseUntilEmptyObj,

      ...deliveryPressure,

      duration_lower_hard_limit: concentration.duration_lower_hard_limit
        ? concentration.duration_lower_hard_limit
        : '',
      duration_lower_soft_limit: concentration.duration_lower_soft_limit
        ? concentration.duration_lower_soft_limit
        : '',
      duration_upper_soft_limit: concentration.duration_upper_soft_limit
        ? concentration.duration_upper_soft_limit
        : '',
      duration_upper_hard_limit: concentration.duration_upper_hard_limit
        ? concentration.duration_upper_hard_limit
        : '',

      dose_lower_hard_limit: concentration.dose_lower_hard_limit
        ? concentration.dose_lower_hard_limit
        : '',
      dose_lower_soft_limit: concentration.dose_lower_soft_limit
        ? concentration.dose_lower_soft_limit
        : '',
      dose_upper_soft_limit: concentration.dose_upper_soft_limit
        ? concentration.dose_upper_soft_limit
        : '',
      dose_upper_hard_limit: concentration.dose_upper_hard_limit
        ? concentration.dose_upper_hard_limit
        : '',
    },
  );

  // If there is assigned therapy find it and assign it to the form
  if (concentration.therapy_required && concentration.therapy_id) {
    const chosenTherapy = therapies.find(
      (therapy) => String(therapy.id) === String(concentration.therapy_id),
    );
    if (chosenTherapy) {
      data.therapy = {
        label: chosenTherapy.name,
        value: chosenTherapy.id,
      } as any;
    }
  }

  return data;
};
