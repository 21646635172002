import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import DeploymentHistory from './DeploymentHistory/DeploymentHistory';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import {
  FRONT_ROUTER,
  DEPLOYMENT_ALL_PREFIX,
  DEPLOYMENT_TAB_PARAMS_KEY,
  DEPLOYMENT_PARTIAL_PREFIX,
} from 'constants/router';
import DeploymentService, {
  CanDeployApiResponse,
} from 'services/DeploymentService';
import DeploymentForm from './DeploymentForm/DeploymentForm';
import PartialDeploymentTab from './PartialDeployment/PartialDeployment';
import { useState } from 'react';
import Spinner from 'components/UI/Spinner/Spinner';
import CanNotDeploy from './CanNotDeploy';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { ReactQueryKeys } from 'constants/react-query-keys';

export const links = [
  {
    title: 'deployment.tab_all_devices',
    url: FRONT_ROUTER.DEPLOYMENT_FULL_DEPLOYMENT_TAB,
  },
  {
    title: 'deployment.tab_selected_device',
    url: FRONT_ROUTER.DEPLOYMENT_PARTIAL_DEPLOYMENT_TAB,
  },
];

const Deployment = () => {
  const { t } = useTranslation();
  const [canDeploy, setCanDeploy] = useState(false);
  const [
    libraryHasNotChangedSinceLastDeployment,
    setLibraryHasNotChangedSinceLastDeployment,
  ] = useState(false);
  const [canNotDeployReasons, setCanNotDeployReasons] = useState<
    CanDeployApiResponse['reasons']
  >({
    panelLockPasscode: false,
    advancedSettingsPasscode: false,
    incompleteDrugs: false,
    incompleteCareArea: false,
  });

  const { [DEPLOYMENT_TAB_PARAMS_KEY]: deploymentTab } = useParams();

  const { isLoading: canDeployLoading, isFetching } = useQuery(
    [
      ReactQueryKeys.CAN_DEPLOY,
      {
        deploymentTab,
      },
    ],
    () => DeploymentService.canDeploy(deploymentTab),
    {
      onSuccess: (res) => {
        setCanDeploy(res.data.status);
        setLibraryHasNotChangedSinceLastDeployment(
          res.data.libraryHasNotChangedSinceLastDeployment,
        );
        setCanNotDeployReasons(res.data.reasons);
      },
      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
      keepPreviousData: true,
    },
  );

  return (
    <div>
      <h4 data-testid="deployment-page">{t('deployment.title')}</h4>
      <div className="d-flex gap-3 pt-5">
        <section className="flex-fill master-list__full-screen master-list">
          <ContentHeader links={links} />
          <div className="color-bg px-4 h-100 position-relative">
            <>
              {canDeployLoading || isFetching ? <Spinner isAbsolute /> : null}

              {deploymentTab === DEPLOYMENT_ALL_PREFIX ? (
                canDeployLoading || isFetching ? (
                  <Spinner isAbsolute />
                ) : canDeploy ? (
                  <DeploymentForm
                    libraryHasNotChangedSinceLastDeployment={
                      libraryHasNotChangedSinceLastDeployment
                    }
                  />
                ) : (
                  <CanNotDeploy reasons={canNotDeployReasons} />
                )
              ) : deploymentTab === DEPLOYMENT_PARTIAL_PREFIX ? (
                canDeployLoading || isFetching ? (
                  <Spinner isAbsolute />
                ) : canDeploy ? (
                  <PartialDeploymentTab
                    libraryHasNotChangedSinceLastDeployment={
                      libraryHasNotChangedSinceLastDeployment
                    }
                  />
                ) : (
                  <CanNotDeploy reasons={canNotDeployReasons} />
                )
              ) : (
                <>{<Navigate to={FRONT_ROUTER.ERROR} />}</>
              )}
            </>
            <Outlet />
          </div>
        </section>
        <DeploymentHistory />
      </div>
    </div>
  );
};

export default Deployment;
