import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import { overlayActions } from 'store/slices/overlay';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateTypeLiterals, UserCredentials } from 'interfaces/deployment';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import DeploymentService from 'services/DeploymentService';
import { ReactQueryKeys } from 'constants/react-query-keys';
import {
  FRONT_ROUTER,
  DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY,
} from 'constants/router';
import { PARTIAL_UPDATE_TYPE } from 'constants/deployment';
import Button from 'components/UI/Button/Button';
import { userSelector } from 'store/selectors';
import CredentialsFields from '../CredentialsFields/CredentialsFields';

type ParamsWithUpdateType = {
  [DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY]: UpdateTypeLiterals;
};

const UpdatePartialDeploymentModal = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const initialState: UserCredentials = {
    password: '',
    username: user?.email || '',
  };

  const formMethods = useForm<UserCredentials>({
    defaultValues: initialState,
  });
  const { handleSubmit, reset } = formMethods;

  const location = useLocation();
  const backUrl =
    (location.state as string) ?? FRONT_ROUTER.DEPLOYMENT_FULL_DEPLOYMENT_TAB;

  const navigate = useNavigate();

  const { [DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY]: updateType } =
    useParams<ParamsWithUpdateType>();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    (data: UserCredentials) =>
      DeploymentService.updatePartialDeployment(
        data,
        updateType ?? 'update-to-full-library',
      ),
    {
      onSuccess: () => {
        toast.success(t('deployment.messages.deployment__success'));
        reset();
        queryClient.invalidateQueries([ReactQueryKeys.DEPLOYMENTS]);
        queryClient.invalidateQueries([ReactQueryKeys.PARTIAL_DEPLOYMENT]);
        queryClient.invalidateQueries([ReactQueryKeys.CAN_DEPLOY]);
        navigate(backUrl);
      },
      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
    },
  );

  useEffect(() => {
    dispatch(overlayActions.open({ path: backUrl }));
  }, [dispatch, backUrl]);

  const handleFormSubmit: SubmitHandler<UserCredentials> = (data) => {
    mutate(data);
  };

  if (!updateType || !Object.values(PARTIAL_UPDATE_TYPE).includes(updateType)) {
    return <Navigate to={FRONT_ROUTER.ERROR} />;
  }

  return (
    <form
      id="formid"
      autoComplete="off"
      className="form-fixed-width"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className="modal-sidebar-body">
        <CredentialsFields formMethods={formMethods} isModal />
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={isLoading}
          defaultLabel={
            updateType === PARTIAL_UPDATE_TYPE.REVERT
              ? t('buttons.save')
              : t('deployment.buttons.deploy_btn')
          }
          loadingLabel={t('buttons.updating')}
          type="submit"
          disabled={isLoading}
          data-testid="update_partial_deploymet_submit_btn"
        ></Button>
        <Link
          to={backUrl}
          className={`btn btn-lg rounded btn-secondary ${
            isLoading ? 'disabled' : ''
          }`}
        >
          {t('buttons.cancel')}
        </Link>
      </div>
    </form>
  );
};

export default UpdatePartialDeploymentModal;
