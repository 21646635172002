export enum AmplifyAuthenticatorEnum {
  SETUP = 'setup',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  CONFIRM_SIGN_IN = 'confirmSignIn',
  CONFIRM_SIGN_UP = 'confirmSignUp',
  SETUP_TOTP = 'setupTOTP',
  FORCE_NEW_PASSWORD = 'forceNewPassword',
  RESET_PASSWORD = 'resetPassword',
  CONFIRM_RESET_PASSWORD = 'confirmResetPassword',
  VERIFY_USER = 'verifyUser',
  CONFIRM_VERIFY_USER = 'confirmVerifyUser',
  SIGN_OUT = 'signOut',
  AUTHENTICATED = 'authenticated',
}
