import React, { useEffect, useRef, useState } from 'react';
import './AdvisoryForm.scss';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import {
  Link,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Button from 'components/UI/Button/Button';
import {
  IoIosAdd,
  IoIosCreate,
  IoIosList,
  IoMdHelpCircle,
  IoMdSync,
  IoMdTrash,
} from 'react-icons/io';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Spinner from 'components/UI/Spinner/Spinner';
import intersection from 'lodash.intersection';
import { ADMINISTRATION, AUTHOR } from 'constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { usePrompt } from 'hooks/useBlocker';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { useTranslation } from 'react-i18next';
import AdvisoryService from 'services/AdvisoryService';
import { advisoryActions } from 'store/slices/advisory';
import TextareaAutosize from 'react-textarea-autosize';
import { Advisory } from 'interfaces/advisory';
import { BasicModeAdvisoryReason } from 'interfaces/basic-mode-advisory-reason';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import { SweetAlertResult } from 'sweetalert2';
import { selectUser } from 'store/slices/auth';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';

const sortReasons = (
  reasons: BasicModeAdvisoryReason[],
): BasicModeAdvisoryReason[] => {
  return reasons.sort((a, b) => {
    if (!a.can_modify) return 10000;
    if (!b.can_modify) return -10000;
    return a.reason.localeCompare(b.reason, 'en');
  });
};

export type FormData = {
  id?: string | number;
  name: string;
  message: string;
  is_basic?: boolean;
};

const initialFormData: FormData = {
  name: '',
  message: '',
  is_basic: false,
};

const AdvisoryForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;
  const messageWrapperRef = useRef<any | null>(null);

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [record, setRecord] = useState<Advisory>({} as Advisory);
  const [forceNavigate, setForceNavigate] = useState(false);
  const [searchParams] = useSearchParams();
  const [editing, setEditing] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [messagePadding, setMessagePadding] = useState(0);
  const [messageHeight, setMessageHeight] = useState(0);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setFocus,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });

  usePrompt(
    t('messages.unsaved_changes'),
    isDirty && !forceNavigate && !formSubmitting,
  );

  const featchData = (
    id: string | number | undefined,
    controller?: AbortController,
  ) => {
    if (id) {
      setEditing(true);
      setFormLoading(true);
      AdvisoryService.find(
        id,
        controller ? { signal: controller.signal } : undefined,
      )
        .then(({ data }) => {
          setRecord({
            ...data,
            basic_mode_advisory_reasons: Array.isArray(
              data.basic_mode_advisory_reasons,
            )
              ? sortReasons(data.basic_mode_advisory_reasons)
              : [],
          });
          reset(
            Object.assign(
              { ...initialFormData },
              {
                name: data.name,
                message: data.message,
                is_basic: data.is_basic,
              },
            ),
          );
          setEditingName(false);
          setForceNavigate(false);
        })
        .catch((error: any) => {
          //if api call aborted do nothing
          if (error?.name === 'CanceledError') {
            return;
          }

          setForceNavigate(true);
          toast.error(t('advisories.messages.loading__error'));
          setTimeout(() => {
            navigate('/advisories');
          }, 100);
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      setEditing(false);
      const name = searchParams.get('name') || '';
      setRecord({} as Advisory);
      reset({ ...initialFormData });
      setValue('name', name, { shouldDirty: true });
      setForceNavigate(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    featchData(params.id, controller);

    return () => {
      controller.abort();
    };
  }, [params, searchParams]);

  /**
   * Submit form and create advisory
   *
   * @param formData Advisory data
   */

  const handleSubmitData = async (formData: FormData) => {
    setFormSubmitting(true);

    if (params.id) {
      try {
        let { data: advisory } = await AdvisoryService.update(params.id, {
          ...formData,
          name: formData.name.trim(),
        });

        advisory = {
          ...advisory,
          basic_mode_advisory_reasons: Array.isArray(
            advisory.basic_mode_advisory_reasons,
          )
            ? sortReasons(advisory.basic_mode_advisory_reasons)
            : [],
        };

        setFormSubmitting(false);
        setRecord(advisory);
        reset(advisory);
        setEditingName(false);
        queryClient.invalidateQueries([ReactQueryKeys.ADVISORIES]);
        toast.success(t('advisories.messages.update__success'));
      } catch (e: any) {
        let message = t('advisories.messages.update__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setFormSubmitting(false);
      }
    } else {
      try {
        setForceNavigate(true);
        const { data: advisory } = await AdvisoryService.create({
          ...formData,
          name: formData.name.trim(),
        });
        setFormSubmitting(false);
        dispatch(advisoryActions.clearSearch());
        queryClient.invalidateQueries([ReactQueryKeys.ADVISORIES]);
        toast.success(t('advisories.messages.create__success'));
        navigate(`/advisories/${advisory.id}`);
      } catch (e: any) {
        let message = t('advisories.messages.create__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setForceNavigate(false);
        setFormSubmitting(false);
      }
    }
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    if (params.id) {
      reset({ ...record });
    } else {
      reset({ ...initialFormData });
    }
  };

  const handleMessageBodyClick = () => {
    setFocus('message');
  };

  // Show alert for confirming of deletion a record
  const handleDeleteReason = (reason: BasicModeAdvisoryReason) => {
    firePreConfirmAlert({
      title: t('advisories.messages.modal__unassign__reason_title'),
      html: t('advisories.messages.modal__unassign__reason_content', {
        name: reason.reason,
      }),
      preConfirm: () => {
        return AdvisoryService.removeReason(
          String(record?.id),
          String(reason.id),
        )
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([
          ReactQueryKeys.ADVISORY_BASIC_MODE_REASONS,
        ]);
        if (params.id) {
          featchData(params.id);
        }
        toast.success(
          t(
            'advisories.messages.basic_mode_advisory_reason__unassugn__success',
          ),
        );
      }
    });
  };

  const LINE_HEIGHT = 40;
  const MAX_LINES = 10;
  const messageValueRef = watch('message');
  const [oldMessageValue, setOldMessageValue] = useState(messageValueRef);

  // Set initial message padding
  useEffect(() => {
    if (!messageWrapperRef?.current) return;

    const wrapperHeight = messageWrapperRef.current.offsetHeight;

    const heigth = messageHeight === 0 ? LINE_HEIGHT : messageHeight;
    const padding = (1 - heigth / wrapperHeight) * wrapperHeight * 0.3;

    setMessagePadding(isNaN(padding) ? 0 : padding);
  }, [messageHeight]);

  //if message is over 10 lines we reset it to its old value
  useEffect(() => {
    if (messageHeight > LINE_HEIGHT * MAX_LINES) {
      return setValue('message', oldMessageValue, { shouldValidate: true });
    }
  }, [messageHeight, LINE_HEIGHT, setValue, oldMessageValue]);

  const handleMessageHeightChange = (height: number) => {
    setMessageHeight(height);
  };

  /**
   * Reset advisory message
   */
  const handleResetMessage = (e: React.MouseEvent) => {
    e.preventDefault();

    setValue('message', t('advisories.messages.basic_mode_advisory'), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {!editing && (
            <h3 className="mb-4">{t('advisories.labels.create')}</h3>
          )}

          <form
            style={{ maxWidth: '600px', position: 'relative' }}
            autoComplete="off"
            onSubmit={handleSubmit((data) => handleSubmitData(data))}
            className={formLoading || formSubmitting ? 'opacity-50' : ''}
            data-testid="form"
          >
            {formLoading && <Spinner isAbsolute />}
            <div className="pb-3">
              <label htmlFor="advisoryName" className="form-label">
                {t('advisories.name')} *
              </label>
              {!editing || (editing && editingName) ? (
                <input
                  disabled={!canManage}
                  type="text"
                  maxLength={20}
                  autoFocus
                  className="form-control form-control-lg"
                  id="advisoryName"
                  data-testid="name"
                  {...register('name', {
                    required: true,
                    maxLength: 20,
                    pattern: /^[a-zA-Z]+.*$/,
                    validate: {
                      noEmptySpaces: (value) =>
                        !!value.length && /^[^\s]+(\s+[^\s]+)*$/.test(value),
                    },
                  })}
                />
              ) : (
                <div>
                  <span className="h4">{getValues('name')}</span>
                  {!record.is_basic ? (
                    <>
                      <IoIosCreate
                        className="advisory-form__edit-title"
                        onClick={() => setEditingName(true)}
                        data-tooltip-id="advisory-edit-tooltip"
                        data-testid="edit-advisory-name-btn"
                        data-tooltip-content="Edit Advisory name"
                      />
                      <ReactTooltip id="advisory-edit-tooltip" place="top" />
                    </>
                  ) : null}
                </div>
              )}

              {errors?.name?.type === 'required' && (
                <div className="invalid-feedback pt-1">
                  {t('advisories.errors.name__required')}
                </div>
              )}
              {errors?.name?.type === 'maxLength' && (
                <div className="invalid-feedback pt-1">
                  {t('advisories.errors.name__max_characters')}
                </div>
              )}
              {errors?.name?.type === 'pattern' && (
                <div className="invalid-feedback pt-1">
                  {t('advisories.errors.name__start_alphabetic')}
                </div>
              )}
              {errors?.name?.type === 'noEmptySpaces' && (
                <div className="invalid-feedback pt-1">
                  {t('advisories.errors.name__no_empty_spaces')}
                </div>
              )}
            </div>

            <div className="pb-5 d-flex">
              <div>
                <div
                  className={`advisory-form__message-wrapper ${
                    record?.is_basic ? 'basic-mode-advisory' : ''
                  }`}
                >
                  <div
                    className={`advisory-form__message-title ${
                      record?.is_basic ? 'basic-mode-advisory' : ''
                    }`}
                  >
                    {record?.is_basic
                      ? t('advisories.basic_mode_advisory')
                      : t('advisories.clinical_advisory')}

                    <span className="advisories-form__message-tooltip-btns">
                      <span>
                        <IoMdHelpCircle
                          color="#fff"
                          size={36}
                          data-tooltip-id="advisories-message-tooltip"
                        />
                        <ReactTooltip
                          id="advisories-message-tooltip"
                          place="right"
                        >
                          Entered text will appear exactly as it will
                          <br /> be displayed on the device.
                        </ReactTooltip>
                      </span>
                      <span>
                        <IoIosCreate
                          onClick={handleMessageBodyClick}
                          color="#fff"
                          className="advisories-form__message-tooltip-btns--edit"
                          size={33}
                          data-tooltip-content={
                            record.is_basic
                              ? t(
                                  'advisories.messages.tooltip__edit_basic_advisory_message',
                                )
                              : t(
                                  'advisories.messages.tooltip__edit_advisory_message',
                                )
                          }
                          data-tooltip-id="edit-advisory-message-tooltip"
                        />
                        <ReactTooltip
                          id="edit-advisory-message-tooltip"
                          place="right"
                        />
                      </span>
                      {record.is_basic ? (
                        <span>
                          <a
                            href="/#"
                            className="advisories-form__message-manage btn-rounded"
                            data-tooltip-content={t('advisories.buttons.reset')}
                            data-tooltip-id="advisories-message-reset"
                            onClick={handleResetMessage}
                            data-testid="handleResetMessage"
                            id="reset-basicadvisory-btn"
                          >
                            <IoMdSync color="#fff" />
                          </a>
                          <ReactTooltip
                            id="advisories-message-reset"
                            place="right"
                          />
                        </span>
                      ) : null}
                    </span>
                  </div>

                  <div
                    className="advisory-form__message-body"
                    style={{
                      paddingTop: messagePadding,
                    }}
                    onClick={handleMessageBodyClick}
                    ref={messageWrapperRef}
                  >
                    <Controller
                      control={control}
                      name="message"
                      rules={{
                        required: true,
                        minLength: 1,
                        maxLength: 200,

                        validate: {
                          noEmptySpaces: (value) =>
                            !!value.length &&
                            /^[^\s]+(\s+[^\s]+)*$/.test(value),
                          maximumLines: (value) =>
                            !!value.length &&
                            value.split(/\r\n|\n\r|\n|\r/).length <= MAX_LINES,
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextareaAutosize
                          id="content"
                          className="advisories-form__message"
                          onBlur={onBlur}
                          ref={ref}
                          onChange={(e) => {
                            setOldMessageValue(messageValueRef);
                            onChange(e.target.value);
                          }}
                          value={value}
                          disabled={!canManage}
                          onHeightChange={handleMessageHeightChange}
                          data-testid="message"
                          placeholder={
                            params.id ? '' : t('advisories.placeholder')
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="advisory-form__message-buttons">
                    <span
                      className={`advisory-form__message-buttons--back opacity-25
                      `}
                    >
                      {t('buttons.back').toUpperCase()}
                    </span>
                    <span
                      className={`advisory-form__message-buttons--ok
                        opacity-25 
                      `}
                    >
                      {t('buttons.ok')}
                    </span>
                  </div>
                </div>

                {errors?.message?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('advisories.errors.message__required')}
                  </div>
                )}
                {errors?.message?.type === 'minLength' && (
                  <div className="invalid-feedback pt-1">
                    {t('advisories.errors.message__min_characters')}
                  </div>
                )}
                {errors?.message?.type === 'maxLength' && (
                  <div className="invalid-feedback pt-1">
                    {t('advisories.errors.message__max_characters')}
                  </div>
                )}
                {errors?.message?.type === 'noEmptySpaces' && (
                  <div className="invalid-feedback pt-1">
                    {t('advisories.errors.message__no_empty_spaces')}
                  </div>
                )}
                {errors?.message?.type === 'maximumLines' && (
                  <div className="invalid-feedback pt-1">
                    {t('advisories.errors.message__maximum_lines', {
                      count: MAX_LINES,
                    })}
                  </div>
                )}
              </div>

              {/* Basic Mode Advisory Reasons */}
              {record?.is_basic && (
                <div
                  style={{ marginLeft: '100px' }}
                  data-testid="basic_mode_advisory_reasons_section"
                >
                  <div className="advisory-form__message-wrapper basic-mode-advisory">
                    <div
                      className={`advisory-form__message-title basic-mode-advisory`}
                    >
                      {t('advisories.basic_mode_advisory')}
                      <span className="advisories-form__message-tooltip-btns ">
                        <span
                          data-tooltip-content={t(
                            'advisories.messages.tooltip__basic_advisory_reasons',
                          )}
                          data-tooltip-id="help-basic-advisory-reasons"
                        >
                          <IoMdHelpCircle color="#fff" size={36} />
                          <ReactTooltip
                            id="help-basic-advisory-reasons"
                            place="top"
                          />
                        </span>
                        <span>
                          <Link
                            to={`reasons/manage`}
                            className="btn-rounded"
                            id="manage-reasons-btn"
                            data-tooltip-content={t(
                              'advisories.buttons.manage_reasons',
                            )}
                            data-tooltip-id="advisories-reasons-manage"
                          >
                            <IoIosList color="#fff" size={23} />
                          </Link>
                          <ReactTooltip
                            id="advisories-reasons-manage"
                            place="left"
                          />
                        </span>
                      </span>
                    </div>

                    <div className="advisory-form__message-body">
                      <h2 className="text-center py-3">
                        {t('advisories.messages.reason_for_using_basic')}
                      </h2>

                      {!!record &&
                      !!record.basic_mode_advisory_reasons &&
                      canManage &&
                      Array.isArray(record.basic_mode_advisory_reasons) &&
                      record.basic_mode_advisory_reasons.length < 4 ? (
                        <Link
                          to={`reasons/add`}
                          className={`facility-form__basic-advisory-reason mx-4 mt-3 can-modify`}
                          data-testid="add_reason_to_basic_advisory_btn"
                        >
                          <IoIosAdd size={40} color="#ffffff" />
                        </Link>
                      ) : null}
                      {!!record.is_basic &&
                        Array.isArray(record.basic_mode_advisory_reasons) &&
                        record.basic_mode_advisory_reasons.map(
                          (
                            basicModeAdvisoryReason: BasicModeAdvisoryReason,
                          ) => {
                            return basicModeAdvisoryReason.can_modify ? (
                              <Link
                                key={basicModeAdvisoryReason.id}
                                to={`reasons/${basicModeAdvisoryReason.id}`}
                                className={`facility-form__basic-advisory-reason mx-4 mt-3 can-modify`}
                              >
                                {basicModeAdvisoryReason.reason}

                                {canManage && (
                                  <span
                                    className="facility-form__reason-delete"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDeleteReason(
                                        basicModeAdvisoryReason,
                                      );
                                    }}
                                    data-testid={`handleDeleteReason_${basicModeAdvisoryReason.reason}`}
                                  >
                                    <IoMdTrash size={30} />
                                  </span>
                                )}
                              </Link>
                            ) : (
                              <div
                                key={basicModeAdvisoryReason.id}
                                className="facility-form__basic-advisory-reason mx-4 mt-3"
                              >
                                {basicModeAdvisoryReason.reason}
                              </div>
                            );
                          },
                        )}
                    </div>
                    <div className="advisory-form__message-buttons">
                      <span className="advisory-form__message-buttons--back message-buttons--back-center opacity-25">
                        {t('buttons.back').toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {canManage ? (
              <>
                <Button
                  loading={formSubmitting}
                  defaultLabel={t('buttons.save')}
                  loadingLabel={
                    params?.id ? t('buttons.updating') : t('buttons.saving')
                  }
                  type="submit"
                  disabled={formSubmitting || formLoading || !isDirty}
                  data-testid="submitBtn"
                ></Button>

                <a
                  href="/#"
                  className={`btn btn-lg rounded btn-secondary ${
                    formSubmitting || formLoading || !isDirty ? 'disabled' : ''
                  }`}
                  onClick={handleCancel}
                  data-testid="handleCancelBtn"
                >
                  {t('buttons.cancel')}
                </a>
              </>
            ) : null}
          </form>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default AdvisoryForm;
