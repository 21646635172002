import { Facility } from 'interfaces/facility';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import FacilityService from 'services/FacilityService';
import { CARE_AREA_QUERY_PARAM, FACILITY_QUERY_PARAM } from 'constants/device';
import { useDispatch } from 'react-redux';
import { deviceActions } from 'store/slices/device';
import useBlockerExceptionalCases from 'hooks/useBlockerExceptionalCases';

type FacilitiesProps = {
  canManage: boolean;
};

// eslint-disable-next-line no-unused-vars
const Facilities: FC<FacilitiesProps> = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const handleBlock = useBlockerExceptionalCases();

  const { data: facilities } = useQuery(
    [ReactQueryKeys.FACILITIES],
    () =>
      FacilityService.get({
        pageIndex: 0,
        pageSize: 1000,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
      }),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  );

  const updateSelectedFacility = (facility: Facility) => {
    searchParams.set(FACILITY_QUERY_PARAM, String(facility.id));
    setSearchParams(searchParams);

    dispatch(
      deviceActions.updateSelectedFacility({
        facility,
      }),
    );
  };

  useEffect(() => {
    if (facilities?.data.records.length) {
      if (!searchParams.has(FACILITY_QUERY_PARAM)) {
        updateSelectedFacility(facilities.data.records[0]);
      } else {
        const selectedFacility = facilities.data.records.find(
          (facility: any) =>
            Number(facility.id) ===
            Number(searchParams.get(FACILITY_QUERY_PARAM)),
        );

        if (selectedFacility) {
          updateSelectedFacility(selectedFacility);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities, searchParams]);

  const handleFacilityClick = async (facility: Facility) => {
    const canWeContinue = await handleBlock(() => true);

    if (!canWeContinue) return;
    updateSelectedFacility(facility);

    searchParams.delete(CARE_AREA_QUERY_PARAM);
    setSearchParams(searchParams);
    dispatch(deviceActions.clearSelectedCareArea());
    dispatch(deviceActions.clearAddingCareArea());
    dispatch(deviceActions.clearSelectedDrug());
    dispatch(deviceActions.clearSelectedDrugConcentration());
  };

  return (
    <>
      <h4
        className="device__facility-title pb-1"
        data-testid="facility-component"
      >
        {t('device.facilities')}
      </h4>
      {facilities?.data?.records && facilities.data.records?.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {facilities?.data?.records && facilities.data.records?.length > 0 && (
        <div className="master-list device__facility--master-list">
          {facilities?.data.records.map((facility: Facility) => {
            return (
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  handleFacilityClick(facility);
                }}
                className={`master-list__item device-item ${
                  searchParams.get(FACILITY_QUERY_PARAM) === String(facility.id)
                    ? 'active'
                    : ''
                }`}
                key={facility.id}
              >
                {facility.name}
              </a>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Facilities;
