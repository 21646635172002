import type { FC } from 'react';
import { Table as ReactTable, flexRender } from '@tanstack/react-table';

type TableProps = {
  isFetching: boolean;
  table: ReactTable<any>;
  classes?: string;
  // eslint-disable-next-line no-unused-vars
  filters?: (header: any) => JSX.Element;
};

const getSortClass = (header: any) => {
  const ascDescClasses: { [key: string]: string } = {
    asc: 'dl-pagination__asc',
    desc: 'dl-pagination__desc',
  };

  return ascDescClasses[header.column.getIsSorted() as string] ?? '';
};

const Table: FC<TableProps> = ({ table, isFetching, filters, classes }) => {
  return (
    <div className={`table-wrapper ${classes ? classes : ''}`}>
      <table className={'table table-dark ' + (isFetching ? 'opacity-50' : '')}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const lastHeaderInTreeBranch = header.subHeaders.length === 0;

                return (
                  <th
                    key={header.id}
                    data-testid={
                      lastHeaderInTreeBranch && !header.isPlaceholder
                        ? `${header.id} columnHeader`
                        : ''
                    }
                    colSpan={header.colSpan}
                    className={(header.column.columnDef as any).className}
                  >
                    {header.isPlaceholder ? (
                      ''
                    ) : (
                      <>
                        <div
                          {...{
                            className:
                              (header.column.getCanSort()
                                ? 'table-header dl-pagination__header cursor-pointer select-none '
                                : '') + getSortClass(header),
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div className="p-2">
                            {filters && filters(header)}
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    key={cell.id}
                    className={(cell.column.columnDef as any).className}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
