import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmationButtonProps = {
  isValid: boolean;
  onClick: MouseEventHandler;
};

const ConfirmationButton: FC<ConfirmationButtonProps> = ({
  isValid,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-start pt-5">
      <a
        href="/#"
        className={`btn btn-lg rounded btn-primary ${
          isValid ? '' : 'disabled'
        }`}
        onClick={onClick}
        data-testid="confirm-button"
      >
        {t('buttons.confirm')}
      </a>
    </div>
  );
};

export default ConfirmationButton;
