import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  clearSearch: boolean;
}

const initialState: InitialState = {
  clearSearch: false,
};

const therapySlice = createSlice({
  name: 'therapy',
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.clearSearch = true;
    },
    resetClearSearch: (state) => {
      state.clearSearch = false;
    },
  },
});

export const therapyActions = therapySlice.actions;

export default therapySlice.reducer;
