import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CareArea } from '../../interfaces/care-area';
import { Drug } from '../../interfaces/drug';
import { DrugConcentration } from '../../interfaces/drug-concentration';
import { Facility } from '../../interfaces/facility';
import { RootState } from 'store';

interface InitialState {
  selectedFacility: null | Facility;
  selectedCareArea: null | CareArea;
  selectedDrug: null | Drug;
  selectedDrugConcentration: null | DrugConcentration;
  isAddingCareAreas: boolean;
  isAddingConcentration: boolean;
  expandedDrugs: number[]; // Drug ids
  expandedThrapies: number[]; // List Concentration ids
  isEditingStill: boolean;
  drugUnsavedChangesMessage: string;
  blockDoubleModal: boolean;
}

const initialState: InitialState = {
  selectedFacility: null,
  selectedCareArea: null,
  selectedDrug: null,
  selectedDrugConcentration: null,
  isAddingCareAreas: false,
  isAddingConcentration: false,
  expandedDrugs: [],
  expandedThrapies: [],
  isEditingStill: false,
  drugUnsavedChangesMessage: '',
  blockDoubleModal: false,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    updateSelectedFacility: (state, action) => {
      state.selectedFacility = action.payload.facility;
    },
    updateSelectedCareArea: (state, action) => {
      state.selectedCareArea = action.payload.careArea;
    },
    updateIsEditingStill: (
      state,
      action: PayloadAction<
        Pick<InitialState, 'isEditingStill' | 'drugUnsavedChangesMessage'>
      >,
    ) => {
      state.isEditingStill = action.payload.isEditingStill;
      state.drugUnsavedChangesMessage =
        action.payload.drugUnsavedChangesMessage;
    },
    updateBlockDoubleModal: (
      state,
      action: PayloadAction<InitialState['blockDoubleModal']>,
    ) => {
      state.blockDoubleModal = action.payload;
    },
    updateSelectedDrug: (state, action) => {
      state.selectedDrug = action.payload.drug;
      state.selectedDrugConcentration = null;
    },
    updateSelectedDrugConcentration: (state, action) => {
      state.selectedDrugConcentration = action.payload.concentration;
      state.selectedDrug = null;
    },
    clearSelectedFacility: (state) => {
      state.selectedFacility = null;
    },
    clearSelectedCareArea: (state) => {
      state.selectedCareArea = null;
    },
    clearSelectedDrugConcentration: (state) => {
      state.selectedDrugConcentration = null;
    },
    clearSelectedDrug: (state) => {
      state.selectedDrug = null;
    },
    setAddingCareArea: (state) => {
      state.isAddingCareAreas = true;
      state.isAddingConcentration = false;
    },
    clearAddingCareArea: (state) => {
      state.isAddingCareAreas = false;
    },
    setAddingConcentration: (state) => {
      state.isAddingConcentration = true;
      state.isAddingCareAreas = false;
    },
    clearAddingConcentration: (state) => {
      state.isAddingConcentration = false;
    },
    reset: (state) => {
      state.selectedFacility = null;
      state.selectedCareArea = null;
      state.selectedDrugConcentration = null;
      state.isAddingCareAreas = false;
      state.isAddingConcentration = false;
    },
    setExpandedDrug: (state, action) => {
      const id = Number(action.payload.id);

      state.expandedDrugs = [id];
    },
    updateExpandedDrugs: (state, action) => {
      const id = Number(action.payload.id);
      if (state.expandedDrugs.includes(id)) {
        state.expandedDrugs = state.expandedDrugs.filter(
          (drugId: number) => id !== drugId,
        );
      } else {
        state.expandedDrugs.push(id);
      }
    },
    clearExpandedDrugs: (state) => {
      state.expandedDrugs = [];
    },
    setExpandedTherapies: (state, action) => {
      const id = Number(action.payload.id);

      state.expandedThrapies = [id];
    },
    updateExpandedTherapies: (state, action) => {
      const id = Number(action.payload.id);
      if (state.expandedThrapies.includes(id)) {
        state.expandedThrapies = state.expandedThrapies.filter(
          (drugId: number) => id !== drugId,
        );
      } else {
        state.expandedThrapies.push(id);
      }
    },
    clearExpandedTherapies: (state) => {
      state.expandedThrapies = [];
    },
  },
});

// Selectors
export const selectSelectedDrugConcentration = (state: any) =>
  state.device.selectedDrugConcentration;
export const selectSelectedFacility = (state: any) =>
  state.device.selectedFacility;
export const selectSelectedCareArea = (state: any) =>
  state.device.selectedCareArea;
export const selectSelectedDrug = (state: any) => state.device.selectedDrug;
export const selectIsAddingCareAreas = (state: any) =>
  state.device.isAddingCareAreas;
export const selectIsAddingConcentration = (state: any) =>
  state.device.isAddingConcentration;
export const selectExpandedDrugs = (state: any) => state.device.expandedDrugs;
export const selectExpandedTherapies = (state: any) =>
  state.device.expandedThrapies;
export const selectIsEditingStill = (state: RootState) =>
  state.device.isEditingStill;
export const selectDrugUnsavedChangesMessage = (state: RootState) =>
  state.device.drugUnsavedChangesMessage;

export const deviceActions = deviceSlice.actions;

export default deviceSlice.reducer;
