import ContentHeader from '../../components/UI/ContentHeader/ContentHeader';
import { MASTER_LINKS } from '../../constants/master-list-tabs';
import DrugList from './DrugList/DrugList';
import DrugService from '../../services/DrugService';
import { ReactQueryKeys } from '../../constants/react-query-keys';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ColumnFiltersState, PaginationState } from '@tanstack/react-table';
import { SweetAlertResult } from 'sweetalert2';
import SwalAlert, {
  firePreConfirmAlert,
} from '../../components/UI/SwalAlert/SwalAlert';
import { toast } from 'react-hot-toast';
import { ADMINISTRATION, AUTHOR } from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import intersection from 'lodash.intersection';
import { useTranslation } from 'react-i18next';
import { ListDrug } from '../../interfaces/list-drug';
import Spinner from 'components/UI/Spinner/Spinner';
import HospitalTotalLabels from 'components/UI/HospitalTotalLabels/HospitalTotalLabels';
import { drugActions } from 'store/slices/drug';
import { selectUser } from 'store/slices/auth';

const Drugs = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const user = useSelector(selectUser);
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;

  useEffect(() => {
    return () => {
      dispatch(drugActions.setPageIndex(0));
    };
  }, []);

  const {
    data: listDrugs,
    isLoading: isLoadingListDrugs,
    isFetching,
  } = useQuery(
    [
      ReactQueryKeys.LIST_DRUGS,
      {
        columnFilters,
        pagination,
      },
    ],
    () =>
      DrugService.get({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
        columnFilters: columnFilters,
      }),
    { keepPreviousData: true },
  );

  /**
   * Update search filters for the api call
   *
   * @param value The search value
   */
  const handleUpdateFilter = (value: string) => {
    setColumnFilters([
      {
        id: 'name',
        value,
      },
    ]);
  };

  // Show alert for confirming of deletion a record
  const handleDeleteShow = (drug: ListDrug) => {
    firePreConfirmAlert({
      title: t('drugs.messages.modal__delete__title'),
      html: t('drugs.messages.modal__delete__content', { name: drug.name }),
      preConfirm: () => {
        return DrugService.delete(String(drug.id))
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.LIST_DRUGS]);
        navigate('/drugs');
        toast.success(t('drugs.messages.delete__success'));
      }
    });
  };

  return (
    <>
      <ContentHeader links={MASTER_LINKS} />
      <div className="main-container with-tabs" data-testid="main-container">
        <div className="row">
          <div className="col-3">
            {isLoadingListDrugs ? (
              <Spinner isAbsolute />
            ) : (
              <>
                <HospitalTotalLabels
                  translationKey="drugs"
                  total={listDrugs?.data.totalConcentrations}
                />

                <DrugList
                  refetch={isLoadingListDrugs || isFetching}
                  canManage={canManage}
                  onDelete={handleDeleteShow}
                  updateFilter={handleUpdateFilter}
                  drugsResponse={listDrugs?.data}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </>
            )}
          </div>
          <div className="col-9 ps-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Drugs;
