import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  clearSearch: boolean;
}

const initialState: InitialState = {
  clearSearch: false,
};

const careAreaSlice = createSlice({
  name: 'careArea',
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.clearSearch = true;
    },
    resetClearSearch: (state) => {
      state.clearSearch = false;
    },
  },
});

export const careAreaActions = careAreaSlice.actions;

export default careAreaSlice.reducer;
