import { CellContext } from '@tanstack/react-table';
import { Deployment } from 'interfaces/deployment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';
import { ReactQueryKeys } from 'constants/react-query-keys';
import DeploymentService from 'services/DeploymentService';

function TypeDeploymentColumn({
  info,
}: {
  info: CellContext<Deployment, unknown>;
}) {
  const { t } = useTranslation();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const deployment = info.row.original;

  const {
    data: singleDeploymentData,
    isLoading: loadingSingleDeployment,
    isError,
  } = useQuery(
    [ReactQueryKeys.SINGLE_DEPLOYMENT, deployment.id],
    () => DeploymentService.getSingleDeployment(deployment.id),
    { enabled: isTooltipOpen },
  );

  const renderTooltipContent = () => {
    if (loadingSingleDeployment) {
      return (
        <span
          data-testid={`loading_tooltip_deployment_id_${deployment.id}`}
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
      );
    }
    if (isError) {
      return '';
    }

    if (!singleDeploymentData?.data.deploymentDevices?.length) {
      return (
        <div data-testid={`tooltip_nodevices_id_${deployment.id}`}>
          {t('deployment.partial_deployment.no_devices')}
        </div>
      );
    }

    return (
      <div data-testid={`tooltip_typedeployment_id_${deployment.id}`}>
        {singleDeploymentData.data.deploymentDevices.map((dev) => (
          <div className="py-2" key={dev.id}>
            {dev.device_number}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (isError) {
      toast.error(
        (isError as any)?.response?.data?.message || t('errors.default'),
      );
    }
  }, [isError, t]);

  return (
    <td
      onMouseEnter={() => {
        if (!deployment.partial_deployment) return;
        setIsTooltipOpen(true);
      }}
      onMouseLeave={() => {
        if (!deployment.partial_deployment) return;
        setIsTooltipOpen(false);
      }}
      className={
        'p-2 text-center' + (deployment.partial_deployment ? '' : ' fw-bold')
      }
      style={{
        minWidth: '150px',
      }}
      data-tooltip-id={`id_${deployment.id}`}
      data-testid={`deployment_type_column_id_${deployment.id}`}
    >
      {t(
        `deployment.table.${
          deployment.partial_deployment
            ? 'partial_deployment'
            : 'full_deployment'
        }`,
      )}
      <ReactTooltip
        id={`id_${deployment.id}`}
        place="bottom"
        className="toolTip"
        role={`deployment_type_column_tooltip_${deployment.id}`}
        render={() =>
          deployment.partial_deployment && isTooltipOpen
            ? renderTooltipContent()
            : ''
        }
      />
    </td>
  );
}

export default TypeDeploymentColumn;
