import {
  Controller,
  Control,
  UseFormGetValues,
  FieldErrors,
} from 'react-hook-form';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { FC, useMemo } from 'react';
import { calculateVariablePrecision, validatePrecision } from 'helpers/drugs';
import { useTranslation } from 'react-i18next';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';
import {
  ConcentrationTypesEnum,
  DOSE_MODE_TYPES_VALUES,
  DrugUnitsEnum,
} from 'constants/drugs';

type DoseAmountLimitsProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  getValues: UseFormGetValues<ConcentrationFormData>;
  errors: FieldErrors<ConcentrationFormData>;
  watchDoseLHL: string | number | null | undefined;
  watchDoseLSL: string | number | null | undefined;
  watchDoseUSL: string | number | null | undefined;
  watchDoseUHL: string | number | null | undefined;
  watchDoseModeType:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  canManage: boolean;
};

const DoseAmountLimits: FC<DoseAmountLimitsProps> = ({
  record,
  control,
  getValues,
  errors,
  watchDoseLHL,
  watchDoseLSL,
  watchDoseUSL,
  watchDoseUHL,
  watchDoseModeType,
  canManage,
}) => {
  const { t } = useTranslation();

  const precisionDoseLHL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseLHL),
    [watchDoseLHL],
  );
  const precisionDoseLSL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseLSL),
    [watchDoseLSL],
  );
  const precisionDoseUSL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseUSL),
    [watchDoseUSL],
  );
  const precisionDoseUHL = useMemo<any>(
    () => calculateVariablePrecision(watchDoseUHL),
    [watchDoseUHL],
  );

  return (
    <div className="row pb-3 flex-nowrap">
      <div className="device-concentration-form__label">{t('device.dose')}</div>

      {/* Lower hard limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="dose_lower_hard_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LSL, USL, UHL] = getValues([
                  'dose_lower_soft_limit',
                  'dose_upper_soft_limit',
                  'dose_upper_hard_limit',
                ]);

                isValid = isValid && (LSL ? Number(LSL) > currentValue : true);
                isValid = isValid && (USL ? Number(USL) > currentValue : true);
                isValid = isValid && (UHL ? Number(UHL) > currentValue : true);

                return isValid;
              },
              oneLowerLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDoseLSL !== '' &&
                    watchDoseLSL !== null &&
                    !isNaN(watchDoseLSL as any))
                );
              },
              precision: (value) => validatePrecision(value, precisionDoseLHL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDoseLHL}
              rounding={'toAllowedNumberOfDecimals'}
              // disabled={!canManage}
              className={
                'device-concentration__input--hard-limit ' +
                (errors?.dose_lower_hard_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tooltip-id="dose_lower_hard_limit"
              data-testid="dose_lower_hard_limit"
              disabled={!canManage}
            />
          )}
        />

        {errors?.dose_lower_hard_limit && (
          <ReactTooltip
            id="dose_lower_hard_limit"
            place="bottom"
            delayHide={300}
            variant="error"
          >
            <>
              {errors?.dose_lower_hard_limit?.type ===
                'oneLowerLimitRequired' &&
                t('device.errors.one_lower_limit__required')}
              {errors?.dose_lower_hard_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.dose_lower_hard_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.dose_lower_hard_limit?.type === 'increasingOrder' &&
                t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.dose_lower_hard_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionDoseLHL,
                })}
            </>
          </ReactTooltip>
        )}
      </div>

      {/* Lower soft limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="dose_lower_soft_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LHL, USL, UHL] = getValues([
                  'dose_lower_hard_limit',
                  'dose_upper_soft_limit',
                  'dose_upper_hard_limit',
                ]);

                isValid = isValid && (LHL ? Number(LHL) < currentValue : true);
                isValid = isValid && (USL ? Number(USL) > currentValue : true);
                isValid = isValid && (UHL ? Number(UHL) > currentValue : true);

                return isValid;
              },
              oneLowerLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDoseLHL !== '' &&
                    watchDoseLHL !== null &&
                    !isNaN(watchDoseLHL as any))
                );
              },
              precision: (value) => validatePrecision(value, precisionDoseLSL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDoseLSL}
              rounding={'toAllowedNumberOfDecimals'}
              // disabled={!canManage}
              className={
                'device-concentration__input--soft-limit ' +
                (errors?.dose_lower_soft_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tooltip-id="dose_lower_soft_limit"
              data-testid="dose_lower_soft_limit"
              disabled={!canManage}
            />
          )}
        />

        {errors?.dose_lower_soft_limit && (
          <ReactTooltip
            id="dose_lower_soft_limit"
            place="bottom"
            delayHide={300}
            variant="error"
          >
            <>
              {errors?.dose_lower_soft_limit?.type ===
                'oneLowerLimitRequired' &&
                t('device.errors.one_lower_limit__required')}
              {errors?.dose_lower_soft_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.dose_lower_soft_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.dose_lower_soft_limit?.type === 'increasingOrder' &&
                t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.dose_lower_soft_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionDoseLSL,
                })}
            </>
          </ReactTooltip>
        )}
      </div>

      {/* Upper soft limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="dose_upper_soft_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LHL, LSL, UHL] = getValues([
                  'dose_lower_hard_limit',
                  'dose_lower_soft_limit',
                  'dose_upper_hard_limit',
                ]);

                isValid = isValid && (LHL ? Number(LHL) < currentValue : true);
                isValid = isValid && (LSL ? Number(LSL) < currentValue : true);
                isValid = isValid && (UHL ? Number(UHL) > currentValue : true);

                return isValid;
              },
              oneUpperLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDoseUHL !== '' &&
                    watchDoseUHL !== null &&
                    !isNaN(watchDoseUHL as any))
                );
              },
              precision: (value) => validatePrecision(value, precisionDoseUSL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDoseUSL}
              rounding={'toAllowedNumberOfDecimals'}
              // disabled={!canManage}
              className={
                'device-concentration__input--soft-limit ' +
                (errors?.dose_upper_soft_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tooltip-id="dose_upper_soft_limit"
              data-testid="dose_upper_soft_limit"
              disabled={!canManage}
            />
          )}
        />

        {errors?.dose_upper_soft_limit && (
          <ReactTooltip
            id="dose_upper_soft_limit"
            place="bottom"
            delayHide={300}
            variant="error"
          >
            <>
              {errors?.dose_upper_soft_limit?.type ===
                'oneUpperLimitRequired' &&
                t('device.errors.one_upper_limit__required')}
              {errors?.dose_upper_soft_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.dose_upper_soft_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.dose_upper_soft_limit?.type === 'increasingOrder' &&
                t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.dose_upper_soft_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionDoseUSL,
                })}
            </>
          </ReactTooltip>
        )}
      </div>

      {/* Upper hard limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="dose_upper_hard_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LHL, LSL, USL] = getValues([
                  'dose_lower_hard_limit',
                  'dose_lower_soft_limit',
                  'dose_upper_soft_limit',
                ]);

                isValid = isValid && (LHL ? Number(LHL) < currentValue : true);
                isValid = isValid && (LSL ? Number(LSL) < currentValue : true);
                isValid = isValid && (USL ? Number(USL) < currentValue : true);

                return isValid;
              },
              oneUpperLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDoseUSL !== '' &&
                    watchDoseUSL !== null &&
                    !isNaN(watchDoseUSL as any))
                );
              },
              precision: (value) => validatePrecision(value, precisionDoseUHL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDoseUHL}
              rounding={'toAllowedNumberOfDecimals'}
              // disabled={!canManage}
              className={
                'device-concentration__input--hard-limit ' +
                (errors?.dose_upper_hard_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tooltip-id="dose_upper_hard_limit"
              data-testid="dose_upper_hard_limit"
              disabled={!canManage}
            />
          )}
        />

        {errors?.dose_upper_hard_limit && (
          <ReactTooltip
            id="dose_upper_hard_limit"
            place="bottom"
            delayHide={300}
            variant="error"
          >
            <>
              {errors?.dose_upper_hard_limit?.type ===
                'oneUpperLimitRequired' &&
                t('device.errors.one_upper_limit__required')}
              {errors?.dose_upper_hard_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.dose_upper_hard_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.dose_upper_hard_limit?.type === 'increasingOrder' &&
                t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.dose_upper_hard_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionDoseUHL,
                })}
            </>
          </ReactTooltip>
        )}
      </div>

      {/* Units */}
      <div className="device-concentration-units">
        {record?.type === ConcentrationTypesEnum.MILLILITER_BASED
          ? DrugUnitsEnum.MILLILITER
          : record?.drug_unit}
        &nbsp;
        {!!record &&
          !!watchDoseModeType &&
          DOSE_MODE_TYPES_VALUES[
            watchDoseModeType.value as keyof typeof DOSE_MODE_TYPES_VALUES
          ]}
      </div>
    </div>
  );
};

export default DoseAmountLimits;
