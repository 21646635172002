import './Overlay.scss';
import React from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { overlayActions } from 'store/slices/overlay';
import { useDispatch, useSelector } from 'react-redux';

type OverlayProps = {
  path?: string;
};

const Overlay: FC<OverlayProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useSelector((state: any) => state.overlay.path);

  const overlayClass = 'overlay';

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!!path && e.currentTarget.className === overlayClass) {
      dispatch(overlayActions.close());
      navigate(path);
    }
  };

  return (
    <div
      className={overlayClass}
      onClick={handleClick}
      data-testid="overlay"
    ></div>
  );
};

export default Overlay;
