import {
  DEPLOYMENT_UPDATE_PARTIAL_PREFIX,
  DEPLOYMENT_UPDATE_PREFIX,
} from 'constants/router';
import { Link, useLocation } from 'react-router-dom';
import { CellContext } from '@tanstack/react-table';
import { Deployment } from 'interfaces/deployment';
import { useTranslation } from 'react-i18next';
import { TbArrowBack } from 'react-icons/tb';
import { FiSkipBack } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { PARTIAL_UPDATE_TYPE } from 'constants/deployment';
import { toast } from 'react-hot-toast';
import DeploymentService from 'services/DeploymentService';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useQuery } from '@tanstack/react-query';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';

const LastColumn = ({ info }: { info: CellContext<Deployment, unknown> }) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const [backUrl, setBackUrl] = useState(pathname);

  useEffect(() => {
    setBackUrl(pathname);
  }, [pathname]);

  const deployment = info.row.original;

  return (
    <td className="p-2 text-center text-nowrap w-100">
      <div className="d-flex align-items-center justify-content-center gap-1 btn-controls">
        {deployment.partial_deployment ? (
          deployment.active ? (
            <>
              <RevertButton backUrl={backUrl} />
            </>
          ) : deployment.partial_reverted ? (
            <b>{t('deployment.table.partial_deployment_reverted')}</b>
          ) : null
        ) : deployment.active ? (
          <div
            data-tooltip-content={t(
              'deployment.messages.type__deployment__full',
            )}
            className={'color'}
            data-tooltip-id={`currently_deployed_tooltip_${deployment.id}`}
          >
            {t('deployment.table.currently_deployed')}
            <ReactTooltip id={`currently_deployed_tooltip_${deployment.id}`} />
          </div>
        ) : deployment.can_revert ? (
          <>
            <Link
              to={`${backUrl}/${DEPLOYMENT_UPDATE_PREFIX}/${deployment.id}`}
              state={backUrl}
              data-tooltip-content={t('deployment.buttons.revert_btn')}
              data-tooltip-id={`revert_tooltip_${deployment.id}`}
              className="icon"
            >
              <ReactTooltip id={`revert_tooltip_${deployment.id}`} />
              <TbArrowBack size={26} />
            </Link>
          </>
        ) : null}
      </div>
    </td>
  );
};

export default LastColumn;

const RevertButton = ({ backUrl }: { backUrl: string }) => {
  const { t } = useTranslation();

  const { data: activeFullDeployment, isError } = useQuery(
    [ReactQueryKeys.DEPLOYMENTS],
    () => DeploymentService.getLatestActiveDeployment(),
  );

  if (isError) {
    toast.error(
      (isError as any)?.response?.data?.message || t('errors.default'),
    );
  }

  if (!activeFullDeployment?.data) return null;

  const version = activeFullDeployment?.data?.version_major;

  return (
    <Link
      to={`${backUrl}/${DEPLOYMENT_UPDATE_PARTIAL_PREFIX}/${PARTIAL_UPDATE_TYPE.REVERT}`}
      state={backUrl}
      data-tooltip-id={activeFullDeployment.data.id + ''}
      data-tooltip-content={t('deployment.buttons.revert_partial_btn', {
        version,
      })}
      className="icon"
      data-testid="revert_btn"
    >
      <ReactTooltip
        place="bottom"
        className="toolTip"
        id={activeFullDeployment.data.id + ''}
      />
      <FiSkipBack size={26} />
    </Link>
  );
};
