import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from '../../../constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from '../../../store/slices/overlay';
import UserService from '../../../services/UserService';
import CompanyService from '../../../services/CompanyService';
import { User } from '../../../interfaces/user';
import {
  ROLE_LIST,
  SUPER_ADMIN,
  ROLES_MAPPING,
} from '../../../constants/roles';
import { Company } from '../../../interfaces/company';
import Select from 'react-select';
import { timezones } from '../../../constants/timezones';
import { PASSWORD_REGEX } from '../../../constants/regex';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { parseErrorMessage } from '../../../helpers/parse-error-message';
import validator from 'validator';
import { useTranslation } from 'react-i18next';

type FormData = {
  id?: number;
  cognito_id?: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  company_id?: string;
  company?: { label: string; value: string };
  title?: string;
  department?: string;
  timezone?: { label: string; value: string };
  password?: string;
  password_confirm?: string;
  roles: string;
  role?: { label: string; value: string };
};

const UsersModalForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'all',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const watchRole = watch('role');
  const watchPassword = watch('password');

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      first_name: formData.first_name.trim(),
      last_name: formData.last_name.trim(),
      email: formData.email,
      phone_number: formData.phone_number.trim(),
      password: formData.password ? formData.password : null,
      roles: formData.role!.value,
      company_id: formData.company?.value ? +formData.company?.value : null,
      title: formData.title ? formData.title : null,
      department: formData.department ? formData.department : null,
      timezone: formData.timezone?.label ? formData.timezone.label : null,
    };
    setFormSubmitting(true);

    if (params.id) {
      try {
        await UserService.update(params.id, data);
        queryClient.invalidateQueries([ReactQueryKeys.USERS]);
        toast.success(t('users.messages.update__success'));
        navigate('/users');
      } catch (e: any) {
        let message = t('users.messages.update__error');
        if (
          e.response?.data?.statusCode === 400 ||
          e?.response?.data?.statusCode === 502
        ) {
          message = e.response.data?.message;
        }
        toast.error(message);
      }
    } else {
      try {
        await UserService.create(data);
        queryClient.invalidateQueries([ReactQueryKeys.USERS]);
        toast.success(t('users.messages.create__success'));
        navigate('/users');
      } catch (e: any) {
        let message = t('users.messages.create__error');
        if (
          e?.response?.data?.statusCode === 400 ||
          e?.response?.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }

    setFormSubmitting(false);
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate('/users');
    }
  };

  /**
   * Get companies for the company dropdown
   */
  const getCompanies = async () => {
    try {
      const {
        data: { records },
      } = await CompanyService.get({
        pageIndex: 0,
        pageSize: 1000,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
        columnFilters: [
          {
            id: 'disabled_at',
            value: { label: 'Enabled', value: 'enabled' },
          },
        ],
      });

      setCompanies(records);
      return records;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      toast.error(t('users.messages.loading__error__companies'));
      return Promise.reject();
    }
  };

  useEffect(() => {
    dispatch(overlayActions.open({ path: '/users' }));
    getCompanies()
      .then((companies) => {
        if (params.id) {
          setFormLoading(true);
          UserService.find(params.id)
            .then(({ data }: { data: User }) => {
              setValue('first_name', data.first_name);
              setValue('last_name', data.last_name);
              setValue('email', data.email);
              setValue('phone_number', data.phone_number);
              setValue('role', {
                label: t(ROLES_MAPPING[data.roles]),
                value: data.roles,
              });
              if (data?.title) {
                setValue('title', data?.title);
              }
              if (data?.department) {
                setValue('department', data?.department);
              }
              if (data?.company_id) {
                const company = companies?.find(
                  (c) => c.id === data.company_id,
                );
                if (company) {
                  setValue('company', {
                    label: company?.name,
                    value: String(company?.id),
                  });
                }
              }
              if (data?.timezone) {
                const selectedTimezone = timezones.find(
                  (tz) => tz.label === data.timezone,
                );
                if (selectedTimezone) {
                  setValue('timezone', {
                    label: selectedTimezone.label,
                    value: selectedTimezone.value,
                  });
                }
              }

              setFormLoading(false);
              setEditing(true);
            })
            .catch(() => {
              toast.error(t('users.messages.loading__error'));
              navigate('/users');
            });
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div
        className={
          'modal-sidebar-body pb-4 ' +
          (formLoading || formSubmitting ? 'opacity-50' : '')
        }
      >
        {formLoading && <Spinner isAbsolute />}

        <div className="row">
          {/* First Name */}
          <div className="col-6">
            <label htmlFor="firstName" className="form-label">
              {t('users.first_name')} *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="firstName"
              data-testid="first_name"
              {...register('first_name', {
                required: true,
              })}
            />
            {errors?.first_name?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.first_name__required')}
              </div>
            )}
          </div>
          {/* Last Name */}
          <div className="col-6">
            <label htmlFor="lastName" className="form-label">
              {t('users.last_name')} *
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="lastName"
              data-testid="last_name"
              {...register('last_name', {
                required: true,
              })}
            />
            {errors?.last_name?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.last_name__required')}
              </div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Email */}
          <div className="col-6">
            <label htmlFor="email" className="form-label">
              {t('users.email')} *
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="email"
              data-testid="email"
              {...register('email', {
                required: true,
                validate: {
                  email: (value) => validator.isEmail(value),
                },
              })}
            />
            {errors?.email?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.email__required')}
              </div>
            )}
            {errors?.email?.type === 'email' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.email__valid')}
              </div>
            )}
          </div>
          {/* Phone Number */}
          <div className="col-6">
            <label htmlFor="phoneNumber" className="form-label">
              {t('users.phone_number')} *
            </label>

            <Controller
              control={control}
              name="phone_number"
              rules={{
                required: true,
                validate: (value) =>
                  !!value && isValidPhoneNumber(String(value)),
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <PhoneInput
                  id="phoneNumber"
                  placeholder="Enter phone number"
                  defaultCountry="US"
                  value={value}
                  data-testid="phone_number"
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  className="phone-input-lg phone-input"
                />
              )}
            />
            {errors?.phone_number?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.phone_number__required')}
              </div>
            )}
            {errors?.phone_number?.type === 'validate' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.phone_number__valid')}
              </div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Access Level */}
          <div className="col-6">
            <label htmlFor="accessLevel" className="form-label">
              {t('users.access_level')} *
            </label>
            <Controller
              control={control}
              name="role"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  id="accessLevel"
                  maxMenuHeight={300}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value as any}
                  isMulti={false}
                  aria-label="access_level"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={ROLE_LIST.map((role) => ({
                    label: t(ROLES_MAPPING[role]),
                    value: role,
                  }))}
                />
              )}
            />
            {errors?.role?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.access_level__required')}
              </div>
            )}
          </div>

          {/* Company */}
          {watchRole?.value !== SUPER_ADMIN && (
            <div className="col-6">
              <label htmlFor="company" className="form-label">
                {t('users.company')} *
              </label>
              <Controller
                control={control}
                name="company"
                rules={{ required: watchRole?.value !== SUPER_ADMIN }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    maxMenuHeight={300}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value as any}
                    isMulti={false}
                    aria-label="company"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable
                    options={companies.map((company) => ({
                      label: company.name,
                      value: company.id,
                    }))}
                  />
                )}
              />
              {errors?.company?.type === 'required' && (
                <div className="invalid-feedback pt-1">
                  {t('users.errors.company__required')}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="row pt-4">
          {/* Title */}
          <div className="col-6">
            <label htmlFor="title" className="form-label">
              {t('users.user_title')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="title"
              data-testid="title"
              {...register('title')}
            />
          </div>

          {/* Department */}
          <div className="col-6">
            <label htmlFor="department" className="form-label">
              {t('users.department')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="department"
              data-testid="department"
              {...register('department')}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Time Zone */}
          <div className="col-12">
            <label htmlFor="timezone" className="form-label">
              {t('users.time_zone')}
            </label>
            <Controller
              control={control}
              name="timezone"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  aria-label="timezone"
                  maxMenuHeight={300}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value as any}
                  isMulti={false}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={timezones.map((timezone) => ({
                    label: timezone.label,
                    value: timezone.value,
                  }))}
                />
              )}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Password */}
          <div className="col-6">
            <label htmlFor="password" className="form-label">
              {t('users.password')} {!editing && '*'}
            </label>
            <input
              type="password"
              className="form-control form-control-lg rounded"
              id="password"
              data-testid="password"
              {...register('password', {
                required: !editing,
                pattern: PASSWORD_REGEX,
              })}
            />
            {errors?.password?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.password__required')}
              </div>
            )}
            {errors?.password?.type === 'pattern' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.password__pattern')}
              </div>
            )}
          </div>

          {/* Confirm Password */}
          <div className="col-6">
            <label htmlFor="password_confirm" className="form-label">
              {t('users.confirm_password')} {!editing && '*'}
            </label>
            <input
              type="password"
              className="form-control form-control-lg rounded"
              id="password_confirm"
              data-testid="password_confirm"
              {...register('password_confirm', {
                required: !editing,
                pattern: PASSWORD_REGEX,
                validate: (value) => value === watchPassword,
              })}
            />

            {errors?.password_confirm?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.confirm_password__required')}
              </div>
            )}
            {errors?.password_confirm?.type === 'pattern' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.confirm_password__pattern')}
              </div>
            )}
            {errors?.password_confirm?.type === 'validate' && (
              <div className="invalid-feedback pt-1">
                {t('users.errors.confirm_password__match')}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={formSubmitting}
          defaultLabel={t('buttons.save')}
          loadingLabel={params.id ? t('buttons.updating') : t('buttons.saving')}
          type="submit"
          disabled={formSubmitting || formLoading}
        ></Button>

        <Link
          to="/users"
          className={`btn btn-lg rounded btn-secondary ${
            formSubmitting || formLoading ? 'disabled' : ''
          }`}
        >
          {t('buttons.cancel')}
        </Link>
      </div>
    </form>
  );
};

export default UsersModalForm;
