import ModalSidebar from 'components/UI/ModalSidebar/ModalSidebar';
import { PARTIAL_UPDATE_TYPE } from 'constants/deployment';
import {
  FRONT_ROUTER,
  DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY,
} from 'constants/router';
import { ReactElement } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const ModalSidebarWrapper = ({ children }: { children: ReactElement }) => {
  const { [DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY]: updateType } = useParams();

  let translation = '';

  switch (updateType) {
    case PARTIAL_UPDATE_TYPE.REVERT:
      translation = 'deployment.form.modal__update__partial__deployment-revert';
      break;
    case PARTIAL_UPDATE_TYPE.UPDATE_TO_FULL_DEPLOYMENT:
      translation =
        'deployment.form.modal__update__partial__deployment-full_deployment';
      break;
    case PARTIAL_UPDATE_TYPE.UPDATE_TO_FULL_LIBRARY:
      translation =
        'deployment.form.modal__update__partial__deployment-library';
      break;

    default:
      translation = FRONT_ROUTER.ERROR;
      break;
  }

  if (translation === FRONT_ROUTER.ERROR) {
    return <Navigate to={FRONT_ROUTER.ERROR} />;
  }

  return <ModalSidebar title={translation}>{children}</ModalSidebar>;
};

export default ModalSidebarWrapper;
