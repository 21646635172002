import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface InitialState {
  oldDeployment: any;
  newDeployment: any;
}

const initialState: InitialState = {
  oldDeployment: null,
  newDeployment: null,
};

const deploymentSlice = createSlice({
  name: 'deployment',
  initialState,
  reducers: {
    setDeploymentJsonFiles: (
      state,
      action: PayloadAction<{
        oldDeployment: any;
        newDeployment: any;
      }>,
    ) => {
      state.oldDeployment = action.payload.oldDeployment;
      state.newDeployment = action.payload.newDeployment;
    },
  },
});

export const deploymentActions = deploymentSlice.actions;

export default deploymentSlice.reducer;
