import { NavLink } from 'react-router-dom';
import { FC } from 'react';
import './ContentHeader.scss';
import { useTranslation } from 'react-i18next';

type LinkType = {
  title: string;
  url: string;
};

type ContentHeaderProps = {
  title?: string;
  actions?: JSX.Element | undefined | null;
  links?: LinkType[];
};

const ContentHeader: FC<ContentHeaderProps> = ({ title, actions, links }) => {
  const { t } = useTranslation();

  return (
    <div className={'content-header'} data-testid="content-header">
      {!title && !actions ? null : (
        <div className="content-header__title">
          {title && <span>{title}</span>}
          <div className="content-header__actions">{!!actions && actions}</div>
        </div>
      )}

      {Array.isArray(links) && (
        <ul className="content-header__links">
          {links.map((link) => {
            return (
              <li key={link.url}>
                <NavLink className="content-header__link" to={link.url}>
                  {t(link.title)}
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ContentHeader;
