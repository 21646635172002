import { UseFormRegister } from 'react-hook-form';
import { ConcentrationFormData } from 'interfaces/concentration';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RadioInput from 'components/UI/RadioInput/RadioInput';

type AllowRapidCompleteProps = {
  register: UseFormRegister<ConcentrationFormData>;
  canManage: boolean;
};

const AllowRapidComplete: FC<AllowRapidCompleteProps> = ({
  register,
  canManage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="customRow pt-5">
      <div className="device-concentration-form__label  align-items-start">
        {t('device.rapid_complete')}
      </div>
      <div className="customRow ps-0 allow-rapid-radios">
        <RadioInput
          {...register('allow_rapid_complete')}
          value={'OFF'}
          id="allow_rapid_complete__off"
          disabled={!canManage}
          title={t('device.rapid_complete__off')}
          data-testid="allow_rapid_complete"
        />
        <RadioInput
          {...register('allow_rapid_complete')}
          value={'ON'}
          id="allow_rapid_complete__on"
          disabled={!canManage}
          title={t('device.rapid_complete__on')}
          data-testid="allow_rapid_complete"
        />
      </div>
    </div>
  );
};

export default AllowRapidComplete;
