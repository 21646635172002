import { DB_LIMITS } from 'constants/db-limits';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  total: number | undefined;
  translationKey: keyof typeof DB_LIMITS;
};
const HospitalTotalLabels: React.FC<Props> = ({ total, translationKey }) => {
  const { t } = useTranslation();

  return (
    <div className="py-2" data-testid="hospital-total-labels">
      {`${t('totalCount.number')} ${t(
        `${translationKey}.title`,
      )}: ${total?.toLocaleString()} (${t('totalCount.limit')}: ${DB_LIMITS[
        translationKey
      ].toLocaleString()})`}
    </div>
  );
};

export default HospitalTotalLabels;
