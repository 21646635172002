import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from '../config/axios';
import { CareArea } from '../interfaces/care-area';
import { PaginationResponse } from 'interfaces/pagination-response';
import { AxiosRequestConfig } from 'axios';

export type CareAreaResponse = PaginationResponse<CareArea>;

const CareAreaService = {
  create: (data: Partial<CareArea>) => {
    return axiosInstance.post<CareArea>('/v1/care-areas', data);
  },

  update: (id: string | number, data: Partial<CareArea>) => {
    return axiosInstance.patch<CareArea>(`/v1/care-areas/${id}`, data);
  },

  find: (id: string | number, config?: AxiosRequestConfig) => {
    return axiosInstance.get<CareArea>(`/v1/care-areas/${id}`, config);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<CareArea>(`/v1/care-areas/${id}`);
  },

  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<CareAreaResponse>('/v1/care-areas', {
      params: queryParams,
    });
  },
};

export default CareAreaService;
