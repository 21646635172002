import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/UI/Table/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getViewChangesCellClass } from 'helpers/deployment';
import { formatSecondsToWhateverFormat } from 'helpers/date-helpers';
import { DrugChangesReportType } from 'interfaces/drug';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';

type DrugChangesProps = {
  drugs: any[];
};

function setTooltipForUpdatedValues(
  key:
    | keyof DrugChangesReportType
    //add infuseAs object keys
    | keyof DrugChangesReportType['infuseAs'],
  row: DrugChangesReportType,
  {
    limit,
    replaceValueTooltipText,
    bolusAndLoad,
  }: {
    limit?:
      | 'lowerHardLimit'
      | 'lowerSoftLimit'
      | 'upperSoftLimit'
      | 'upperHardLimit';
    replaceValueTooltipText?: string;
    bolusAndLoad?: keyof Pick<
      DrugChangesReportType['loadingDose'],
      'amount' | 'time'
    >;
  } = {},
) {
  const noTooltip = {};
  if (key === 'loadingDose' || key === 'bolus') {
    if (
      limit &&
      bolusAndLoad &&
      //@ts-ignore
      row.oldValues?.[key]?.[bolusAndLoad] &&
      limit in
        (row?.oldValues?.[key] as DrugChangesReportType[typeof key])[
          bolusAndLoad
        ]
    ) {
      return {
        'data-tooltip-id': `${row.id}-${key}-${bolusAndLoad}-${limit}`,
        'data-tooltip-content':
          replaceValueTooltipText ??
          (row.oldValues[key] as DrugChangesReportType['loadingDose'])[
            bolusAndLoad
          ][limit],
      };
    }
    return noTooltip;
  }
  if (row.oldValues && key in row.oldValues) {
    if (!limit) {
      return {
        'data-tooltip-id': `${row.id}-${key}`,
        'data-tooltip-content': replaceValueTooltipText ?? row.oldValues[key],
      };
    }

    if (
      limit in
      (row.oldValues[key] as DrugChangesReportType['concentrationLimits'])
    ) {
      return {
        'data-tooltip-id': `${row.id}-${key}-${limit}`,
        'data-tooltip-content':
          replaceValueTooltipText ??
          (row.oldValues[key] as DrugChangesReportType['concentrationLimits'])[
            limit
          ],
      };
    }
  }
  return noTooltip;
}

export const getInfusionComplete = (runKvoRate: boolean | null) => {
  if (runKvoRate === null || runKvoRate === undefined) {
    return '';
  }

  return runKvoRate ? 'Run at KVO Rate' : 'Maintain Infusion Rate';
};

const DrugChanges: FC<DrugChangesProps> = ({ drugs }) => {
  const { t } = useTranslation();

  /**
   * Define columns for the table
   */

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'drug_name',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'name'> = 'name';

          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key]}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.drug_name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'facility',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'facilities'> =
            'facilities';

          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key]}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.facility'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'care_area',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'careAreas'> =
            'careAreas';

          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original.name === 'Basic mL / hr'
                  ? 'All Care Areas'
                  : row.original[key]}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.care_area'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'infusion_type',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'infusionType'> =
            'infusionType';

          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key].value}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.infusion_type'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'concentration',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'concentrationName'> =
            'concentrationName';

          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key] ? row.original[key] : ''}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.concentration'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'dose_mode',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'doseMode'> = 'doseMode';

          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original))}
              >
                {row.original[key].value}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.dose_mode'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'therapy',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'therapyName'> =
            'therapyName';
          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText:
                      OLD_VALUES[key] === ''
                        ? t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          )
                        : OLD_VALUES[key],
                  }))}
              >
                {row.original[key]}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.therapy'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'concentration_limits',
        header: t('reports.drug_report.concentration_limits'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'concentration_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<
                DrugChangesReportType,
                'concentrationLimits'
              > = 'concentrationLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerHardLimit'
              > = 'lowerHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.drugUnit.value
                        } / ml`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<
                DrugChangesReportType,
                'concentrationLimits'
              > = 'concentrationLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.drugUnit.value
                        } / ml`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<
                DrugChangesReportType,
                'concentrationLimits'
              > = 'concentrationLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperSoftLimit'
              > = 'upperSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.drugUnit.value
                        } / ml`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<
                DrugChangesReportType,
                'concentrationLimits'
              > = 'concentrationLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperHardLimit'
              > = 'upperHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.drugUnit.value
                        } / ml`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'dose_rate',
        header: t('reports.drug_report.dose_rate'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'dose_rate_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseRateLimits'> =
                'doseRateLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerHardLimit'
              > = 'lowerHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseRateLimits'> =
                'doseRateLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseRateLimits'> =
                'doseRateLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperSoftLimit'
              > = 'upperSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseRateLimits'> =
                'doseRateLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperHardLimit'
              > = 'upperHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'concentration_duration',
        header: t('reports.drug_report.duration'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'duration_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'time'> = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerHardLimit'
              > = 'lowerHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] === null
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : `${formatSecondsToWhateverFormat(
                                Number(OLD_VALUES?.[key]?.[limit]),
                                'HH:mm',
                              )}`,
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${formatSecondsToWhateverFormat(
                          Number(row.original[key][limit]),
                          'HH:mm',
                        )}`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'time'> = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] === null
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : `${formatSecondsToWhateverFormat(
                                Number(OLD_VALUES?.[key]?.[limit]),
                                'HH:mm',
                              )}`,
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${formatSecondsToWhateverFormat(
                          Number(row.original[key][limit]),
                          'HH:mm',
                        )}`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'time'> = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperSoftLimit'
              > = 'upperSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] === null
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : `${formatSecondsToWhateverFormat(
                                Number(OLD_VALUES?.[key]?.[limit]),
                                'HH:mm',
                              )}`,
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${formatSecondsToWhateverFormat(
                          Number(row.original[key][limit]),
                          'HH:mm',
                        )}`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'time'> = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperHardLimit'
              > = 'upperHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] === null
                            ? t(
                                'reports.dl_changes_report.common.null_values_tooltip',
                              )
                            : `${formatSecondsToWhateverFormat(
                                Number(OLD_VALUES?.[key]?.[limit]),
                                'HH:mm',
                              )}`,
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${formatSecondsToWhateverFormat(
                          Number(row.original[key][limit]),
                          'HH:mm',
                        )}`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'dose_limits',
        header: t('reports.drug_report.dose_limits'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'dose_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseLimits'> =
                'doseLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerHardLimit'
              > = 'lowerHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        } 
                    `
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseLimits'> =
                'doseLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        } 
                    `
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseLimits'> =
                'doseLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperSoftLimit'
              > = 'upperSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        } 
                    `
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'doseLimits'> =
                'doseLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperHardLimit'
              > = 'upperHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        } 
                    `
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'drug_amount',
        header: t('reports.drug_report.drug_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'drug_amount_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'drugAmountLimits'> =
                'drugAmountLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerHardLimit'
              > = 'lowerHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'drugAmountLimits'> =
                'drugAmountLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'drugAmountLimits'> =
                'drugAmountLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperSoftLimit'
              > = 'upperSoftLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'drugAmountLimits'> =
                'drugAmountLimits';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key],
                'upperHardLimit'
              > = 'upperHardLimit';
              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key] === 'object' &&
                limit in (OLD_VALUES?.[key] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {row.original?.[key] &&
                    limit in row.original[key] &&
                    row.original?.[key]?.[limit] !== null
                      ? `${Number(row.original[key][limit])} ${
                          row.original.doseMode.value
                        }`
                      : ''}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      //Infuse as columns
      {
        id: 'infuse_as',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'infuseAs'> = 'infuseAs';
          const OLD_VALUES = row.original.oldValues;
          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText:
                      OLD_VALUES[key] === null
                        ? t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          )
                        : OLD_VALUES[key].value,
                  }))}
              >
                {row.original[key].value}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.infuse_as'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'allow_secondary',
        accessorFn: () => {},
        cell: ({ row }) => {
          const infuseAsKey: keyof Pick<DrugChangesReportType, 'infuseAs'> =
            'infuseAs';

          const key: keyof Pick<
            DrugChangesReportType[typeof infuseAsKey],
            'allowPairedSecondary'
          > = 'allowPairedSecondary';

          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          const ON_VALUE = 'On';
          const OFF_VALUE = 'Off';

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText:
                      OLD_VALUES[key] === null
                        ? t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          )
                        : OLD_VALUES[key]
                          ? ON_VALUE
                          : OFF_VALUE,
                  }))}
              >
                {key in row.original[infuseAsKey] === false
                  ? ''
                  : row.original[infuseAsKey][key]
                    ? ON_VALUE
                    : OFF_VALUE}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.allow_secondary'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      //------------
      {
        id: 'infusion_complete',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'runKvoRate'> =
            'runKvoRate';
          const OLD_VALUES = row.original.oldValues;
          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                data-testid={`${key}-${row.original.id}`}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText: (() => {
                      const parsedValue = getInfusionComplete(OLD_VALUES[key]);
                      return parsedValue === ''
                        ? t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          )
                        : parsedValue;
                    })(),
                  }))}
              >
                {getInfusionComplete(row.original[key])}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.infusion_complete'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'delivery_pressure',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'deliveryPressure'> =
            'deliveryPressure';
          const OLD_VALUES = row.original.oldValues;
          const ON_VALUE = t('device.delivery_pressure__on');
          const OFF_VALUE = t('device.delivery_pressure__off');

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                data-testid={`${key}-${row.original.id}`}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText: OLD_VALUES[key]
                      ? ON_VALUE
                      : OFF_VALUE,
                  }))}
              >
                {row.original[key] ? ON_VALUE : OFF_VALUE}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.delivery_pressure'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'allow_rapid_complete',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'allowRapidEmpty'> =
            'allowRapidEmpty';
          const OLD_VALUES = row.original.oldValues;
          const ON_VALUE = t('device.rapid_complete__on');
          const OFF_VALUE = t('device.rapid_complete__off');

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                data-testid={`${key}-${row.original.id}`}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText:
                      OLD_VALUES[key] === ''
                        ? t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          )
                        : OLD_VALUES[key]
                          ? ON_VALUE
                          : OFF_VALUE,
                  }))}
              >
                {key in row.original === false
                  ? ''
                  : row.original[key]
                    ? ON_VALUE
                    : OFF_VALUE}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.rapid_complete'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'infuse_until_empty',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'infuseUntilEmpty'> =
            'infuseUntilEmpty';
          const OLD_VALUES = row.original.oldValues;
          const ON_VALUE = t('device.infuse_until_empty__on');
          const OFF_VALUE = t('device.infuse_until_empty__off');

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;
          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                data-testid={`${key}-${row.original.id}`}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText:
                      OLD_VALUES[key] === null
                        ? t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          )
                        : OLD_VALUES[key]
                          ? ON_VALUE
                          : OFF_VALUE,
                  }))}
              >
                {key in row.original === false
                  ? ''
                  : row.original[key]
                    ? ON_VALUE
                    : OFF_VALUE}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.infuse_until_empty'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      // Loading Dose
      {
        id: 'loading_dose_amount',
        header: t('reports.drug_report.loading_dose_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'load_amount_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'lowerHardLimit'
              > = 'lowerHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[amountKey] ?? {}) === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[amountKey] ?? {}) === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'upperSoftLimit'
              > = 'upperSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    (row.original[key]?.[amountKey] ?? {})[limit] === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'upperHardLimit'
              > = 'upperHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});

              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    data-testid={`${row.original.id}-${key}-${limit}`}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[amountKey] ?? {}) === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'loading_dose_time',
        header: t('reports.drug_report.loading_dose_time'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'load_time_lower_hard_limit',
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'lowerHardLimit'
              > = 'lowerHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    !row.original[key]?.[timeKey]?.unit?.value ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_lower_soft_limit',
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    !row.original[key]?.[timeKey]?.unit?.value ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_upper_soft_limit',
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'upperSoftLimit'
              > = 'upperSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    !row.original[key]?.[timeKey]?.unit?.value ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_upper_hard_limit',
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'loadingDose'> =
                'loadingDose';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'upperHardLimit'
              > = 'upperHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    !row.original[key]?.[timeKey]?.unit?.value ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },

      // BOLUS
      {
        id: 'bolus_dose_amount',
        header: t('reports.drug_report.bolus_dose_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'bolus_amount_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'lowerHardLimit'
              > = 'lowerHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[amountKey] ?? {}) === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[amountKey] ?? {}) === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'upperSoftLimit'
              > = 'upperSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[amountKey] ?? {}) === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const amountKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'amount'
              > = 'amount';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof amountKey],
                'upperHardLimit'
              > = 'upperHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[amountKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[amountKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: amountKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: amountKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[amountKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[amountKey] ?? {}) === false ||
                    row.original[key]?.[amountKey]?.[limit] === null
                      ? ''
                      : `${Number(row.original[key][amountKey][limit])} ${
                          row.original[key][amountKey].unit.value
                        } / kg`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${amountKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'bolus_dose_time',
        header: t('reports.drug_report.bolus_dose_time'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'bolus_time_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'lowerHardLimit'
              > = 'lowerHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'lowerSoftLimit'
              > = 'lowerSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'upperSoftLimit'
              > = 'upperSoftLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_upper_hard_limit',
            cell: ({ row }) => {
              const key: keyof Pick<DrugChangesReportType, 'bolus'> = 'bolus';
              const timeKey: keyof Pick<
                DrugChangesReportType[typeof key],
                'time'
              > = 'time';
              const limit: keyof Pick<
                DrugChangesReportType[typeof key][typeof timeKey],
                'upperHardLimit'
              > = 'upperHardLimit';

              const OLD_VALUES = row.original.oldValues;

              const setUpUpdateToolTip =
                typeof OLD_VALUES?.[key]?.[timeKey] === 'object' &&
                limit in (OLD_VALUES?.[key]?.[timeKey] ?? {});
              return (
                <>
                  <div
                    className={getViewChangesCellClass(row.original, {
                      key,
                      limit,
                      bolusAndLoad: timeKey,
                    })}
                    {...(setUpUpdateToolTip &&
                      setTooltipForUpdatedValues(key, row.original, {
                        limit: limit,
                        bolusAndLoad: timeKey,
                        replaceValueTooltipText:
                          OLD_VALUES?.[key]?.[timeKey]?.[limit] ??
                          t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          ),
                      }))}
                  >
                    {!row.original?.[key] ||
                    !row.original[key].allow ||
                    limit in (row.original[key]?.[timeKey] ?? {}) === false ||
                    row.original[key]?.[timeKey]?.[limit] === null
                      ? ''
                      : `${formatSecondsToWhateverFormat(
                          Number(row.original[key][timeKey][limit]),
                          row.original[key][timeKey].unit.value,
                        )} ${row.original[key][timeKey].unit.value}`}
                  </div>
                  {setUpUpdateToolTip && (
                    <ReactTooltip
                      place="bottom"
                      className="toolTip"
                      id={`${row.original.id}-${key}-${timeKey}-${limit}`}
                    />
                  )}
                </>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      // {
      //   id: 'rate_increase',
      //   accessorFn: () => {},
      //   cell: ({ row }) => (
      //     <div className={getViewChangesCellClass(row.original)}>
      //       {!!row.original.maxRatePercentIncrease
      //         ? `${row.original.maxRatePercentIncrease} %`
      //         : null}
      //     </div>
      //   ),
      //   header: t('reports.drug_report.rate_increase'),
      //   enableSorting: false,
      //   enableColumnFilter: false,
      //   className: 'text-center',
      // },
      {
        id: 'advisory',
        accessorFn: () => {},
        cell: ({ row }) => {
          const key: keyof Pick<DrugChangesReportType, 'advisoryName'> =
            'advisoryName';
          const OLD_VALUES = row.original.oldValues;

          const setUpUpdateToolTip =
            typeof OLD_VALUES === 'object' && key in OLD_VALUES;

          return (
            <>
              <div
                className={getViewChangesCellClass(row.original, { key })}
                {...(setUpUpdateToolTip &&
                  setTooltipForUpdatedValues(key, row.original, {
                    replaceValueTooltipText:
                      OLD_VALUES[key] === ''
                        ? t(
                            'reports.dl_changes_report.common.null_values_tooltip',
                          )
                        : OLD_VALUES[key],
                  }))}
              >
                {row.original[key]}
              </div>
              {setUpUpdateToolTip && (
                <ReactTooltip
                  place="bottom"
                  className="toolTip"
                  id={`${row.original.id}-${key}`}
                />
              )}
            </>
          );
        },
        header: t('reports.drug_report.advisory_name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Define react table
   */
  const table: ReactTable<any> = useReactTable({
    data: drugs,
    columns,
    // pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      //   pagination,
      //   sorting,
      //   columnFilters,
    },
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // manualPagination: true,
    // manualSorting: true,
    // manualFiltering: true,
    enableMultiSort: false,
  });

  return <Table classes="dl-changes-table" table={table} isFetching={false} />;
};

export default DrugChanges;
