export const SUPER_ADMIN = 'SuperAdmin';
export const ADMINISTRATION = 'Administration';
export const AUTHOR = 'Author';
// export const REVIEWER = 'Reviewer';
export const MOBILE = 'Mobile';

export const ROLE_LIST = [
  SUPER_ADMIN,
  ADMINISTRATION,
  AUTHOR,
  // REVIEWER,
  MOBILE,
] as const;

type RolesMapping = {
  [key: string]: string;
};

export const ROLES_MAPPING: RolesMapping = {
  [SUPER_ADMIN]: 'roles.super_admin',
  [ADMINISTRATION]: 'roles.administration',
  [AUTHOR]: 'roles.author',
  // [REVIEWER]: 'roles.reviewer',
  [MOBILE]: 'roles.mobile',
};
