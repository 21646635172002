import { Link, useLocation, useParams } from 'react-router-dom';
import './JsonComparisonModal.scss';
import { useEffect, useState } from 'react';
import { overlayActions } from 'store/slices/overlay';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { ReactQueryKeys } from 'constants/react-query-keys';
import DeploymentService, {
  ChangeReportFilters,
} from 'services/DeploymentService';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { deploymentActions } from 'store/slices/deployment';
import { DEPLOYMENT_TAB_PARAMS_KEY, FRONT_ROUTER } from 'constants/router';
import { Tab, Tabs } from 'components/UI/Tabs/Tabs';
import FacilitiesChanges from './ViewChangesComponents/FacilitiesChanges';
import CareAreasChanges from './ViewChangesComponents/CareAreasChanges';
import DrugsChanges from './ViewChangesComponents/DrugsChanges';
import Spinner from 'components/UI/Spinner/Spinner';
import ViewChangesLegend from './ViewChangesComponents/ViewChangesLegend';
import { RecordTypeUnionValues } from 'interfaces/facility';
import ReactTooltip from 'components/UI/Tooltip/ReactTooltip';
import { IoMdSync } from 'react-icons/io';
import { BsFiletypePdf } from 'react-icons/bs';
import dayjs from 'dayjs';
import { downloadFile } from 'helpers/download-pdf-file';

const JsonComparisonModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState<ChangeReportFilters>({
    recordType: [],
  });
  const [activeTab, setActiveTab] = useState(0);

  function setRecordType(recordType: RecordTypeUnionValues) {
    setFilters((oldValue) => {
      // Check if the recordType is already in the array
      if (oldValue.recordType.includes(recordType)) {
        // If it is, remove it
        return {
          ...oldValue,
          recordType: oldValue.recordType.filter((rt) => rt !== recordType),
        };
      } else {
        // If it is not, add it
        return {
          ...oldValue,
          recordType: [...oldValue.recordType, recordType],
        };
      }
    });
  }

  const { newDeployment } = useAppSelector((state) => state.deployment);

  const location = useLocation();
  const { [DEPLOYMENT_TAB_PARAMS_KEY]: deploymentTab } = useParams();
  const backUrl =
    (location.state as string) ?? FRONT_ROUTER.DEPLOYMENT_FULL_DEPLOYMENT_TAB;

  useEffect(() => {
    dispatch(overlayActions.open({ path: backUrl }));
  }, [dispatch, backUrl]);

  const { isLoading, isFetching } = useQuery(
    [ReactQueryKeys.TEMPORARY_JSON_FILE, { filters, deploymentTab }],
    () => DeploymentService.getChangeReport({ filters, deploymentTab }),
    {
      onSuccess: (data) => {
        dispatch(deploymentActions.setDeploymentJsonFiles(data.data));
      },

      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
      keepPreviousData: true,
    },
  );

  const DownloadButton = () => {
    const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

    const handleDownload = async () => {
      setIsDownloadingPDF(true);

      try {
        const pdfReport = await DeploymentService.downloadChangeReport({
          deploymentTab,
        });
        downloadFile(
          pdfReport.data,
          `view-changes-report-${dayjs().format('YYYY-MM-DD')}.pdf`,
        );
      } catch (e) {
        console.log(e);
      } finally {
        setIsDownloadingPDF(false);
      }
    };
    return (
      <button
        className={`btn btn-primary mb-2`}
        disabled={isDownloadingPDF}
        onClick={handleDownload}
      >
        {isDownloadingPDF && <IoMdSync size={24} className="rotate-infinute" />}
        {!isDownloadingPDF && (
          <>
            <BsFiletypePdf
              data-tooltip-id="report-download-pdf-button"
              data-tooltip-content={t('reports.download_pdf')}
              size={24}
            />
            <ReactTooltip id="report-download-pdf-button" place="bottom" />
          </>
        )}
        <span className="ps-2">{t('reports.download')}</span>
      </button>
    );
  };

  return (
    <form id="formid" autoComplete="off" className="json-comparison-modal">
      <div className="modal-sidebar-body">
        <DownloadButton />
        {(isLoading || isFetching) && <Spinner isAbsolute />}
        {!isLoading && !isFetching && newDeployment ? (
          <Tabs setActiveTab={setActiveTab} activeTab={activeTab}>
            <Tab title="Facility" classes="jsom-comparison-modal__tab-wrapper">
              <ViewChangesLegend
                setRecordTypeFilter={setRecordType}
                activeFilters={filters.recordType}
              />
              <FacilitiesChanges facilities={newDeployment.facilities} />
            </Tab>
            <Tab
              title="Care Areas"
              classes="jsom-comparison-modal__tab-wrapper"
            >
              <ViewChangesLegend
                setRecordTypeFilter={setRecordType}
                activeFilters={filters.recordType}
              />
              <CareAreasChanges careAreas={newDeployment.careAreas} />
            </Tab>
            <Tab title="Drugs" classes="jsom-comparison-modal__tab-wrapper">
              <ViewChangesLegend
                setRecordTypeFilter={setRecordType}
                activeFilters={filters.recordType}
              />
              <DrugsChanges drugs={newDeployment.drugs} />
            </Tab>
          </Tabs>
        ) : null}
      </div>

      <div className="modal-sidebar-footer">
        <Link to={backUrl} className={`btn btn-lg rounded btn-secondary `}>
          {t('buttons.close')}
        </Link>
      </div>
    </form>
  );
};

export default JsonComparisonModal;
