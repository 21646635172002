import './Profile.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Button/Button';
import Spinner from 'components/UI/Spinner/Spinner';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Controller, useForm } from 'react-hook-form';
import UserService from 'services/UserService';
import { User } from 'interfaces/user';
import { timezones } from 'constants/timezones';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { authActions, selectUser } from 'store/slices/auth';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
// import { DevTool } from '@hookform/devtools';

type FormData = {
  id?: number;
  cognito_id?: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  company_name: string;
  title?: string | null;
  department?: string | null;
  timezone?: { label: string; value: string } | null;
};

const TABS = [
  {
    title: 'users.tabs.profile',
    url: '/settings/profile',
  },
  {
    title: 'users.tabs.password',
    url: '/settings/password',
  },
];

const Profile = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    mode: 'all',
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [record, setRecord] = useState<User>();
  const [formLoading, setFormLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const isDirtyExtended = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    setFormLoading(true);
    UserService.getMe()
      .then(({ data }: { data: User }) => {
        setRecord(data);
        setValue('first_name', data.first_name);
        setValue('last_name', data.last_name);
        setValue('phone_number', data.phone_number);
        if (data?.title) {
          setValue('title', data?.title);
        }
        if (data?.department) {
          setValue('department', data?.department);
        }
        if (data?.timezone) {
          const selectedTimezone = timezones.find(
            (tz) => tz.label === data.timezone,
          );
          if (selectedTimezone) {
            setValue('timezone', {
              label: selectedTimezone.label,
              value: selectedTimezone.value,
            });
          }
        }

        setFormLoading(false);
      })
      .catch(() => {
        toast.error(t('users.messages.loading__error'));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      first_name: formData.first_name.trim(),
      last_name: formData.last_name.trim(),
      phone_number: formData.phone_number.trim(),
      title: formData.title ? formData.title : null,
      department: formData.department ? formData.department : null,
      timezone: formData.timezone?.label ? formData.timezone.label : null,
    };

    setFormSubmitting(true);

    try {
      const { data: updatedData } = await UserService.updateMe(data);

      // Workaround for setting the form as pristine
      reset({}, { keepValues: true });

      setRecord(updatedData);

      dispatch(
        authActions.updateUser({
          user: updatedData,
        }),
      );
      toast.success(t('users.profile.update__success'));
    } catch (e: any) {
      let message = t('users.profile.update__error');
      if (e.response.data?.statusCode === 400) {
        message = parseErrorMessage(e.response.data);
      }
      toast.error(message);
    }

    setFormSubmitting(false);
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    reset({
      first_name: record?.first_name,
      last_name: record?.last_name,
      phone_number: record?.phone_number,
      title: record?.title,
      department: record?.department,
      timezone: record?.timezone
        ? { label: record?.timezone, value: record?.timezone }
        : null,
    });
  };

  return (
    <div className="user-profile">
      <div className="user-profile__header">
        <div>
          <h3>{t('users.profile.title')}</h3>
        </div>
        <div className="text-end">
          <div>{user?.email}</div>
          <div>@{user?.company_name}</div>
        </div>
      </div>

      <ContentHeader links={TABS} />
      <div className="main-container with-tabs">
        <form
          id="formid"
          data-testid="profile.form"
          autoComplete="off"
          onSubmit={handleSubmit((data) => handleSubmitData(data))}
        >
          <div className={formLoading || formSubmitting ? 'opacity-50' : ''}>
            {formLoading && <Spinner isAbsolute />}

            <div className="row">
              {/* First Name */}
              <div className="col-6">
                <label htmlFor="firstName" className="form-label">
                  {t('users.first_name')} *
                </label>
                <input
                  type="text"
                  autoFocus
                  className="form-control form-control-lg rounded"
                  data-testid="profile.first_name"
                  id="firstName"
                  {...register('first_name', {
                    required: true,
                  })}
                />
                {errors?.first_name?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.first_name__required')}
                  </div>
                )}
              </div>
              {/* Last Name */}
              <div className="col-6">
                <label htmlFor="lastName" className="form-label">
                  {t('users.last_name')} *
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg rounded"
                  data-testid="profile.last_name"
                  id="lastName"
                  {...register('last_name', {
                    required: true,
                  })}
                />
                {errors?.last_name?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.last_name__required')}
                  </div>
                )}
              </div>
            </div>

            <div className="row pt-4">
              {/* Phone Number */}
              <div className="col-6">
                <label htmlFor="phoneNumber" className="form-label">
                  {t('users.phone_number')} *
                </label>

                <Controller
                  control={control}
                  name="phone_number"
                  rules={{
                    required: true,
                    validate: (value) =>
                      !!value && isValidPhoneNumber(String(value)),
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PhoneInput
                      id="phoneNumber"
                      placeholder="Enter phone number"
                      defaultCountry="US"
                      value={value}
                      data-testid="profile.phone_number"
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      className="phone-input-lg phone-input"
                    />
                  )}
                />
                {errors?.phone_number?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.phone_number__required')}
                  </div>
                )}
                {errors?.phone_number?.type === 'validate' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.phone_number__valid')}
                  </div>
                )}
              </div>

              {/* Time Zone */}
              <div className="col-6">
                <label htmlFor="timezone" className="form-label">
                  {t('users.time_zone')}
                </label>
                <Controller
                  control={control}
                  name="timezone"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      maxMenuHeight={300}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      aria-label="phone_number"
                      value={value as any}
                      isMulti={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      isSearchable
                      options={timezones.map((timezone) => ({
                        label: timezone.label,
                        value: timezone.value,
                      }))}
                    />
                  )}
                />
              </div>
            </div>

            <div className="row pt-4">
              {/* Title */}
              <div className="col-6">
                <label htmlFor="title" className="form-label">
                  {t('users.user_title')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg rounded"
                  data-testid="profile.title"
                  id="title"
                  {...register('title')}
                />
              </div>

              {/* Department */}
              <div className="col-6">
                <label htmlFor="department" className="form-label">
                  {t('users.department')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg rounded"
                  data-testid="profile.department"
                  id="department"
                  {...register('department')}
                />
              </div>
            </div>
          </div>

          <div className="py-4 pt-5">
            <Button
              loading={formSubmitting}
              defaultLabel={t('buttons.save')}
              loadingLabel={t('buttons.updating')}
              data-testid="buttons.confirm"
              type="submit"
              disabled={formSubmitting || formLoading || !isDirtyExtended}
            ></Button>

            <a
              href="/#"
              className={`btn btn-lg rounded-1 btn-secondary ${
                formSubmitting || formLoading || !isDirtyExtended
                  ? 'disabled'
                  : ''
              }`}
              onClick={handleCancel}
            >
              {t('buttons.cancel')}
            </a>
          </div>
        </form>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Profile;
