import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { CareArea } from 'interfaces/care-area';
import CareAreaService from 'services/CareAreaService';
import { Facility } from 'interfaces/facility';
import FacilityService from 'services/FacilityService';
import { toast } from 'react-hot-toast';
import { parseErrorMessage } from 'helpers/parse-error-message';
import Spinner from 'components/UI/Spinner/Spinner';

type CareAreasProps = {
  facility: Facility;
  canManage: boolean;
};

const CareAreas: FC<CareAreasProps> = ({ facility }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [addedCareAreaIds, setAddedCareAreaIds] = useState<
    Array<string | number>
  >([]);

  const { data: addedCareAreas, isLoading: isLoadingAddedCareAreas } = useQuery(
    [ReactQueryKeys.FACILITY_CARE_AREAS, { facilityId: facility.id }],
    () => FacilityService.getCareAreas(String(facility.id)),
    {
      keepPreviousData: true,
      // @ts-ignore
      enabled: !!facility && !isNaN(facility.id),
    },
  );

  const { data: careAreas, isLoading: isLoadingCareAreas } = useQuery(
    [ReactQueryKeys.CARE_AREAS],
    () =>
      CareAreaService.get({
        pageIndex: 0,
        pageSize: 100,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
      }),
    { keepPreviousData: true },
  );

  // Get selected care area IDs
  useEffect(() => {
    if (addedCareAreas?.data) {
      setAddedCareAreaIds(
        addedCareAreas.data.records.map((careArea) => Number(careArea.id)),
      );
    }
  }, [addedCareAreas]);

  const handleCareAreaClick = async (careArea: CareArea) => {
    try {
      await FacilityService.addCareArea(
        Number(facility.id),
        Number(careArea.id),
      );
      queryClient.invalidateQueries([ReactQueryKeys.FACILITY_CARE_AREAS]);
      toast.success(
        t('device.messages.facility__care_area__success', {
          careAreaName: careArea.name,
          facilityName: facility.name,
        }),
      );
    } catch (e: any) {
      let message = t('device.messages.facility__care_area__error');
      if (
        e?.response?.data?.statusCode === 400 ||
        e?.response?.data?.statusCode === 502
      ) {
        message = parseErrorMessage(e.response.data);
      }
      toast.error(message);
    }
  };

  return (
    <>
      <h6
        className="device__care-area--title mt-3 pb-1"
        data-testid="careareas-component"
      >
        {t('device.messages.add_care_area', {
          name: facility.name,
        })}
      </h6>

      {isLoadingAddedCareAreas || isLoadingCareAreas ? (
        <Spinner isAbsolute />
      ) : null}

      {careAreas?.data?.records && careAreas.data.records.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {careAreas?.data?.records && careAreas.data.records.length > 0 && (
        <div className="master-list device__care-area--full-list">
          {careAreas.data.records.map((careArea: CareArea) => {
            return (
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  handleCareAreaClick(careArea);
                }}
                className={`master-list__item device-item ${
                  addedCareAreaIds.includes(Number(careArea.id))
                    ? 'item-disabled'
                    : ''
                }`}
                key={careArea.id}
              >
                {careArea.name}
              </a>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CareAreas;
