import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'config/axios';
import { CareArea } from 'interfaces/care-area';
import { Facility } from 'interfaces/facility';
import { FacilityCareArea } from 'interfaces/facility-care-area';
import { PaginationResponse } from 'interfaces/pagination-response';

export type FacilityCareAreasResponse = {
  records: CareArea[];
};

export type FacilityResponse = PaginationResponse<Facility>;

const FacilityService = {
  create: (data: Partial<Facility>) => {
    return axiosInstance.post<Facility>('/v1/facilities', data);
  },

  update: (id: string | number, data: Partial<Facility>) => {
    return axiosInstance.patch<Facility>(`/v1/facilities/${id}`, data);
  },

  find: (id: string | number, config?: AxiosRequestConfig) => {
    return axiosInstance.get<Facility>(`/v1/facilities/${id}`, config);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<Facility>(`/v1/facilities/${id}`);
  },

  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters?: ColumnFilter[];
  }) => {
    return axiosInstance.get<FacilityResponse>('/v1/facilities', {
      params: queryParams,
    });
  },

  getCareAreas: (facilityId: string | number, listDrugId?: string | number) => {
    let params = {};
    if (listDrugId) {
      params = { listDrugId };
    }

    return axiosInstance.get<FacilityCareAreasResponse>(
      `/v1/facilities/${facilityId}/care-areas`,
      {
        params,
      },
    );
  },

  addCareArea: (facilityId: string | number, careAreaId: string | number) => {
    return axiosInstance.post<FacilityCareArea>(
      `/v1/facilities/${facilityId}/care-areas/${careAreaId}`,
    );
  },

  removeCareArea: (
    facilityId: string | number,
    careAreaId: string | number,
  ) => {
    return axiosInstance.delete<FacilityCareArea>(
      `/v1/facilities/${facilityId}/care-areas/${careAreaId}`,
    );
  },
};

export default FacilityService;
