import './Pagination.scss';
import { Table } from '@tanstack/react-table';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type PaginationProps<T> = {
  table: Table<T>;
  responsive?: boolean;
};

function Pagination<T>({ table, responsive }: PaginationProps<T>) {
  const { t } = useTranslation();

  let pageIndex = Number(table.getState().pagination.pageIndex);

  const inputValue = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputValue.current)
      inputValue.current.value = (pageIndex + 1).toString();
  }, [pageIndex]);

  if (pageIndex < 0) {
    pageIndex = 0;
  }

  const preventMinus = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Minus', 'Period', 'Comma'].includes(event.code)) {
      event.preventDefault();
    }
  };

  const modifyTablePageState = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const page = Number(event.target.value) - 1;

    if (page >= table.getPageCount()) {
      if (inputValue.current) {
        inputValue.current.value = (pageIndex + 1).toString();
      }

      return;
    }

    // If page is the same as the current page, do nothing
    if (pageIndex === page) return;

    // If blank reset input to defaultValue
    if ((event.target.value === '' || page < 0) && inputValue.current)
      return (inputValue.current.value = (pageIndex + 1).toString());

    if (page >= 0) {
      table.setPageIndex(page);
    }
  };

  const handlePageEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      modifyTablePageState(event);
    }
  };

  return (
    <div className={'d-flex justify-content-between dl-pagination flex-nowrap'}>
      <div className="dl-pagination__previous">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {t('pagination.previous')}
        </button>
      </div>

      <div className={'dl-pagination__center'}>
        {pageIndex >= 0 && table.getPageCount() > 0 && (
          <span className="dl-pagination__page-info">
            {responsive
              ? t('pagination.responsive.page')
              : t('pagination.go_to')}
            <div className="dl-pagination__page-jump">
              <input
                aria-label="jump to page"
                type="number"
                min={1}
                ref={inputValue}
                defaultValue={pageIndex + 1}
                onKeyDown={preventMinus}
                onKeyUp={handlePageEnter}
                onBlur={modifyTablePageState}
              />
            </div>
            <span className="dl-pagination__total-pages">
              {responsive ? (
                t('pagination.responsive.of') + table.getPageCount()
              ) : (
                <>
                  &nbsp;|{' '}
                  {t('pagination.page_number', {
                    value: pageIndex + 1,
                    total: table.getPageCount(),
                  })}
                </>
              )}
            </span>
          </span>
        )}
        {!responsive && pageIndex >= 0 && table.getPageCount() > 0 && (
          <span className="dl-pagination__select-wrap">
            <select
              aria-label="rows per page"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
                table.setPageIndex(0);
              }}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} {t('pagination.rows')}
                </option>
              ))}
            </select>
          </span>
        )}
      </div>

      <div className="dl-pagination__next">
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {t('pagination.next')}
        </button>
      </div>
    </div>
  );
}

export default Pagination;
