type ServerErrors = {
  property: string;
  children: ServerErrors[];
  constraints: { [key: string]: string };
};

type ServerErrorData = {
  statusCode: number;
  message: string;
  errors?: ServerErrors[];
};

/**
 * Parse validation error message
 *
 * @param {ServerErrorData} data API Error data
 * @returns string
 */
export const parseErrorMessage = (data: ServerErrorData): string => {
  if (Array.isArray(data.errors) && !!data.errors.length) {
    // Get the first message from the list of errors
    return String(Object.entries(data.errors[0].constraints)[0][1]);
  }

  return data.message;
};
