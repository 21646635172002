import { Drug } from 'interfaces/drug';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import DrugService from 'services/DrugService';
import DrugFormOverview from './DrugFormOverview/DrugFormOverview';
import ListAdvisories from './ListAdvisories/ListAdvisories';
import { Facility } from 'interfaces/facility';
import CopyDrugCareAreas from './CopyDrugCareAreas/CopyDrugCareAreas';
import { ScreenEnum } from '../enums';

type DrugFormProps = {
  drug: Drug;
  facility: Facility;
};

const DrugForm: FC<DrugFormProps> = ({ drug, facility }) => {
  const { t } = useTranslation();

  const [record, setRecord] = useState<Drug | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formLoading, setFormLoading] = useState(false);
  const [screen, setScreen] = useState<ScreenEnum>(ScreenEnum.Overview);

  // Fetch the selected drug
  const getDrug = (id: string | number) => {
    DrugService.getDrug(id)
      .then(({ data }) => {
        setRecord(data);
        setFormLoading(false);
      })
      .catch((_) => {
        setFormLoading(false);
        toast.error(t('device.messages.drug_can_not_be_loaded'));
      });
  };

  useEffect(() => {
    setRecord(null);
    setFormLoading(true);
    setScreen(ScreenEnum.Overview);
    getDrug(drug.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drug]);

  return (
    <div className="pt-3 pb-5" data-testid="drug-form-component">
      <div className="device-concentration-form__drug--name pb-5">
        {drug.name}
      </div>

      {!!record && screen === ScreenEnum.Overview && (
        <DrugFormOverview
          drug={record}
          setScreen={setScreen}
          updateDrug={getDrug}
        />
      )}

      {!!record &&
        screen === ScreenEnum.Advisories && ( // !record.is_basic
          <ListAdvisories
            drug={record}
            setScreen={setScreen}
            updateDrug={getDrug}
          />
        )}
      {!!record && !record.is_basic && screen === ScreenEnum.CareAreas && (
        <CopyDrugCareAreas
          drug={record}
          facility={facility}
          canManage={true}
          setScreen={setScreen}
        />
      )}
    </div>
  );
};

export default DrugForm;
