import { RecordTypeUnionValues } from 'interfaces/facility';
import './ViewChangesLegend.scss';

type Props = {
  setRecordTypeFilter: (recordType: RecordTypeUnionValues) => void;
  activeFilters: RecordTypeUnionValues[];
};

const ViewChangesLegend = ({ setRecordTypeFilter, activeFilters }: Props) => {
  return (
    <div
      className="view-changes-legend"
      data-testid="view-changes-legend-test-id"
    >
      <span
        className={`view-changes-legend__button view-changes-added ${
          activeFilters.includes('ADDED') ? 'active' : ''
        }`}
        onClick={() => setRecordTypeFilter('ADDED')}
      >
        Added
      </span>
      <span
        className={`view-changes-legend__button view-changes-updated ${
          activeFilters.includes('UPDATED') ? 'active' : ''
        }`}
        onClick={() => setRecordTypeFilter('UPDATED')}
      >
        Updated
      </span>
      <span
        className={`view-changes-legend__button view-changes-deleted ${
          activeFilters.includes('DELETED') ? 'active' : ''
        }`}
        onClick={() => setRecordTypeFilter('DELETED')}
      >
        Deleted
      </span>
    </div>
  );
};

export default ViewChangesLegend;
