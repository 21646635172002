import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type UnitsHeaderProps = {
  canManage: boolean;
};

const UnitsHeader: FC<UnitsHeaderProps> = ({ canManage }) => {
  const { t } = useTranslation();

  return (
    <div className="row pb-3 pt-5 position-relative" data-testid="units-header">
      {!canManage && (
        <div className="device-concentration--disabled-item"></div>
      )}
      <div className="device-concentration-form__label"></div>
      <div className="device-concentration__box-input justify-content-center text-center">
        {t('device.lower_hard_limit')}
      </div>
      <div className="device-concentration__box-input justify-content-center text-center">
        {t('device.lower_soft_limit')}
      </div>
      <div className="device-concentration__box-input justify-content-center text-center">
        {t('device.upper_soft_limit')}
      </div>
      <div className="device-concentration__box-input justify-content-center text-center">
        {t('device.upper_hard_limit')}
      </div>
      <div className="col-care-area-units">{t('device.units')}</div>
    </div>
  );
};

export default UnitsHeader;
